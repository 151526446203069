import {
    Fragment,
    useEffect,
    useRef,
    useState
} from "react";

import {
    ArrowDownTrayIcon,
    ArrowTopRightOnSquareIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";

import {
    classNames,
    getFileNameExtension,
    removeFileNameExtension,
    unzipFile
} from "../lib/utils";
import { Backend } from "../lib/backend";
import {
    EXCEL_MIME_TYPES,
    IMAGE_MIME_TYPES,
    OCR_MIME_TYPES,
    PDF_MIME_TYPE,
    SIMPLE_MIME_TYPES
} from "../lib/consts";
import {
    IExcelArraySheet,
    SelectPagesMode
} from "../lib/types";

import { LoadingSpinnerLimit } from "./LoadingSpinner";
import { PdfViewerHorizontal } from "./PdfViewer";
import { SheetsViewer } from "./Sheets";
import { Button } from "./Button";
import { LongText } from "./LongText";
import { FullScreenText } from "./FullScreen";

type DragDropAreaProps = {
    name: string,
    rows: number,
    text?: string,
    file?: File,
    readonly?: boolean,
    allow_multiple?: boolean,
    show_section_split?: boolean,
    select_pages_mode?: SelectPagesMode,
    setText?: (text: string) => void,
    setFiles: (files: File[], skip_set_subject: boolean) => void,
    setFilePages?: (pages: number) => void,
    setFileSelectedPages?: (selected_pages: number[], page_delimiters: number[]) => void,
    setSubject?: (title: string) => void,
    setErrorMessage: (messages: string | undefined) => void,
    setClear: () => void
};

export function DragDropArea(props: DragDropAreaProps) {
    const {
        name, rows, text, file, readonly, allow_multiple, show_section_split, select_pages_mode,
        setText, setFiles, setFilePages, setFileSelectedPages, setSubject, setErrorMessage, setClear
    } = props;

    const [ocr_file_url, setOcrFileUrl] = useState<string | undefined>(undefined);
    const [excel_file_sheets, setExcelFileSheets] = useState<IExcelArraySheet[] | undefined>(undefined);
    const [plain_text, setPlainText] = useState<string | undefined>(undefined);
    const [pdf_num_pages, setPdfNumPages] = useState<number>(0);
    const [show_full_screen, setShowFullScreen] = useState<boolean>(false);
    const [is_drag_over, setIsDragOver] = useState<boolean>(false);
    const [is_parsing_file, setIsParsingFile] = useState<boolean>(false);

    const textBoxRef = useRef<HTMLTextAreaElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (file && OCR_MIME_TYPES.includes(file.type)) {
            try {
                if (ocr_file_url !== undefined) { URL.revokeObjectURL(ocr_file_url); }
                setOcrFileUrl(URL.createObjectURL(file));
            } catch (err) {
                setErrorMessage("Error loading file. Please try again.");
            }
            setIsParsingFile(false);
        } else if (file && EXCEL_MIME_TYPES.includes(file.type)) {
            try {
                if (ocr_file_url !== undefined) {
                    URL.revokeObjectURL(ocr_file_url);
                    setOcrFileUrl(undefined);
                }
                const form_data = new FormData();
                form_data.append("file", file);
                Backend.getArrayFromExcel(form_data)
                    .then((sheets) => {
                        setExcelFileSheets(sheets);
                        if (setFilePages) { setFilePages(sheets.length); }
                        setIsParsingFile(false);
                    })
                    .catch((_err) => {
                        setErrorMessage("Error parsing excel file. Please try again.");
                        setIsParsingFile(false);
                    });
            } catch (err) {
                setErrorMessage("Error parsing excel file. Please try again.");
                setIsParsingFile(false);
            }
        } else if (file && SIMPLE_MIME_TYPES.includes(file.type)) {
            try {
                // plain text can be read directly
                const reader = new FileReader();
                reader.onload = (e) => {
                    setPlainText(e.target?.result as string);
                    setIsParsingFile(false);
                };
                if (ocr_file_url !== undefined) {
                    URL.revokeObjectURL(ocr_file_url);
                    setOcrFileUrl(undefined);
                }
                reader.readAsText(file);
            } catch (_err) {
                setErrorMessage("Error loading file. Please try again.");
                setIsParsingFile(false);
            }
        } else {
            setIsParsingFile(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    const handleDragOver = (e: React.DragEvent<HTMLTextAreaElement | HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    }

    const handleDragLeave = (e: React.DragEvent<HTMLTextAreaElement | HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
    }

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const processFiles = async (zip_drop_files: File[]) => {
        // in case we have a zip file, we unzip it and use zip file as subject
        let is_set_subject = false;
        if (setSubject && zip_drop_files.length > 0 && getFileNameExtension(zip_drop_files[0].name) === "zip") {
            setSubject(removeFileNameExtension(zip_drop_files[0].name));
            is_set_subject = true;
        }
        const drop_files = (await Promise.all(zip_drop_files.map(unzipFile))).flat();
        // check file type
        const supported_types = [...OCR_MIME_TYPES, ...EXCEL_MIME_TYPES, ...SIMPLE_MIME_TYPES];
        const supported_files = drop_files.filter((f) => supported_types.includes(f.type));
        if (supported_files.length > 0) {
            setFiles(supported_files, is_set_subject);
        }
        const unsupported_types = drop_files.filter((f) => !supported_types.includes(f.type)).map((f) => f.name);
        if (unsupported_types.length > 0) {
            setErrorMessage(`Unsupported file type(s): ${unsupported_types.join(",")}.`);
            console.error("Unsupported file types", drop_files.map((f) => f.type).join(", "));
            setIsParsingFile(false);
        }
        // in case we already have file, it will be parsed in different DragDropArea
        if (file !== undefined) { setIsParsingFile(false); }
    }

    const handleDrop = async (e: React.DragEvent<HTMLTextAreaElement | HTMLDivElement>) => {
        try {
            e.preventDefault();
            setIsDragOver(false);
            if (readonly) {
                setErrorMessage("Cannot upload file in read-only mode.");
                return;
            }

            setIsParsingFile(true);
            setErrorMessage(undefined);
            if (allow_multiple) {
                processFiles([...e.dataTransfer.files]);
            } else {
                processFiles([e.dataTransfer.files[0]]);
            }
        } catch (err) {
            console.error("Error processing dropped file", err);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (e.target.files) {
                setIsParsingFile(true);
                setErrorMessage(undefined);
                if (allow_multiple) {
                    processFiles([...e.target.files]);
                } else {
                    processFiles([e.target.files[0]]);
                }
            }
        } catch (err) {
            console.error("Error processing changed file", err);
        }
    };

    const notifyNumPages = (num_pages: number) => {
        if (setFilePages) { setFilePages(num_pages); }
        setPdfNumPages(num_pages);
    }

    const notifySelectedPages = (selected_pages: number[], page_delimiters: number[]) => {
        if (setFileSelectedPages) { setFileSelectedPages(selected_pages, page_delimiters); }
    }

    // default we render the text area
    return <Fragment>
        {file && <div className="pt-4 pb-2 flex items-center max-w-4xl">
            <p className="text-sm text-gray-600">File: {file.name}</p>
            <div className="flex-grow" />
            {plain_text !== undefined && <button
                className="ml-2 text-sm focus:outline-none focus:underline text-gray-400"
                onClick={() => setShowFullScreen(true)}>
                <ArrowTopRightOnSquareIcon className="h-5 w-5" />
            </button>}
            <button
                className="ml-2 text-sm focus:outline-none focus:underline"
                onClick={() => { setClear(); setOcrFileUrl(undefined); setExcelFileSheets(undefined); }}>
                <XMarkIcon className="h-6 w-6" />
            </button>
        </div>}

        {file && ocr_file_url && file.type === PDF_MIME_TYPE && <div
            className={classNames(
                "w-full p-2 border border-gray-200 rounded shadow bg-gray-50 overflow-y-auto",
                pdf_num_pages && pdf_num_pages > 2 ? "" : "max-w-4xl",
                is_drag_over ? "bg-sea_blue-100" : ""
            )}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>

            <PdfViewerHorizontal
                display_name={file.name}
                file={ocr_file_url}
                select_pages={true}
                split_document={show_section_split === true}
                max_width={300}
                select_pages_mode={select_pages_mode}
                notifyNumPages={notifyNumPages}
                notifySelectedPages={notifySelectedPages} />
        </div>}

        {file && ocr_file_url && IMAGE_MIME_TYPES.includes(file.type) && <div
            className={classNames("max-w-4xl w-full p-2 border border-gray-200 rounded shadow bg-gray-50 overflow-y-auto", is_drag_over ? "bg-sea_blue-100" : "")}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>

            {OCR_MIME_TYPES.includes(file.type) ? <img src={ocr_file_url} alt="Uploaded" className="max-h-96 max-w-4" /> : "file.type"}
        </div>}

        {file && EXCEL_MIME_TYPES.includes(file.type) && excel_file_sheets && <div
            className={classNames("max-w-4xl w-full p-2 border border-gray-200 rounded shadow bg-gray-50 overflow-y-auto", is_drag_over ? "bg-sea_blue-100" : "")}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>
            <SheetsViewer sheets={excel_file_sheets} />
        </div>}

        {file && SIMPLE_MIME_TYPES.includes(file.type) && plain_text && <div
            className={classNames("max-w-4xl w-full p-2 border border-gray-200 rounded shadow bg-gray-50 overflow-y-auto", is_drag_over ? "bg-sea_blue-100" : "")}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>
            <LongText text={plain_text} line_limit={10} />
        </div>}

        {setText !== undefined && text !== undefined && <textarea
            ref={textBoxRef}
            id={name}
            name={name}
            rows={rows}
            className={classNames(
                "block max-w-4xl w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6 whitespace-pre",
                is_drag_over ? (readonly ? "bg-red-50" : "bg-sea_blue-100") : "", is_parsing_file ? "bg-gray-100 text-gray-400" : "")}
            value={is_drag_over ? "" : text}
            placeholder={is_drag_over ? "" : "Enter text or drag and drop file here."}
            onChange={(e) => setText(e.target.value)}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onBlur={() => { if (text === "") { setClear(); } }}
            disabled={is_parsing_file}
            readOnly={readonly}
        />}

        {setText !== undefined && file === undefined && text === undefined && <div
            className={classNames(
                "flex justify-center items-center max-w-4xl w-full h-96 border-2 border-dashed border-gray-300 rounded-lg p-5",
                is_drag_over ? "bg-sea_blue-100" : ""
            )}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => { setText(""); setTimeout(() => { textBoxRef.current?.focus(); }, 100); }}>

            <div className="text-center">
                <ArrowDownTrayIcon className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-4 text-sm font-semibold text-gray-900">Drop file here or just click and write or copy text.</h3>
                <p className="mt-2 mb-8 text-sm text-gray-500">You can drop any PDF, Excel or image file.</p>
                <Button text="Upload file" onClick={handleButtonClick} />
            </div>
        </div>}

        <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            multiple={allow_multiple}
            onChange={handleFileChange}
        />

        <FullScreenText
            text={plain_text || ""}
            show={show_full_screen}
            onClose={() => setShowFullScreen(false)} />

        {/* Overlay text area with loading indicator */}
        {is_parsing_file && <div className="absolute inset-0 max-w-5xl w-full flex items-center justify-center bg-white opacity-95">
            <LoadingSpinnerLimit text="Uploading and processing file..." />
        </div>}
    </Fragment>;
}