import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as t from "../lib/types";
import { Backend } from "../lib/backend";
import { validatePassword } from "../lib/utils";

export const PASSWORD_STATES = {
    "new": "new",
    "change": "change",
    "new_error": "new_error",
    "change_error": "change_error"
};

type PasswordState = typeof PASSWORD_STATES[keyof typeof PASSWORD_STATES];

type PasswordFieldProps = {
    label: string;
    value: string;
    type: string;
    read_only?: boolean;
    tooltip?: boolean;
    onChange?: (value: string) => void;
}

function PasswordField(props: PasswordFieldProps) {
    const { label, value, type, read_only, tooltip, onChange } = props;

    return <div>
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            {label}
        </label>
        {tooltip && <span className="text-xs text-gray-400">
            min. 8 chars, one lowercase and uppercase letter, one digit.
        </span>}
        <div className="mt-2">
            <input
                id={type}
                name={type}
                type={["email", "password"].includes(type || "") ? type : "text"}
                autoComplete={type === "password" ? "current-password" : type}
                value={value}
                onChange={(e) => { if (onChange) { onChange(e.target.value); } }}
                readOnly={read_only === true}
                disabled={read_only}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6"
            />
        </div>
    </div>;
}

type PasswordProps = {
    user: t.IUser;
    init_password_state: PasswordState;
}

export function Password(props: PasswordProps) {
    const navigate = useNavigate();

    const { user, init_password_state } = props;

    const [password_state, setPasswordState] = useState<PasswordState>(init_password_state);
    const [old_password, setOldPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [is_processing, setIsProcessing] = useState(false);

    const handleNewPassword = (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        Backend.newPassword({ password: new_password })
            .then((ok) => {
                window.location.href = "/";
            })
            .catch((err) => {
                setIsProcessing(false);
                setPasswordState(PASSWORD_STATES.new_error);
                console.log(err);
            });
    };

    const handleChangePassword = (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        Backend.changePassword({ old_password, new_password })
            .then((ok) => {
                window.location.href = "/";
            })
            .catch((err) => {
                setIsProcessing(false);
                setPasswordState(PASSWORD_STATES.change_error);
                console.log(err);
            });
    };

    const handleBacktoDashbaord = (e: any) => {
        e.preventDefault();
        navigate("/user");
    };

    const is_old_password_valid = old_password.length > 0;
    const is_new_password_valid = validatePassword(new_password);

    return <div className="flex min-h-screen flex-1 flex-col justify-center py-20 sm:px-6 lg:px-8 w-full bg-cover lg:bg-[url('app_blur.png')]">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                className="mx-auto h-12 w-auto"
                src="/logo.svg"
                alt="nordoon"
            />
            {password_state === PASSWORD_STATES.new && <Fragment>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Reset your password
                </h2>
            </Fragment>}

            {password_state === PASSWORD_STATES.new_error && <Fragment>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Error resetting your password
                </h2>
                <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                    Please contact our support at <a href="mailto:team@nordoon.ai">team@nordoon.ai</a>
                </p>
            </Fragment>}

            {password_state === PASSWORD_STATES.change && <Fragment>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Change your password
                </h2>
            </Fragment>}

            {password_state === PASSWORD_STATES.change_error && <Fragment>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Error changing your password
                </h2>
                <p className="mt-2 text-center text-sm leading-6 text-gray-500">
                    Make sure you entered your existing password correctly.
                </p>
            </Fragment>}
        </div>

        {password_state !== PASSWORD_STATES.new_error &&
            <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <form className="">
                        <PasswordField label="Email address" value={user.email} type="email" read_only={true} />
                        {(password_state === PASSWORD_STATES.change || password_state === PASSWORD_STATES.change_error) && <div className="mt-6">
                            <PasswordField label="Old password" value={old_password} onChange={setOldPassword} type="password" />
                        </div>}
                        <div className="mt-6">
                            <PasswordField label="New password" value={new_password} onChange={setNewPassword} type="password" tooltip={true} />
                        </div>

                        {password_state === PASSWORD_STATES.new && <div>
                            <button
                                onClick={handleNewPassword}
                                disabled={!is_new_password_valid || is_processing}
                                className="mt-6 flex w-full justify-center rounded-md bg-space_blue-600 disabled:bg-gray-300 disabled:text-gray-900  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-space_blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-space_blue-600"
                            >
                                {is_processing && <i className="fas fa-spinner fa-spin mt-1" />}&nbsp;Reset password
                            </button>
                        </div>}
                        {(password_state === PASSWORD_STATES.change || password_state === PASSWORD_STATES.change_error) && <div>
                            <button
                                onClick={handleChangePassword}
                                disabled={!is_old_password_valid || !is_new_password_valid || is_processing}
                                className="mt-6 flex w-full justify-center rounded-md bg-space_blue-600 disabled:bg-gray-300 disabled:text-gray-900  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-space_blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-space_blue-600"
                            >
                                {is_processing && <i className="fas fa-spinner fa-spin mt-1" />}&nbsp;Change password
                            </button>
                        </div>}
                    </form>

                    {(password_state === PASSWORD_STATES.change || password_state === PASSWORD_STATES.change_error) && <div className="mt-6">
                        <button
                            onClick={handleBacktoDashbaord}
                            className="mt-12 w-full bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow flex items-center text-sm justify-center h-9"
                        >
                            Back
                        </button>
                    </div>}
                </div>
            </div>}
    </div>;
}
