import { Link } from "react-router-dom";

import {
    ChevronRightIcon,
    PuzzlePieceIcon
} from "@heroicons/react/24/outline";
import { TbTable } from "react-icons/tb";

type IItemBreadcrumbProps = {
    template_uuid: string;
    template_name: string;
    endpoint_uuid?: string;
    endpoint_name?: string;
}

export function ItemBreadcrumb({ template_uuid, template_name, endpoint_uuid, endpoint_name }: IItemBreadcrumbProps) {
    return <div className="flex flex-row items-center">
        {endpoint_uuid !== undefined && <Link to={`/endpoint/${endpoint_uuid}`}>
            <div className="flex flex-row items-center hover:underline pr-2">
                <PuzzlePieceIcon className="h-4 w-4" />
                <div className="text-gray-400 text-sm px-2">{endpoint_name}</div>
                <div>|</div>
            </div>
        </Link>}
        <Link to={`/template/${template_uuid}`}>
            <div className="flex flex-row items-center hover:underline">
                <TbTable className="h-4 w-4" />
                <div className="text-gray-400 text-sm px-2">{template_name}</div>
                <div><ChevronRightIcon className="h-4 w-4" /></div>
            </div>
        </Link>
    </div>;
}