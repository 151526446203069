import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as t from "../lib/types";
import { Backend } from "../lib/backend";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { classNames } from "../lib/utils";

import { LoadingSpinnerLimit } from "../components/LoadingSpinner";

export function AdminUnitEconomics() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [cost_aggr, setCostAggr] = useState<t.ICostAggr | undefined>(undefined);

    useEffect(() => {
        Backend.getCostAggr({}).then(setCostAggr);
    }, []);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="m-16 mb-6">
            <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                Unit Economics
            </h2>
            <div className="pt-5 border-b-4 border-sea_blue-700" />
        </div>
        <div className="px-10 py-5">
            {cost_aggr && <p className="text-gray-600">
                Estimates for the period from {new Date(cost_aggr.from_ts).toLocaleDateString()} to {new Date(cost_aggr.to_ts).toLocaleDateString()}
            </p>}

            <div className="mt-5 mb-10 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Number of job calls
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Credits per job
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Cost per job
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Cost per credit
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {cost_aggr && <tr>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {cost_aggr.job_costs.cost_per_call.calls}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {(cost_aggr.job_costs.cost_per_call.credits_per_call || 0).toFixed(1)}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                ${(cost_aggr.job_costs.cost_per_call.cost_per_call).toFixed(4)}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {cost_aggr.job_costs.cost_per_call.credits_per_call !== undefined && cost_aggr.job_costs.cost_per_call.credits_per_call > 0 &&
                                    `$${(cost_aggr.job_costs.cost_per_call.cost_per_call / (cost_aggr.job_costs.cost_per_call.credits_per_call || 1)).toFixed(4)}`}
                                {cost_aggr.job_costs.cost_per_call.credits_per_call !== undefined && cost_aggr.job_costs.cost_per_call.credits_per_call === 0 && "/"}
                            </td>
                        </tr>}
                        {!cost_aggr && <tr>
                            <td className="whitespace-nowrap py-4 pl-2 text-sm font-semibold text-gray-500 sm:pl-3 align-top" colSpan={4}>
                                <LoadingSpinnerLimit />
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>

            <div className="my-10 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Job Type
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Number of job calls
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Credits per job
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Cost per job
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Cost per credit
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {cost_aggr && cost_aggr.job_costs.type_cost_per_call.map((type_cost_per_call, idx) => (<tr key={idx}>
                            <td className="whitespace-nowrap py-4 pl-2 text-sm font-semibold text-gray-500 sm:pl-3 align-top">
                                {type_cost_per_call.type}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {type_cost_per_call.cost_per_call.calls}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {(type_cost_per_call.cost_per_call.credits_per_call || 0).toFixed(1)}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                ${(type_cost_per_call.cost_per_call.cost_per_call).toFixed(4)}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {type_cost_per_call.cost_per_call.credits_per_call !== undefined && type_cost_per_call.cost_per_call.credits_per_call > 0 &&
                                    `$${(type_cost_per_call.cost_per_call.cost_per_call / (type_cost_per_call.cost_per_call.credits_per_call || 1)).toFixed(4)}`}
                                {type_cost_per_call.cost_per_call.credits_per_call !== undefined && type_cost_per_call.cost_per_call.credits_per_call === 0 && "/"}
                            </td>
                        </tr>))}
                    </tbody>
                </table>
            </div>

            <div className="my-10 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Model
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Number of calls
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Input tokens per call
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Output tokens per call
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Cost per call
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {cost_aggr && cost_aggr.job_costs.model_cost_per_call.map((model_cost_per_call, idx) => (<tr key={idx}>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top">
                                {model_cost_per_call.model}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {model_cost_per_call.cost_per_call.calls}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {Math.round(model_cost_per_call.cost_per_call.num_input_tokens_per_call)}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {Math.round(model_cost_per_call.cost_per_call.num_output_tokens_per_call)}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                ${(model_cost_per_call.cost_per_call.cost_per_call).toFixed(6)}
                            </td>
                        </tr>))}
                    </tbody>
                </table>
            </div>

            <div className="my-10 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Job Type
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Model
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Number of calls
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Input tokens per call
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Output tokens per call
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Cost per call
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {cost_aggr && cost_aggr.job_costs.model_type_cost_per_call.map((model_type_cost_per_call, idx) => (<tr key={idx}>
                            <td className="whitespace-nowrap py-4 pl-2 text-sm font-semibold text-gray-500 sm:pl-3 align-top">
                                {model_type_cost_per_call.type}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top">
                                {model_type_cost_per_call.model}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {model_type_cost_per_call.cost_per_call.calls}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {Math.round(model_type_cost_per_call.cost_per_call.num_input_tokens_per_call)}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                {Math.round(model_type_cost_per_call.cost_per_call.num_output_tokens_per_call)}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 align-top text-left">
                                ${(model_type_cost_per_call.cost_per_call.cost_per_call).toFixed(6)}
                            </td>
                        </tr>))}
                        {!cost_aggr && <tr>
                            <td className="whitespace-nowrap py-4 pl-2 text-sm font-semibold text-gray-500 sm:pl-3 align-top" colSpan={4}>
                                <LoadingSpinnerLimit />
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
};
