import { prettyNumber } from "../lib/utils"
import {
    IContextEvalMetrics,
    IEvalMetrics,
    IScrapeEvalMetrics
} from "../lib/types"

type MetricsProps = {
    stats: {
        name: string
        stat: string
    }[]
}

export function Metrics(props: MetricsProps) {
    const { stats } = props

    return <dl className="mt-3 grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
        {stats.map((item) => (
            <div key={item.name} className="flex-row rounded-lg px-3 py-2 border border-space_blue-200">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1">
                    <span className="text-sm font-normal text-gray-500 mr-2">diff</span>
                    <span className="text-2xl font-semibold tracking-tight text-gray-900">{item.stat}</span>
                </dd>
            </div>
        ))}
    </dl>;
}

export function CompactMetrics(props: MetricsProps) {
    const { stats } = props

    return <dl className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
        {stats.map((item) => (
            <div key={item.name} className="rounded-lg px-2 py-1 border border-gray-200 bg-white flex flex-row">
                <span className="truncate block text-sm font-medium text-gray-500">{item.name}</span>
                <span className="ml-2 block text-sm font-semibold tracking-tight text-gray-900">{item.stat}</span>
            </div>
        ))}
    </dl>;
}

type EvalMetricsProps = {
    eval_metrics: IEvalMetrics,
    eval_metrics_per_field: { field_name: string, metrics: IEvalMetrics }[],
    compact?: boolean,
}

export function EvalMetrics(props: EvalMetricsProps) {
    const { eval_metrics, eval_metrics_per_field, compact } = props

    const eval_metrics_for_fields_with_change = [...eval_metrics_per_field]
        .sort((a, b) => a.metrics.accuracy - b.metrics.accuracy)
        .filter(item => item.metrics.accuracy < 1.0);

    const stats = [
        { name: "Overall", stat: prettyNumber(100.0 - 100.0 * eval_metrics.accuracy, 1) + "%" },
        ...eval_metrics_for_fields_with_change.map((item) => (
            { name: item.field_name, stat: prettyNumber(100.0 - 100.0 * item.metrics.accuracy, 1) + "%" }
        ))
    ];

    if (stats.length <= 1) {
        return <div className="p-1 text-sm">
            <span className="text-gray-400 text-sx">No change</span>
        </div>
    }

    return compact ? <CompactMetrics stats={stats} /> : <Metrics stats={stats} />;
}

type ScrapeEvalMetricsProps = {
    scrapes_eval_metrics: IScrapeEvalMetrics[]
}

export function ScrapeEvalMetrics(props: ScrapeEvalMetricsProps) {
    const { scrapes_eval_metrics } = props;

    return <div>
        <div className="px-2 font-medium text-sm flex items-center gap-2 pb-2">
            Percentage change <span className="font-normal">(0% = no change)</span>
        </div>
        {scrapes_eval_metrics.map((scrape_eval_metrics, idx) => <div key={idx} className="py-2 flex flex-row items-center">
            <h3 className="w-28 px-4 text-sm font-medium text-gray-600">Step {idx + 1}:</h3>
            <EvalMetrics
                eval_metrics={scrape_eval_metrics.metrics}
                eval_metrics_per_field={scrape_eval_metrics.metrics_per_field}
                compact={true} />
        </div>)}
    </div>;
}

type ContextEvalMetricsProps = {
    contexts_eval_metrics: IContextEvalMetrics[]
}

export function ContextEvalMetrics(props: ContextEvalMetricsProps) {
    const { contexts_eval_metrics } = props;

    return <div>
        {contexts_eval_metrics.map((context_eval_metrics, idx) => (<div key={idx} className="py-2 flex flex-row items-center">
            <h3 className="w-28 px-4 text-sm font-medium text-gray-600">Step {idx + 1}:</h3>
            <EvalMetrics
                eval_metrics={context_eval_metrics.metrics}
                eval_metrics_per_field={context_eval_metrics.metrics_per_field}
                compact={true} />
        </div>
        ))}
    </div>;
}