import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { BackendObj } from "../lib/backend";
import * as t from "../lib/backend/extractions.types.generated";
import { classNames, prettySmartDateTime } from "../lib/utils";
import { selectIsSidebarLarge, selectUser } from "../lib/scraper.slice";
import { IGetStripeOptionsRes, ISyncSubscriptionsRes } from "../lib/backend/stripe.types.generated";

import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { Button } from "../components/Button";

export function StripeAdministration() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);

    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [status_msg, setStatusMsg] = useState<string>("");
    const [sync_result, setSyncResult] = useState<ISyncSubscriptionsRes | undefined>(undefined);
    const [settings, setSettings] = useState<IGetStripeOptionsRes | undefined>(undefined);

    const [subscriptions, setSubscriptions] = useState<t.ISubscription[]>([]);

    useEffect(() => {
        loadData()
            .catch(err => console.error(err));
    }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

    if (user.role !== "admin") {
        navigate("/");
    }

    const loadData = async () => {
        // load stripe options
        const res1 = await BackendObj.stripe.getStripeOptions({});
        setSettings(res1);

        // load organizations and stripe subscriptions
        const res3 = await BackendObj.extractions.getSubscriptions({ active: "true", provider: "stripe" });

        // merge org records into subscription records
        const mapped_subscriptions: t.ISubscription[] = res3.subscriptions.map(sub => {
            return {
                ...sub,
            };
        });
        setSubscriptions(mapped_subscriptions);
    };

    const onRunSync = async () => {
        setIsLoading(true);
        setStatusMsg("Syncing with Stripe...");
        try {
            const res = await BackendObj.stripe.syncSubscriptions({});
            setSyncResult(res);
            setStatusMsg("Sync with Stripe successful.");
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setStatusMsg("An error occurred while syncing with Stripe.");
        }
    }

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Stripe administration
                </h2>
                <div className="pt-5 border-b-4 border-sea_blue-700" />
            </div>

            <div className="flex flex-col px-10 py-5">
                <div className="w-full max-w-5xl bg-gray-50 border rounded-lg p-4">

                    <div className="mt-4">
                        <div className="m-4 text-xl font-semibold">
                            Stripe settings
                        </div>
                        {settings && <div>
                            <div className="mb-2">
                                <div className="font-bold">Stripe portal URL</div>
                                <div>{settings.stripe_portal_url}</div>
                                <div className="font-bold">Professional tier</div>
                                <div>Subscription: {settings.tier1.lookup_key}, credits {settings.tier1.credits}, price {settings.tier1.price}</div>
                                <div>One-time payment: {settings.tier1.onetime_lookup_key}, credits {settings.tier1.onetime_credits}, price {settings.tier1.onetime_price}</div>
                                <div className="font-bold">Enterprise tier</div>
                                <div>Subscription: {settings.tier2.lookup_key}, credits {settings.tier2.credits}, price {settings.tier2.price}</div>
                                <div>One-time payment: {settings.tier2.onetime_lookup_key}, credits {settings.tier2.onetime_credits}, price {settings.tier2.onetime_price}</div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className="flex flex-col px-10 py-5">
                <div className="w-full max-w-5xl bg-gray-50 border rounded-lg p-4">
                    <div className="flex items-center space-x-4">
                        <div className="flex-1">
                        </div>
                        <div className="">
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className="mb-2">
                            Click this button to trigger a sync with Stripe.
                            This will update the local database with the latest data from Stripe.
                        </div>

                        <div className="mb-2">
                            <Button text="Run sync"
                                highlight={true}
                                loading={is_loading}
                                tooltip={"Sync with Stripe to update local data. This may take a few minutes."}
                                onClick={onRunSync} />
                        </div>

                        <div>
                            {status_msg}
                        </div>

                        {is_loading && <div className="w-full">
                            <LoadingSpinnerLimit />
                        </div>}
                        {sync_result && <div>

                            <div className="mx-5 font-bold">Statistics</div>

                            <table>
                                <tbody>
                                    <tr>
                                        <td className="pr-4">Subscriptions all</td>
                                        <td>{sync_result.subscriptions_all}</td>
                                    </tr>
                                    <tr>
                                        <td className="pr-4">Active</td>
                                        <td>{sync_result.subscriptions_active}</td>
                                    </tr>
                                    <tr>
                                        <td>Inactive</td>
                                        <td>{sync_result.subscriptions_inactive}</td>
                                    </tr>
                                    <tr>
                                        <td>Skipped</td>
                                        <td>{sync_result.subscriptions_skipped}</td>
                                    </tr>
                                    <tr>
                                        <td>Unknown</td>
                                        <td>{sync_result.subscriptions_unknown}</td>
                                    </tr>
                                    <tr>
                                        <td>Updated</td>
                                        <td>{sync_result.subscriptions_updated}</td>
                                    </tr>
                                    <tr>
                                        <td>Created</td>
                                        <td>{sync_result.subscriptions_created}</td>
                                    </tr>
                                    <tr>
                                        <td>Errored</td>
                                        <td>{sync_result.subscriptions_errored}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="mx-5 font-bold">Errors</div>

                            {sync_result.subscriptions_errored_details.map(x => {
                                return <div key={x.id} className="mx-5">
                                    {x.id}: {x.error}
                                </div>;
                            })}
                        </div>}
                    </div>
                </div>
            </div>
            <div className="flex flex-col px-10 py-5 overflow-y-auto">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Org</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Customer external id</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">External id</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Subscription UUID</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {subscriptions.length > 0 && subscriptions.map((subscription, idx) => (
                            <tr key={idx}>

                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    <Link to={`/admin/org/${subscription.org_uuid}`} className="underline">{subscription.org_title}</Link>
                                </td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{subscription.type}</td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{subscription.status}</td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{prettySmartDateTime(subscription.created_at)}</td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{subscription.customer_external_id}</td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    <Link
                                        to={`https://dashboard.stripe.com/subscriptions/${subscription.external_id}`}
                                        className="underline"
                                    >{subscription.external_id}</Link>
                                </td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{subscription.uuid}</td>
                            </tr>
                        ))}
                        {subscriptions.length === 0 && <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={4}>
                                No Stripe subscriptions found.
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </Fragment >;
};
