import {
    Fragment,
    useEffect,
    useState
} from "react";

import { Dialog, Transition } from "@headlessui/react";

import { Dropdown } from "./Dropdown";
import { Textbox } from "./Textbox";
import { Checkbox } from "./Checkbox";
import { Button } from "./Button";

type NewFieldOverrideDialogProps = {
    contexts: { uuid: string, name: string, fields: { uuid: string, name: string }[] }[];
    open: boolean;
    onClose: () => void;
    onSubmit: (
        trigger_context_uuid: string,
        trigger_field_uuid: string,
        trigger_value: string,
        override_type: "context" | "field",
        override_context_uuid: string,
        override_field_uuid: string
    ) => void;
}

export function NewFieldOverrideDialog({ contexts, open, onClose, onSubmit }: NewFieldOverrideDialogProps) {

    const [selected_trigger_context_uuid, setSelectedTriggerContextUuid] = useState<string>("");
    const [selected_trigger_field_uuid, setSelectedTriggerFieldUuid] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [override_type, setOverrideType] = useState<"context" | "field">("context");
    const [override_context_uuid, setOverrideContextUuid] = useState<string>("");
    const [override_field_uuid, setOverrideFieldUuid] = useState<string>("");

    // reset on open
    useEffect(() => {
        setSelectedTriggerContextUuid(contexts[0].uuid);
        setSelectedTriggerFieldUuid(contexts[0].fields[0]?.uuid ?? "");
        setValue("");
        setOverrideType("context");
    }, [open, contexts]);

    useEffect(() => {
        // get context right after trigger context
        const context_after_trigger_index = contexts.findIndex(context => context.uuid === selected_trigger_context_uuid) + 1;
        setOverrideContextUuid(contexts[context_after_trigger_index].uuid);
        if (override_type === "field") {
            // get first field of context after trigger context
            setOverrideFieldUuid(contexts[context_after_trigger_index].fields[0]?.uuid ?? "");
        } else {
            setOverrideFieldUuid("");
        }
    }, [ contexts, override_type, selected_trigger_context_uuid, selected_trigger_field_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

    // all but last context are possible trigger contexts
    const trigger_contexts = contexts.slice(0, -1);
    const trigger_context = contexts.find(context => context.uuid === selected_trigger_context_uuid);
    const trigger_context_index = contexts.findIndex(context => context.uuid === selected_trigger_context_uuid);

    // only contexts after trigger context are possible override contexts
    const override_contexts = contexts.slice(trigger_context_index + 1);
    const override_context = contexts.find(context => context.uuid === override_context_uuid);
    const override_context_index = contexts.findIndex(context => context.uuid === override_context_uuid);

    // we can call submit only when we have all the values given the type of override
    const can_submit = selected_trigger_context_uuid && selected_trigger_field_uuid && value &&
        (override_type === "context" ? override_context_uuid : override_context_uuid && override_field_uuid);

    return <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => onClose()}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg lg:max-w-xl sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        New Exception Rule
                                    </Dialog.Title>
                                    <div className="mt-4 pt-6 flex flex-col items-start gap-4 border-t border-space_blue-400">
                                        <div className="text-sm font-medium text-gray-700">When</div>
                                        <div className="grid grid-cols-3 gap-2 items-center w-full">
                                            <label htmlFor="context_uuid" className="pl-4 text-sm text-gray-700">Step</label>
                                            <div className="col-span-2">
                                                <Dropdown
                                                    values={trigger_contexts.map((context, idx) => `${idx + 1}. ${context.name}`)}
                                                    ids={trigger_contexts.map(context => context.uuid)}
                                                    selected={selected_trigger_context_uuid}
                                                    onChange={(context_uuid) => { setSelectedTriggerContextUuid(context_uuid); }}
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-3 gap-2 items-center w-full">
                                            <label htmlFor="field_uuid" className="pl-4 text-sm text-gray-700">Field</label>
                                            <div className="col-span-2">
                                                <Dropdown
                                                    values={trigger_context?.fields.map(field => field.name) ?? []}
                                                    ids={trigger_context?.fields.map(field => field.uuid) ?? []}
                                                    selected={selected_trigger_field_uuid}
                                                    onChange={(field_uuid) => { setSelectedTriggerFieldUuid(field_uuid); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-2 items-center w-full">
                                            <label htmlFor="value" className="pl-4 text-sm text-gray-700">Value</label>
                                            <div className="col-span-2">
                                                <Textbox
                                                    value={value}
                                                    onChange={(value) => setValue(value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="pt-6 grid grid-cols-3 gap-2 items-center w-full">
                                            <div className="text-sm font-medium text-gray-700">What</div>
                                            <div className="flex items-center gap-2">
                                                <Checkbox
                                                    checked={override_type === "context"}
                                                    setChecked={(checked) => setOverrideType(checked ? "context" : "field")}
                                                />
                                                <label htmlFor="context_override" className="text-sm text-gray-700">Step</label>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <Checkbox
                                                    checked={override_type === "field"}
                                                    setChecked={(checked) => setOverrideType(checked ? "field" : "context")}
                                                />
                                                <label htmlFor="field_override" className="text-sm text-gray-700">Field</label>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-2 items-center w-full">
                                            <label htmlFor="context_uuid" className="pl-4 text-sm text-gray-700">Step</label>
                                            <div className="col-span-2">
                                                <Dropdown
                                                    values={override_contexts.map((context, idx) => `${override_context_index + idx + 1}. ${context.name}`)}
                                                    ids={override_contexts.map(context => context.uuid)}
                                                    selected={override_context_uuid}
                                                    onChange={(context_uuid) => { setOverrideContextUuid(context_uuid); }}
                                                />
                                            </div>
                                        </div>
                                        {override_type === "field" && (
                                            <div className="grid grid-cols-3 gap-2 items-center w-full">
                                                <label htmlFor="field_uuid" className="pl-4 text-sm text-gray-700">Field</label>
                                                <div className="col-span-2">
                                                    <Dropdown
                                                        values={override_context?.fields.map(field => field.name) ?? []}
                                                        ids={override_context?.fields.map(field => field.uuid) ?? []}
                                                        selected={override_field_uuid}
                                                        onChange={(field_uuid) => { setOverrideFieldUuid(field_uuid); }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 sm:flex justify-center">
                                <Button
                                    text="Create"
                                    disabled={!can_submit}
                                    highlight={true}
                                    onClick={() => {
                                        onSubmit(
                                            selected_trigger_context_uuid,
                                            selected_trigger_field_uuid,
                                            value,
                                            override_type,
                                            override_context_uuid,
                                            override_field_uuid
                                        );
                                    }}
                                />
                                <Button
                                    text="Cancel"
                                    onClick={() => onClose()}
                                />
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root >

}
