import {
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import {
    Link,
    useParams
} from "react-router-dom";

import * as t from "../lib/types"
import { Backend } from "../lib/backend";
import {
    classNames,
    prettyDate,
    prettyTime
} from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";

import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { FullScreenText } from "../components/FullScreen";
import { Pagination } from "../components/Pagination";
import { Button } from "../components/Button";
import { PromptEditor } from "../components/PromptEditor";
import { PlayIcon } from "@heroicons/react/24/outline";


const limit = 10;

export function AdminPromptLog() {
    const { job_uuid } = useParams<{ job_uuid: string | undefined }>();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [prompt_log_list, setPromptLogList] = useState<t.IDebugPromptLogList | undefined>(undefined);
    const [offset, setOffset] = useState(0);
    const [full_screen_title, setFullScreenTitle] = useState<string | undefined>(undefined);
    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);
    const [current_log, setCurrentLog] = useState<t.IDebugPromptLog | undefined>(undefined);
    const [selected_button, setSelectedButton] = useState<{ index: number, type: "playground" |"prompt" | "result" } | undefined>(undefined);
    const [show_prompt_editor, setShowPromptEditor] = useState(false);

    useEffect(() => {
        setPromptLogList(undefined);
        setCurrentLog(undefined);
        setSelectedButton(undefined);
        Backend.getPromptLogs({ offset, limit, job_uuid }).then((prompt_log_list) => {
            setPromptLogList(prompt_log_list);
        });
    }, [offset, job_uuid]);

    const closePromptEditor = () => {
        setShowPromptEditor(false);
    };

    const togglePromptResult = () => {
        if (!current_log || !selected_button) {
            return;
        }

        if (full_screen_title === "prompt.json") {
            setFullScreenTitle("result.json");
            setFullScreenText(JSON.stringify(current_log.output, null, 2));
            setSelectedButton({ ...selected_button, type: "result" });
        } else {
            setFullScreenTitle("prompt.json");
            setFullScreenText(JSON.stringify(current_log.input, null, 2));
            setSelectedButton({ ...selected_button, type: "prompt" });
        }
    };

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="m-16 mb-6">
            <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                Prompt Log
            </h2>
            <div className="pt-5 border-b-4 border-sea_blue-700" />
        </div>

        <div className="flex flex-col px-10 py-5">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" />
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Time</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">User</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Model</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Task</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Input</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tokens</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Duration</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {prompt_log_list && prompt_log_list.logs.map((log, idx) => (
                            <tr key={idx}>
                                <td className="whitespace-nowrap py-4 pl-2 text-sm font-semibold text-gray-500 sm:pl-3 align-top">{idx + offset + 1}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top">{prettyDate(log.created_at)}<br />{prettyTime(log.created_at)}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top"><Link to={`/admin/user/${log.user_uuid}`} className="underline">{log.user_email}</Link></td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top">{log.model}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top">{log.task_type}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top">{log.title}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top">{log.input_name}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top">IN: {log.num_input_tokens}<br />OUT: {log.num_output_tokens}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top">{Math.round(log.duration_ms / 100) / 10} s</td>
                                <td className="px-3 py-4 text-sm text-gray-500 align-top w-48 text-right">
                                    <div className="flex gap-2 justify-end">
                                        <Button
                                            highlight={selected_button?.index === idx && selected_button?.type === "playground"}
                                            icon={PlayIcon}
                                            onClick={() => {
                                                setCurrentLog(log);
                                                setShowPromptEditor(true);
                                                setSelectedButton({ index: idx, type: "playground" });
                                            }}
                                        />
                                        <Button
                                            highlight={selected_button?.index === idx && selected_button?.type === "prompt"}
                                            text="Prompt"
                                            onClick={() => {
                                                setCurrentLog(log);
                                                setFullScreenTitle("prompt.json");
                                                setFullScreenText(JSON.stringify(log.input, null, 2));
                                                setShowPromptEditor(false);
                                                setSelectedButton({ index: idx, type: "prompt" });
                                            }}
                                        />
                                        <Button
                                            highlight={selected_button?.index === idx && selected_button?.type === "result"}
                                            text="Result"
                                            onClick={() => {
                                                setCurrentLog(log);
                                                setFullScreenTitle("result.json");
                                                setFullScreenText(JSON.stringify(log.output, null, 2));
                                                setShowPromptEditor(false);
                                                setSelectedButton({ index: idx, type: "result" });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {!prompt_log_list && <tr>
                            <td className="whitespace-nowrap py-4 pl-2 text-sm font-semibold text-gray-500 sm:pl-3 align-top" colSpan={4}>
                                <LoadingSpinnerLimit />
                            </td>
                        </tr>}
                    </tbody>
                </table>
                <Pagination offset={offset} limit={limit} total={prompt_log_list?.total || 0} setOffset={setOffset} />
            </div>
            <FullScreenText
                title={full_screen_title || ""}
                text={full_screen_text || ""}
                show={full_screen_text !== undefined}
                onClose={() => {
                    setFullScreenText(undefined);
                    setCurrentLog(undefined);
                }}
                onToggle={togglePromptResult}
            />
            {show_prompt_editor && current_log && (
                <PromptEditor
                    input={current_log.input}
                    onClose={closePromptEditor}
                />
            )}
        </div>
    </div>;
};
