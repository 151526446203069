import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../lib/scraper.slice";
import { Link, useNavigate } from "react-router-dom";

import {
    ArrowPathIcon,
    BookOpenIcon,
    BugAntIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    PlusCircleIcon,
    TrashIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import { TbQuote } from "react-icons/tb";

import { Tooltip } from "react-tooltip";

import {
    CONTEXT_TYPES,
    EXTRACT_CONFIRMATION_STATUS,
    USER_ROLES
} from "../lib/consts";
import {
    IRecord,
    IContextBase,
    IScrapeBase,
    IItem,
    IItemBase,
    IContextField,
    IScrapeEvalDiff,
    IRecordRaw,
    IScrapeDocument,
    ITemplate,
    IScrapeEvalMetrics,
    IFieldNameUuidPair
} from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";
import {
    classNames,
    deepCopy,
    getHierarchicalContextSchema,
    getHierarchicalRecord,
    prettyDateTime,
    prettyNumber
} from "../lib/utils";
import {
    ContextType,
    IConfidence,
    IContextFieldType,
    IContextNoUUID,
    IExtractConfirmationLog,
    IItemValidationSummary,
    IPrimitiveType,
    IScrapeDebugLog,
    IValidationResult,
    IValidations
} from "../lib/backend/extractions.types.generated";

import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { ConfirmModal } from "./ConfirmModal";
import { Checkbox } from "./Checkbox";
import { FullScreen, FullScreenText } from "./FullScreen";
import { Pill } from "./Pill";
import { EditExampleModal } from "./ExampleModals";
import { ErrorMessageBar } from "./ErrorMessageBar";
import { ProcessFlowBody, ProcessFlowHead } from "./ProcessFlow";
import { ValidationSummary } from "./ValidationSummary";

const TOOLTIP_STYLE = {
    backgroundColor: "#f0f9ff",
    borderWidth: "1px",
    color: "#111827",
    borderRadius: "0.5rem",
    padding: "0.5rem",
    fontSize: "0.875rem"
};

function FieldValueRender(props: { value: IPrimitiveType }) {
    const { value } = props;

    // check if not string
    if (typeof value !== "string") {
        return <Fragment>{value}</Fragment>
    }

    // check if we have new line characters
    const has_new_lines = value.includes("\n");
    if (has_new_lines) {
        return <div className="flex flex-col items-start gap-1">
            {value.split("\n").map((line, idx) => <p key={idx} className="text-sm">{line}&nbsp;</p>)}
        </div>
    }

    return <Fragment>{value}</Fragment>
}

type ScrapeTableProps = {
    scrape_idx: number,
    context: IContextBase,
    scrape: IScrapeBase,
    setRecords: (scrape_idx: number, records: IRecord[]) => void,
    markChanged: () => void
}

function ScrapeTable(props: ScrapeTableProps) {
    const { scrape_idx, context, scrape, setRecords, markChanged } = props;
    const { field_name_uuid_pairs, records } = scrape;

    const is_object = context?.type === CONTEXT_TYPES.object;
    const is_array = context?.type === CONTEXT_TYPES.array || context?.type === CONTEXT_TYPES.lookup_table;

    const columns = context.fields.map((f, idx) => ({
        title: f.confirm_name ?? f.name,
        field_name: field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        idx,
        hide: f.skip_on_confirm === true,
        validators: f.validators && f.validators.length > 0,
        required: f.validators && f.validators.some(v => v.required)
    }));

    /// TABLE EDITING
    const handleBlur = (e: any, row_idx: number, col_idx: number) => {
        const value = e.target.innerText.trim();
        const field_name = columns[col_idx].field_name;
        if (field_name && value !== records[row_idx].val[field_name]) {
            const new_records: IRecord[] = [...records];
            new_records[row_idx].val[field_name] = value;
            setRecords(scrape_idx, deepCopy(new_records));
        }
    };

    /// ROW EDITING

    const deleteRow = (row_idx: number) => {
        const new_records: IRecord[] = [
            ...records.slice(0, row_idx),
            ...records.slice(row_idx + 1)
        ];
        setRecords(scrape_idx, deepCopy(new_records));
    }

    const addRow = () => {
        const val: IRecordRaw = {};
        for (const column of columns) {
            if (column.field_name !== undefined) {
                val[column.field_name] = "";
            }
        }
        const new_records: IRecord[] = [...records, { val, val_raw: val }];
        setRecords(scrape_idx, deepCopy(new_records));
    }

    const moveRow = (row_idx: number, direction: "up" | "down") => {
        if (direction === "up" && row_idx === 0) { return; }
        if (direction === "down" && row_idx === records.length - 1) { return; }
        const diff = direction === "up" ? -1 : 1;
        const new_records: IRecord[] = [...records];
        const temp = new_records[row_idx];
        new_records[row_idx] = new_records[row_idx + diff];
        new_records[row_idx + diff] = temp;
        setRecords(scrape_idx, deepCopy(new_records));
    }

    // shortcuts for case if object
    const record_val: IRecordRaw = records[0]?.val || {}
    const confidence: IConfidence = records[0]?.confidence || {}
    const fields_validations: IValidations = records[0]?.fields_validations || {}

    return <Fragment>
        <div className="outer-div">
            {is_object && <table>
                <tbody>
                    {columns.filter(c => !c.hide).map((column, idx) => <tr key={idx}>
                        <td className="py-2 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top" colSpan={3}>
                            {column.title}
                        </td>

                        {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2) : "N/A"}`}
                            className={classNames("w-1 border-l border-t border-b border-gray-200 cursor-pointer",
                                confidence && confidence[column.field_name] ?
                                    (confidence[column.field_name] < 0.5 ? "bg-red-100" :
                                        confidence[column.field_name] < 0.8 ? "bg-yellow-100" :
                                            "bg-gray-100") :
                                    "bg-gray-50",
                            )}></td>}

                        {column.field_name === undefined && <td className="w-1 border-l border-t border-b border-gray-200 cursor-pointer bg-gray-50"></td>}

                        {column.field_name !== undefined && <td
                            className={classNames(
                                "border-t border-b border-gray-200 py-2 px-2 min-w-[100px] whitespace-pre-wrap overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text",
                                (fields_validations && fields_validations[column.field_name]) ?
                                    (fields_validations[column.field_name].every(v => v.status === "pass") ? "text-green-600" : "text-red-600") :
                                    "border-r",
                                (fields_validations && fields_validations[column.field_name]) &&
                                    fields_validations[column.field_name].some(v => v.status === "fail") ? "bg-red-50" : ""
                            )}
                            colSpan={(fields_validations && fields_validations[column.field_name]) ? 1 : 2}
                            contentEditable={true}
                            onInput={() => markChanged()}
                            onBlur={e => handleBlur(e, 0, column.idx)}
                            dangerouslySetInnerHTML={{ __html: record_val[column.field_name] !== undefined ? ("" + (record_val[column.field_name] ?? "")) : "" }}
                        />}

                        {column.field_name === undefined && <td
                            className="border-t border-r border-b border-gray-200 py-1 px-2 min-w-[100px] whitespace-normal overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text"
                            colSpan={2}>
                        </td>}

                        {(column.field_name !== undefined && fields_validations && fields_validations[column.field_name]) && <td
                            className="px-1 py-2 w-4 align-middle  text-gray-300 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white"
                            data-tooltip-id={`validation-tooltip-${scrape.uuid}`}
                            data-tooltip-html={fields_validations[column.field_name]
                                .map(v => `<p style="color: ${v.status === "pass" ? "green" : "red"};">${v.name}${v.reason ? `: ${v.reason}` : ""}</p>`).join("")}
                        >
                            {fields_validations[column.field_name].every(v => v.status === "pass") && <CheckIcon className="w-5 h-5 text-green-500" />}
                            {fields_validations[column.field_name].some(v => v.status !== "pass") && <XMarkIcon className="w-5 h-5 text-red-500" />}
                        </td>}
                    </tr>)}
                </tbody>
            </table>}

            {is_array && <table>
                <thead>
                    <tr>
                        <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>
                        {columns.filter(c => !c.hide).map((column, idx) => <Fragment key={idx}>
                            <th className="py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top"
                                colSpan={column.validators ? 3 : 2}>
                                {column.title}
                            </th>
                        </Fragment>)}
                    </tr>
                </thead>
                <tbody>
                    {records.map(({ val, confidence, fields_validations, validations }, row_idx) => <tr key={row_idx}>
                        <td className="py-2 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>

                        {columns.filter(c => !c.hide).map((column) => <Fragment key={column.idx}>
                            {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2) : "N/A"}`}
                                className={classNames("w-1 border-l border-t border-b border-gray-200 cursor-pointer",
                                    confidence && confidence[column.field_name] ?
                                        (confidence[column.field_name] < 0.5 ? "bg-red-100" :
                                            confidence[column.field_name] < 0.8 ? "bg-yellow-100" :
                                                "bg-gray-100") :
                                        "bg-gray-50",
                                )}></td>}
                            {column.field_name === undefined && <td className="w-1 border-l border-t border-b border-gray-200 cursor-pointer bg-gray-50"></td>}

                            {column.field_name !== undefined && <td
                                className={classNames(
                                    "border-t border-b border-gray-200 py-2 px-2 min-w-[100px] max-w-[200px] whitespace-pre-wrap overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text",
                                    (fields_validations && fields_validations[column.field_name]) ?
                                        (fields_validations[column.field_name].every(v => v.status === "pass") ? "text-green-600" : "text-red-600") :
                                        "",
                                    (fields_validations && fields_validations[column.field_name]) &&
                                        fields_validations[column.field_name].some(v => v.status === "fail") ? "bg-red-50" : ""
                                )}
                                contentEditable={true}
                                onInput={() => markChanged()}
                                onBlur={e => handleBlur(e, row_idx, column.idx)}
                                dangerouslySetInnerHTML={{ __html: val[column.field_name] !== undefined ? ("" + (val[column.field_name] ?? "")) : "" }}
                            />}
                            {column.field_name === undefined && <td className="border-t border-b border-gray-200 py-1 px-2 min-w-[100px] max-w-[200px] whitespace-normal overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text"></td>}

                            {column.field_name !== undefined && column.validators && <td
                                className={classNames(
                                    "px-1 py-2 w-4 align-middle  text-gray-300 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white",
                                    (fields_validations && fields_validations[column.field_name]) &&
                                        fields_validations[column.field_name].some(v => v.status === "fail") ? "bg-red-50" : ""
                                )}
                                data-tooltip-id={`validation-tooltip-${scrape.uuid}`}
                                data-tooltip-html={fields_validations && fields_validations[column.field_name] ?
                                    fields_validations[column.field_name]
                                        .map(v => `<p style="color: ${v.status === "pass" ? "green" : "red"};">${v.name}${v.reason ? `: ${v.reason}` : ""}</p>`).join("") : ""}
                            >
                                {fields_validations && fields_validations[column.field_name] && fields_validations[column.field_name].every(v => v.status === "pass") && <CheckIcon className="w-5 h-5 text-green-500" />}
                                {fields_validations && fields_validations[column.field_name] && fields_validations[column.field_name].some(v => v.status !== "pass") && <XMarkIcon className="w-5 h-5 text-red-500" />}
                            </td>}
                        </Fragment>)}
                        <td
                            className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border-t border-b  border-gray-200 cursor-pointer hover:text-white hover:bg-sea_blue-300"
                            onClick={() => moveRow(row_idx, "up")}>
                            <ChevronUpIcon className="w-4 h-4" />
                        </td>
                        <td
                            className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white hover:bg-sea_blue-300"
                            onClick={() => moveRow(row_idx, "down")}>
                            <ChevronDownIcon className="w-4 h-4" />
                        </td>
                        <td
                            className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border border-gray-200 cursor-pointer hover:text-white hover:bg-sea_blue-300"
                            onClick={() => deleteRow(row_idx)}>
                            <TrashIcon className="w-4 h-4" />
                        </td>
                        {validations && validations.length > 0 && <td
                            className="py-2 px-2 text-left text-sm font-semibold align-top"
                            data-tooltip-id={`validation-tooltip-${scrape.uuid}`}
                            data-tooltip-html={validations.map(v => `<p style="color: ${v.status === "pass" ? "green" : "red"};">${v.name}${v.reason ? `: ${v.reason}` : ""}</p>`).join("")}
                        >
                            {validations.every(v => v.status === "pass") && <CheckIcon className="w-5 h-5 text-green-500" />}
                            {validations.some(v => v.status !== "pass") && <XMarkIcon className="w-5 h-5 text-red-500" />}
                        </td>}
                    </tr>)}
                    <tr>
                        <td
                            className="py-1 px-2 bg-gray-100 border border-gray-200 text-center text-sm text-gray-600 align-middle cursor-pointer hover:text-white hover:bg-sea_blue-300"
                            onClick={addRow}>
                            +
                        </td>
                    </tr>
                </tbody>
            </table>}
        </div>
        <Tooltip
            id={`validation-tooltip-${scrape.uuid}`}
            place="bottom"
            style={TOOLTIP_STYLE}
            opacity={1} />
    </Fragment >;
}

function checkIfAnyFail(scrapes: IScrapeBase[]): boolean {
    // check if at least one field, row or context validation fails
    for (const scrape of scrapes) {
        for (const record of scrape.records) {
            if (record.fields_validations) {
                for (const field of Object.keys(record.fields_validations)) {
                    for (const validation_result of record.fields_validations[field]) {
                        if (validation_result.status === "fail") {
                            return true;
                        }
                    }
                }
            }
            if (record.validations) {
                for (const validation_result of record.validations) {
                    if (validation_result.status === "fail") {
                        return true;
                    }
                }
            }
        }
        for (const validation_result of scrape.validations) {
            if (validation_result.status === "fail") {
                return true;
            }
        }
    }
    // all fine
    return false;
}

function getDeepCopyRecords(scrape: IScrapeBase, is_object: boolean): IRecord[] {
    // in case we have an object, we need to make sure we have at least one record
    if (is_object && scrape.records.length === 0) {
        const record: IRecord = { val: {}, val_raw: {} };
        for (const field of scrape.field_name_uuid_pairs) {
            record.val[field.name] = "";
            record.val_raw[field.name] = "";
        }
        return [record];
    }
    // in case we have an array or enough records for an object, we can just deep copy
    return deepCopy(scrape.records);
}

type ItemTablesProps = {
    item: IItem;
    validation_summary?: IItemValidationSummary;
    onItemUpdate: () => void;
    changeDecimalSeparator: (decimal_separator: "," | ".") => Promise<void>;
};

export function ItemTables(props: ItemTablesProps) {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const { item: init_item, validation_summary: init_validation_summary, onItemUpdate, changeDecimalSeparator } = props;

    // current state
    const [scrapes, setScrapes] = useState<{ scrape: IScrapeBase, context: IContextBase }[]>([]);
    // validation state
    const [validation_summary, setValidationSummary] = useState<IItemValidationSummary | undefined>(undefined);
    const [validation_fail, setValidationFail] = useState<boolean>(false);
    // decimal separator
    const [decimal_separator, setDecimalSeparator] = useState<"," | "." | undefined>(undefined);
    const [change_decimal_separator, setChangeDecimalSeparator] = useState<"," | "." | undefined>(undefined);
    const [is_decimal_recompute, setIsDecimalRecompute] = useState(false);
    const [decimal_confirm_open, setDecimalConfirmOpen] = useState(false);
    // example cloning
    const [is_cloned, setIsCloned] = useState(false);
    const [is_new_example_modal_open, setIsNewExampleModalOpen] = useState<boolean>(false);
    const [is_being_cloned, setIsBeingCloned] = useState(false);
    // flags
    const [is_changed, setIsChanged] = useState(false);
    const [is_confirming, setIsConfirming] = useState(false);
    const [is_rejecting, setIsRejecting] = useState(false);
    const [is_removing, setIsRemoving] = useState(false);
    const [is_verifying, setIsVerifying] = useState(false);
    const [is_saving, setIsSaving] = useState(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);
    const [retry_confirm_open, setRetryConfirmOpen] = useState(false);

    // when loaded, copy over initial values for scrapes and scrape validations
    useEffect(() => {
        // put scrapes and contexts together in pairs
        const new_scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
        for (const scrape of init_item.scrapes) {
            const context = init_item.template.contexts.find(c => c.uuid === scrape.context_uuid);
            if (context === undefined) { continue; }
            // we have a scrape
            new_scrapes.push({
                scrape: {
                    ...scrape,
                    records: getDeepCopyRecords(scrape, context.type === CONTEXT_TYPES.object)
                },
                context
            });
            // check if we have decimal separator (at least one field was numeric)
            if (scrape.extraction_info.decimal_separator !== undefined) {
                setDecimalSeparator(scrape.extraction_info.decimal_separator);
            }
        }
        // order scrapes so we first have any single-row scrapes, then any multi-row scrapes
        new_scrapes.sort((a, b) => a.context.weight_score - b.context.weight_score);
        setScrapes(new_scrapes);
        // check if any validation fails
        setValidationFail(checkIfAnyFail(init_item.scrapes));
        // check if item is cloned
        setIsCloned(init_item.details.clone_example_item_uuid !== undefined);
    }, [init_item]);

    // when loaded, copy over initial value for validation summary
    useEffect(() => {
        setValidationSummary(init_validation_summary);
    }, [init_validation_summary]);

    const setRecords = (scrape_idx: number, records: IRecord[]) => {
        const new_scrapes = [...scrapes];
        new_scrapes[scrape_idx].scrape.records = records;
        setScrapes(new_scrapes);
        setIsChanged(true);
    }

    const handleUndo = async () => {
        for (const scrape of init_item.scrapes) {
            const scrape_context = scrapes.find(({ context }) => context.uuid === scrape.context_uuid);
            if (scrape_context === undefined) { continue; }
            scrape_context.scrape.records = getDeepCopyRecords(scrape, scrape_context.context.type === CONTEXT_TYPES.object);
        }
        setIsChanged(false);
    }

    // only available when no verification required for the extraction
    const handleSave = async () => {
        setIsSaving(true);
        // editable items have confirmation status so we can safely assume that confirmation_uuid exists
        await BackendObj.extractions.updateExtractConfirmationScrape({
            confirmation_uuid: init_item.extract_confirmations_uuid as string,
            scrapes: scrapes.map(s => ({
                scrape_uuid: s.scrape.uuid,
                records: s.scrape.records,
                extraction_info: { decimal_separator },
                validations: s.scrape.validations
            })),
        });
        setIsChanged(false);
        setIsSaving(false);
    };

    // only available when verification required for the extraction
    const handleVerifyAndSave = async () => {
        setIsVerifying(true);
        try {
            // verify
            const { validated_scrapes, validation_summary: new_validation_summary, message } = await BackendObj.extractions.verifyItem({
                item_uuid: init_item.uuid,
                scrapes: scrapes.map(s => ({
                    uuid: s.scrape.uuid,
                    context_uuid: s.context.uuid,
                    // remove existing validations
                    records: s.scrape.records.map((r): IRecord => ({
                        val_raw: r.val_raw,
                        val: r.val,
                        confidence: r.confidence
                    })),
                })),
            });
            // check if all ok
            if (message) {
                // handle error
                setErrorMessage(message);
            } else if (validated_scrapes) {
                // all ok, update records and validations
                const new_scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
                for (const scrape of validated_scrapes) {
                    const scrape_idx = scrapes.findIndex(s => s.scrape.uuid === scrape.uuid);
                    if (scrape_idx !== -1) {
                        new_scrapes.push({
                            scrape: {
                                ...scrapes[scrape_idx].scrape,
                                records: deepCopy(scrape.records),
                                validations: scrape.validations ?? []
                            },
                            context: scrapes[scrape_idx].context
                        });
                    }
                }
                setScrapes(new_scrapes);
                setValidationSummary(new_validation_summary);
                setValidationFail(checkIfAnyFail(new_scrapes.map(s => s.scrape)));

                // save updated item
                setIsSaving(true);
                await BackendObj.extractions.updateExtractConfirmationScrape({
                    confirmation_uuid: init_item.extract_confirmations_uuid as string,
                    scrapes: new_scrapes.map(s => ({
                        scrape_uuid: s.scrape.uuid,
                        records: s.scrape.records,
                        extraction_info: { decimal_separator },
                        validations: s.scrape.validations
                    })),
                });
                setIsChanged(false);
            }
        } catch (err) {
            setErrorMessage("Error verifying the item");
        }
        setIsSaving(false);
        setIsVerifying(false);
    }

    const handleChangeDecimalSeparator = async (decimal_separator: "," | ".") => {
        setIsDecimalRecompute(true);
        await changeDecimalSeparator(decimal_separator);
        setIsDecimalRecompute(false);
    }

    // item-level actions

    const handleRemove = async () => {
        setIsRemoving(true);
        setErrorMessage(undefined);
        try {
            await Backend.deleteItem({ item_uuid: init_item.uuid });
            if (onItemUpdate !== undefined) { onItemUpdate(); }
        } catch (err) {
            setErrorMessage("Error deleting the item");
        }
        setIsRemoving(false);
    };

    const handleRetry = async () => {
        navigate(`/extraction/new/${init_item.endpoint_uuid ?? init_item.template_uuid}/${init_item.uuid}`);
    }

    const handleReject = async () => {
        setIsRejecting(true);
        await BackendObj.extractions.updateExtractConfirmationItemStatus({
            confirmation_uuid: init_item.extract_confirmations_uuid as string,
            status: EXTRACT_CONFIRMATION_STATUS.rejected
        });
        setIsRejecting(false);
        if (onItemUpdate !== undefined) { onItemUpdate(); }
    }

    const handleConfirm = async () => {
        setIsConfirming(true);
        await BackendObj.extractions.updateExtractConfirmationItemStatus({
            confirmation_uuid: init_item.extract_confirmations_uuid as string,
            status: EXTRACT_CONFIRMATION_STATUS.confirmed
        });
        setIsConfirming(false);
        if (onItemUpdate !== undefined) { onItemUpdate(); }
    }

    const startCreateExample = () => {
        setIsNewExampleModalOpen(true);
    }

    const createExample = async (comment: string) => {
        setIsNewExampleModalOpen(false);
        if (init_item === undefined) { return; }
        setIsBeingCloned(true);
        try {
            await BackendObj.extractions.createExampleFromItem({ item_uuid: init_item?.uuid, comment })
        } catch (err) {
            console.error(err);
        }
        setIsCloned(true);
        setIsBeingCloned(false);
    }

    // for now, only lookup tables are not editable
    const isScrapeEditable = (scrape: IScrapeBase) => {
        const scrape_context = scrapes.find(({ context }) => context.uuid === scrape.context_uuid);
        if (scrape_context === undefined) { return false; }
        return scrape_context.context.type !== "lookup_table";
    };

    const is_numeric = scrapes.some(({ context }) => context.fields.some(f => f.datatype === "number")) && decimal_separator !== undefined;
    const is_verify = scrapes.some(({ context }) => context.fields.some(f => f.validators)) ||
        scrapes.some(({ context }) => context.row_validators.length > 0) ||
        scrapes.some(({ context }) => context.context_validators.length > 0);

    const disable_buttons = is_confirming || is_rejecting || is_removing || is_being_cloned;

    return <div className="space-y-4">
        {init_item.details.retry_new_item_uuids !== undefined && init_item.details.retry_new_item_uuids.length > 0 &&
            <div className="p-4 border bg-red-100 rounded text-gray-900 text-sm max-w-5xl">
                {init_item.details.retry_new_item_uuids.length === 1 && <p>
                    There is a <Link to={`/confirm/${init_item.details.retry_new_item_uuids[0]}`} className="text-space_blue-600 underline">new version</Link> of this extraction.
                </p>}
                {init_item.details.retry_new_item_uuids.length > 1 && <p>
                    There are {init_item.details.retry_new_item_uuids.length} new versions of this extraction: {init_item.details.retry_new_item_uuids.map((uuid, idx) =>
                        <Fragment key={idx}>{idx > 0 && ", "}<Link to={`/confirm/${uuid}`} className="text-space_blue-600 underline">version {idx + 1}</Link></Fragment>)}
                </p>}
            </div>}
        <div className="p-4 border bg-yellow-100 rounded text-gray-900 max-w-5xl">
            <div className="flex flex-row items-center gap-x-2 text-sm">
                {!validation_fail && !is_changed && <p>Edit extracted data or confirm extractions.</p>}
                {!validation_fail && is_changed && <div>
                    <p className="pb-2">Extractions edited and not saved.</p>
                    <p>You need to <b>save</b> or <b>undo</b> changes before you can confirm.</p>
                </div>}
                {validation_fail && <div>
                    <p className="pb-2">Some required values fail verification (highlighted in red).</p>
                    <p>You need to correct the values and <b>verify</b> before you can confirm.</p>
                </div>}

                <div className="flex-grow"></div>
                <div className="flex flex-row">
                    <Button
                        icon={XMarkIcon}
                        text="Reject"
                        disabled={disable_buttons}
                        onClick={handleReject}
                        loading={is_rejecting} />
                    <Button
                        icon={CheckIcon}
                        text="Confirm"
                        highlight={true}
                        disabled={validation_fail || is_changed || disable_buttons}
                        onClick={handleConfirm}
                        loading={is_confirming} />
                </div>
            </div>
        </div>

        <div className="max-w-5xl">
            {validation_summary && <ValidationSummary summary={validation_summary} />}
        </div>

        <div className="flex flex-row items-center max-w-5xl">
            <Button icon={TrashIcon} text="Remove" disabled={disable_buttons} onClick={() => handleRemove()} loading={is_removing} />
            <Button icon={ArrowPathIcon} text="Retry" disabled={disable_buttons} onClick={() => setRetryConfirmOpen(true)} />
            {is_cloned && <Pill text="Example" type="default" />}
            {!is_cloned && <Button icon={is_being_cloned ? undefined : PlusCircleIcon} text="Examples" disabled={disable_buttons} loading={is_being_cloned} onClick={startCreateExample} />}

            <div className="flex-grow"></div>
            {is_changed && <p className="pr-2 text-gray-500 font-thin text-sm">(unsaved changes)</p>}
            {is_numeric && <div className="pr-2">
                <Dropdown
                    ids={[",", "."]}
                    values={["Decimal comma", "Decimal dot"]}
                    selected={decimal_separator}
                    disabled={is_decimal_recompute}
                    onChange={(id) => { setChangeDecimalSeparator(id as "," | "."); setDecimalConfirmOpen(true); }} />
            </div>}
            <Button text="Undo" highlight={false} disabled={!is_changed} onClick={handleUndo} />
            {!is_verify && <Button text="Save" highlight={true} disabled={!is_changed || is_saving} loading={is_saving} onClick={handleSave} />}
            {is_verify && <Button text="Verify & Save" highlight={true} disabled={is_verifying || is_saving} loading={is_verifying || is_saving} onClick={handleVerifyAndSave} />}
        </div>

        <div className="py-4">
            {scrapes.map((scrape, idx) => <Fragment key={idx}>
                <ProcessFlowHead label={`Step ${idx + 1}`}>
                    <div className="flex flex-row items-center max-w-4xl gap-2">
                        <span className={classNames(
                            "flex flex-row items-center gap-x-2 text-sm font-semibold text-gray-900",
                            scrapes[idx]?.scrape.validations.length > 0 ?
                                (scrapes[idx]?.scrape.validations.every(v => v.status === "pass") ? "text-green-600 cursor-pointer" : "text-red-600 cursor-pointer") :
                                "")}
                            data-tooltip-id={`step-${idx}-validation-tooltip`}
                            data-tooltip-html={scrapes[idx]?.scrape.validations.map(v => `<p style="color: ${v.status === "pass" ? "green" : "red"};">${v.name}${v.reason ? `: ${v.reason}` : ""}</p>`).join("")}
                        >
                            {scrape.context.name}
                            {scrapes[idx]?.scrape.validations.length > 0 && scrapes[idx]?.scrape.validations.every(v => v.status === "pass") && <CheckIcon className="w-5 h-5 text-green-500" />}
                            {scrapes[idx]?.scrape.validations.length > 0 && scrapes[idx]?.scrape.validations.some(v => v.status !== "pass") && <XMarkIcon className="w-5 h-5 text-red-500" />}
                        </span>

                        {is_admin && scrape.scrape.debug_log.length > 0 && (
                            <BugAntIcon
                                className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-700"
                                onClick={() => setFullScreenText(JSON.stringify(scrape.scrape.debug_log, null, 2))}
                            />
                        )}
                        {scrape.context.extract_params.skip_on_confirm && <p className="text-gray-400 text-xs font-normal">(details hidden)</p>}
                    </div>
                </ProcessFlowHead>
                <ProcessFlowBody>
                    {!scrape.context.extract_params.skip_on_confirm && (isScrapeEditable(scrape.scrape) ?
                        <ScrapeTable key={idx}
                            scrape_idx={idx}
                            context={scrape.context}
                            scrape={scrape.scrape}
                            setRecords={setRecords}
                            markChanged={() => setIsChanged(true)}
                        /> :
                        <ViewScrapeTableSimple
                            key={idx}
                            context={scrape.context}
                            scrape={scrape.scrape}
                            input_documents={init_item.documents}
                            show_all_fields={false}
                            confirmation_status={init_item.extract_confirmations_status}
                        />)}
                </ProcessFlowBody>
                <FullScreenText
                    text={full_screen_text || ""}
                    show={full_screen_text !== undefined}
                    onClose={() => setFullScreenText(undefined)}
                />
                <Tooltip
                    id={`step-${idx}-validation-tooltip`}
                    place="bottom"
                    style={TOOLTIP_STYLE}
                    opacity={1} />
            </Fragment>)}
            <ProcessFlowHead label="Done" />
        </div>

        <ConfirmModal
            open={decimal_confirm_open}
            title="Change Decimal Separator"
            message={["Changing the decimal separator will reset all unsaved changes. Do you want to continue?"]}
            confirm="Change"
            cancel="Cancel"
            onClose={confirm => {
                setDecimalConfirmOpen(false);
                if (confirm && change_decimal_separator) {
                    handleChangeDecimalSeparator(change_decimal_separator);
                } else {
                    setChangeDecimalSeparator(undefined);
                }
            }} />
        <ConfirmModal
            open={retry_confirm_open}
            title="Retry Extraction"
            message={["Retrying will delete the current extraction and create a new one. Neither reject nor confirm will be called on the deleted extraction."]}
            confirm="Retry"
            cancel="Cancel"
            onClose={confirm => {
                setRetryConfirmOpen(false);
                if (confirm) {
                    handleRetry();
                }
            }} />
        <EditExampleModal
            type="add"
            open={is_new_example_modal_open}
            init_comment=""
            onUpdateExample={createExample}
            onClose={() => setIsNewExampleModalOpen(false)} />
        <ErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)}
            is_sidebar_large_override={false} />
    </div >;
}

type ViewObjectScrapeTableProps = {
    context: IContextBase,
    scrape: IScrapeBase,
    show_validation_results: boolean,
    show_all_fields: boolean
}

function ViewObjectScrapeTable(props: ViewObjectScrapeTableProps) {
    const { context, scrape, show_validation_results, show_all_fields } = props;

    const fields = show_all_fields ? context.fields.map(f => ({
        title: f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type
    })) : context.fields.filter(f => !f.skip_on_confirm).map(f => ({
        title: f.confirm_name ?? f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type
    }));

    const record_val: IRecordRaw = scrape.records[0]?.val || {}
    const confidence: IConfidence = scrape.records[0]?.confidence || {}
    const fields_validations: IValidations = scrape.records[0]?.fields_validations || {}

    return <div className="px-1 outer-div">
        <table className="py-4">
            <tbody>
                {fields.map(({ title, field_name, type }, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top",
                        type === "compute" ? "italic" : ""
                    )}>
                        {title}
                    </td>
                    <td title={`Confidence: ${field_name && confidence[field_name] ? prettyNumber(confidence[field_name], 2) : "N/A"}`}
                        className={classNames("w-1 border-l border-t border-b cursor-pointer",
                            field_name && confidence[field_name] ?
                                (confidence[field_name] < 0.5 ? "bg-red-100" :
                                    confidence[field_name] < 0.8 ? "bg-yellow-100" :
                                        "bg-gray-100") :
                                "bg-gray-50",
                        )}></td>
                    {field_name === undefined && <td className="border-t border-b border-r py-1 px-2"></td>}
                    {field_name !== undefined && <td
                        className={classNames(
                            "border-t border-b border-r py-1 px-2 min-w-[100px] overflow-hidden hover:bg-sea_blue-100 text-left text-sm align-top cursor-text",
                            (show_validation_results && field_name && fields_validations[field_name]) ?
                                (fields_validations[field_name].every(v => v.status === "pass") ? "text-green-600" :
                                    fields_validations[field_name].every(v => v.status === "warn") ? "text-red-600" :
                                        fields_validations[field_name].every(v => v.status === "fail") ? "text-red-600" :
                                            "text-violet-600") :
                                ""
                        )}
                    >
                        <FieldValueRender value={record_val[field_name] ?? ""} />
                    </td>}
                </tr>)}
            </tbody>
        </table>
    </div>
}

type ViewArrayScrapeTableProps = {
    context: IContextBase,
    scrape: IScrapeBase,
    show_validation_results: boolean,
    show_all_fields: boolean
}

function ViewArrayScrapeTable(props: ViewArrayScrapeTableProps) {
    const { context, scrape, show_validation_results, show_all_fields } = props;

    const columns = show_all_fields ? context.fields.map(f => ({
        title: f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type
    })) : context.fields.filter(f => !f.skip_on_confirm).map(f => ({
        title: f.confirm_name ?? f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type
    }));

    return <div className="px-1 outer-div">
        <table className="py-4">
            <thead>
                <tr>
                    <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>
                    {columns.map((column, idx) => <th key={idx} className={classNames(
                        "py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top",
                        column.type === "compute" ? "italic" : ""
                    )} colSpan={2}>
                        {column.title}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {scrape.records.map(({ val, confidence, fields_validations }, row_idx) => <tr key={row_idx}>
                    <td className="py-1 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {columns.map((column, col_idx) => <Fragment key={col_idx}>
                        {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2) : "N/A"}`}
                            className={classNames("w-1 border-l border-t border-b cursor-pointer",
                                confidence && confidence[column.field_name] ?
                                    (confidence[column.field_name] < 0.5 ? "bg-red-100" :
                                        confidence[column.field_name] < 0.8 ? "bg-yellow-100" :
                                            "bg-gray-100") :
                                    "bg-gray-50",
                            )}></td>}
                        {column.field_name === undefined && <td className="w-1 border-l border-t border-b bg-gray-50"></td>}

                        {column.field_name !== undefined && <td
                            className={classNames(
                                "border-t border-b border-r py-1 px-2 min-w-[100px] max-w-[200px] overflow-hidden hover:bg-sea_blue-100 text-left text-sm align-top cursor-text",
                                (show_validation_results && fields_validations && fields_validations[column.field_name]) ?
                                    (fields_validations[column.field_name].every(v => v.status === "pass") ? "text-green-600" : "text-red-600") :
                                    ""
                            )}
                        >
                            <FieldValueRender value={val[column.field_name] ?? ""} />
                        </td>}

                        {column.field_name === undefined && <td className="border-t border-b border-r py-1 px-2 min-w-[100px] max-w-[200px] hover:bg-sea_blue-100 text-left text-sm align-top cursor-text"></td>}
                    </Fragment>)}
                </tr>)}
            </tbody>
        </table>
    </div>
}

function HierarchicalRecordFieldQuote(props: { quote: string, input_lines: string[] }) {
    const { quote, input_lines } = props;

    const lines: number[] = [];
    if (/^L\d+$/.test(quote)) {
        // format "L123" return [123]
        lines.push(parseInt(quote.slice(1)));
    } else if (/^L\d+-L\d+$/.test(quote)) {
        // format "L123-L125" return [123, 124, 125]
        const [start, end] = quote.split("-").map(s => parseInt(s.slice(1)));
        lines.push(...Array.from({ length: end - start + 1 }, (_, i) => start + i));
    } else if (/^L\d+(, L\d+)*$/.test(quote)) {
        // format "L123, L125, ... L130" return [123, 125, ..., 130]
        lines.push(...quote.slice(1).split(", ").map(s => parseInt(s)));
    }

    // get identified lines from input lines
    const identified_lines = lines.map(l => input_lines[l] || "");
    // prepare tooltip (cannot use tailwind)
    const tooltip = `<p style="padding-left: 1rem; border-left-width: 2px; color: #111827; border-color: #4b5563">${identified_lines.join("\n<br/>")}</p>`;

    return lines.length > 0 ? <TbQuote
        className="ml-1 w-4 h-4 text-space_blue-500 cursor-context-menu"
        data-tooltip-id="quote-tooltip"
        data-tooltip-html={tooltip} /> :
        <Fragment />
}

type HierarchicalRecordProps = {
    val: any;
    input_documents: IScrapeDocument[];
    show_all: boolean;
}

export function HierarchicalRecord(props: HierarchicalRecordProps) {
    const { val, input_documents, show_all } = props;

    const input_lines = input_documents.flatMap(d => d.pages).flatMap(p => p.text.split("\n"));

    const has_reference_line_number = (val: any) => {
        return typeof val === "object" && val.hasOwnProperty("reference_line_number");
    }

    const is_empty = (val: any) => {
        if (show_all) { return false; }
        if (val === undefined || val === null) { return true; }
        if (val === "N/A") { return true; }
        if (typeof val === "string" && val.trim() === "") { return true; }
        if (Array.isArray(val) && val.length === 0) { return true; }
        if (typeof val === "object") {
            if (Object.keys(val).length === 0) { return true; }
            if (Object.values(val).every(v => is_empty(v))) { return true; }
        }
        return false;
    }

    if (Array.isArray(val)) {
        return <div className="pl-8">
            <table>
                <tbody>
                    {val.map((val, idx) => <tr key={idx} className="">
                        <td className="pt-3 font-bold text-sm align-top flex flex-row">
                            {idx + 1}
                            {has_reference_line_number(val) && <HierarchicalRecordFieldQuote quote={val.reference_line_number} input_lines={input_lines} />}
                        </td>
                        <td className="pt-2 pl-4 text-sm align-top">
                            <HierarchicalRecord val={val} input_documents={input_documents} show_all={show_all} />
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>;
    }

    if (typeof val === "object") {
        // split keys into simple key-value pairs and nested objects
        const simple_keys = Object.keys(val)
            .filter(k => typeof val[k] === "string" && k !== "reference_line_number")
            .filter(k => val[k] !== "N/A");
        const nested_keys = Object.keys(val)
            .filter(k => typeof val[k] !== "string")
            .filter(k => !is_empty(val[k]));

        return <div className="pl-8">
            {simple_keys.length > 0 && <table>
                <tbody>
                    {simple_keys.map((key, idx) => <tr key={idx} className="">
                        <td className="pt-2 font-bold text-sm  align-top">{key}</td>
                        <td className="pt-2 pl-4 text-sm  align-top">{val[key] === "N/A" ? "/" : val[key]}</td>
                    </tr>)}
                </tbody>
            </table>}
            {nested_keys.length > 0 && <ul className="">
                {nested_keys.map((key, idx) => <li key={idx} className="pt-1">
                    <div className="flex flex-row">
                        <span className="font-bold text-sm">{key}</span>
                        {has_reference_line_number(val[key]) && <HierarchicalRecordFieldQuote quote={val[key].reference_line_number} input_lines={input_lines} />}
                    </div>
                    <HierarchicalRecord val={val[key]} input_documents={input_documents} show_all={show_all} />
                </li>)}
            </ul>}
        </div>;
    }

    return <li className="pl-4">
        <p>{val}</p>
    </li>;
}

type ViewHierarchicalScrapeTableProps = {
    context: IContextBase;
    scrape: IScrapeBase;
    input_documents: IScrapeDocument[];
}

function ViewHierarchicalScrapeTable(props: ViewHierarchicalScrapeTableProps) {
    const { context, scrape, input_documents } = props;

    const { context_schema, array_placeholders } = getHierarchicalContextSchema(context.fields);
    const flat_record_val = scrape.records ? scrape.records[0].val : {};
    const { record } = getHierarchicalRecord(flat_record_val, context_schema, array_placeholders);

    return <div className="px-1 max-w-3xl">
        <HierarchicalRecord val={record} input_documents={input_documents} show_all={false} />
        <Tooltip
            id="quote-tooltip"
            place="bottom"
            style={TOOLTIP_STYLE}
            opacity={1} />
    </div>;
}

type ViewScrapeTableSimpleProps = {
    context: IContextBase;
    scrape: IScrapeBase;
    input_documents: IScrapeDocument[];
    show_all_fields: boolean;
    confirmation_status?: string;
}

function ViewScrapeTableSimple(props: ViewScrapeTableSimpleProps) {
    const { context, scrape, input_documents, show_all_fields, confirmation_status } = props;

    const is_array = context.type === CONTEXT_TYPES.array;
    const is_object = context.type === CONTEXT_TYPES.object;
    const is_hierarchical = context.type === CONTEXT_TYPES.hierarchical;
    const is_lookup_table = context.type === CONTEXT_TYPES.lookup_table;

    return <Fragment>
        {is_object && <ViewObjectScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}
        {is_array && <ViewArrayScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}
        {is_hierarchical && <ViewHierarchicalScrapeTable
            context={context}
            scrape={scrape}
            input_documents={input_documents} />}
        {is_lookup_table && <ViewArrayScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}
    </Fragment>
}

type ViewScrapeTableProps = {
    context_idx: number;
    context?: IContextBase;
    scrape: IScrapeBase;
    input_documents: IScrapeDocument[];
    confirmation_status?: string;
    confirmation_log?: IExtractConfirmationLog[];
}

export function ViewScrapeTable(props: ViewScrapeTableProps) {
    const { context_idx, context, scrape, input_documents, confirmation_status, confirmation_log } = props;
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [show_all_fields, setShowAllFields] = useState(false);
    const [show_history, setShowHistory] = useState(false);
    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);

    if (!context) {
        return null;
    }

    const is_array = context.type === CONTEXT_TYPES.array;
    const is_object = context.type === CONTEXT_TYPES.object;
    const is_lookup_table = context.type === CONTEXT_TYPES.lookup_table;
    // check if at least one field is either hidden or has different title on confirmation screen
    const is_view_confirmed_diff = context.fields.some(f => f.skip_on_confirm === true || f.confirm_name !== undefined);

    // prepare history log scrapes if we have any
    const is_history = confirmation_log !== undefined && confirmation_log.length > 0;
    // order from oldest to newest
    const history_scrapes: IScrapeBase[] = [];
    if (is_history) {
        confirmation_log.sort((a, b) => a.created_at - b.created_at);
        history_scrapes.push({
            uuid: scrape.uuid,
            context_uuid: context.uuid,
            field_name_uuid_pairs: scrape.field_name_uuid_pairs,
            input_item_uuid: scrape.input_item_uuid,
            records: confirmation_log[0].old_records,
            validations: [],
            created_at: scrape.created_at,
            extraction_info: scrape.extraction_info,
            debug_log: scrape.debug_log
        });
        for (const log of confirmation_log) {
            history_scrapes.push({
                uuid: scrape.uuid,
                context_uuid: context.uuid,
                field_name_uuid_pairs: scrape.field_name_uuid_pairs,
                input_item_uuid: scrape.input_item_uuid,
                records: log.new_records,
                validations: [],
                created_at: log.created_at,
                extraction_info: scrape.extraction_info,
                debug_log: scrape.debug_log
            });
        }
    }

    return <Fragment>
        <ProcessFlowHead label={`Step ${context_idx + 1}`}>
            <div className="flex flex-row items-center max-w-4xl gap-2">
                <span className="text-sm font-semibold text-gray-900">{context.name}</span>
                {is_admin && scrape.debug_log.length > 0 && (
                    <BugAntIcon
                        className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-700"
                        onClick={() => setFullScreenText(JSON.stringify(scrape.debug_log, null, 2))}
                    />
                )}
                {(context?.extract_params.skip_on_confirm && !show_all_fields) && <p className="text-gray-400 text-xs font-normal">(details hidden)</p>}
                <div className="grow" />
                <div className="flex flex-row items-center px-6 gap-x-4">
                    {(is_view_confirmed_diff || context?.extract_params.skip_on_confirm) && <div className="flex flex-row items-center font-normal text-sm">
                        <Checkbox checked={show_all_fields} setChecked={setShowAllFields} id={`${context.uuid}show_all_fields`} />
                        <label htmlFor={`${context.uuid}show_all_fields`} className="ml-2">Show all</label>
                    </div>}
                    {is_history && <Button icon={BookOpenIcon} text="History" onClick={() => setShowHistory(true)} />}
                </div>
            </div>
        </ProcessFlowHead>

        <ProcessFlowBody>
            {(context?.extract_params.skip_on_confirm === false || show_all_fields) && <Fragment>
                {scrape.records.length === 0 && context?.extract_params.ok_to_be_empty === false && context?.type !== "classifier" &&
                    <div className="mb-4 mx-2 p-4 border bg-gray-100 rounded text-gray-900 text-sm">
                        <p className="pb-2">We were unable to extract any data for template <span className="font-semibold">{context.name}</span>.</p>
                        <p className="pb-1">Potential reasons for this:</p>
                        <ul className="list-disc list-inside">
                            <li className="pb-1/2">The input didn't contain any relevant information about the template.</li>
                            <li>Email had attachments in a format that our current extraction algorithms cannot process.</li>
                        </ul>
                    </div>}

                <ViewScrapeTableSimple
                    context={context}
                    scrape={scrape}
                    input_documents={input_documents}
                    show_all_fields={show_all_fields}
                    confirmation_status={confirmation_status}
                />
            </Fragment>}

            <FullScreen show={show_history} onClose={() => setShowHistory(false)}>
                <div className="p-4">
                    <div className="font-bold text-lg leading-6">
                        History of changes
                    </div>
                    {history_scrapes.map((scrape, idx) => <div key={idx} className="mb-4">
                        <div className="pt-8 pb-4">
                            <span className="text-gray-600 text-sm">{prettyDateTime(scrape.created_at)}</span>
                            <span className="ml-4 font-semibold text-gray-600 text-sm">
                                {idx === 0 ? "(Extracted)" : (idx === history_scrapes.length - 1 ? "(Current)" : "")}
                            </span>
                        </div>
                        {is_object && <ViewObjectScrapeTable
                            context={context}
                            scrape={scrape}
                            show_validation_results={true}
                            show_all_fields={show_all_fields} />}
                        {is_array && <ViewArrayScrapeTable
                            context={context}
                            scrape={scrape}
                            show_validation_results={true}
                            show_all_fields={show_all_fields} />}
                        {is_lookup_table && <ViewArrayScrapeTable
                            context={context}
                            scrape={scrape}
                            show_validation_results={true}
                            show_all_fields={show_all_fields} />}
                    </div>)}
                </div>
            </FullScreen>
        </ProcessFlowBody>
        <FullScreenText
            text={full_screen_text || ""}
            show={full_screen_text !== undefined}
            onClose={() => setFullScreenText(undefined)}
        />
    </Fragment>;
}

type ViewItemTablesProps = {
    item: IItem;
};

export function ViewItemTables(props: ViewItemTablesProps) {
    const { item } = props;

    // put scrapes and contexts together in pairs
    const scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = item.template.contexts.find(c => c.uuid === scrape.context_uuid);
        if (context !== undefined) {
            scrapes.push({ scrape, context });
        }
    }

    // order scrapes so we first have any single-row scrapes, then any multi-row scrapes
    scrapes.sort((a, b) => a.context.weight_score - b.context.weight_score);

    return <div className="pl-6">
        {scrapes.map((scrape, i) => <ViewScrapeTable key={i}
            context_idx={i}
            context={scrape.context} scrape={scrape.scrape}
            input_documents={item.documents}
            confirmation_status={item.extract_confirmations_status}
            confirmation_log={item.extract_confirmation_log !== undefined ?
                item.extract_confirmation_log.filter(ecl => ecl.scrape_uuid === scrape.scrape.uuid) : undefined}
        />)}
        <ProcessFlowHead label="Done" />
    </div >;
}

type DemoTableProps = {
    fields: IContextField[];
    records: IRecord[];
}

export function DemoTable(props: DemoTableProps) {
    const { fields, records } = props;

    return <div>
        <div className="px-1 overflow-x-auto"><table className="py-4">
            <thead>
                <tr>
                    <th className="w-6 bg-gray-100 border border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className="py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {records.map(({ val: record }, row_idx) => <tr key={row_idx}>
                    <td className="py-1 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {fields.map((field, col_idx) => <td
                        key={col_idx}
                        className="py-1 px-2 min-w-[100px] max-w-[200px] border bg-white text-left text-sm align-top cursor-text">
                        {record[field.name]}
                    </td>)}
                </tr>)}
            </tbody>
        </table>
        </div>
    </div>;
}

type RenderFieldVals = {
    field_type: IContextFieldType,
    field_name: string,
    field_val: IPrimitiveType,
    field_validations: IValidationResult[],
};

type ExampleObjectScrapeTableProps = {
    fields: IContextField[];
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
    show_full_tables: boolean;
}

function ExampleObjectScrapeTable(props: ExampleObjectScrapeTableProps) {
    const { fields, records, field_name_uuid_pairs, show_full_tables } = props;

    // prepare field uuid => name map
    const field_uuid_name_map: { [key: string]: string } = {};
    field_name_uuid_pairs.forEach(p => field_uuid_name_map[p.uuid] = p.name);

    const record_val: IRecordRaw = records[0]?.val || {}
    const record_fields_validations: IValidations = records[0]?.fields_validations || {}

    // prepare field type => name => value map
    const field_vals: RenderFieldVals[] = [];
    for (const field of fields) {
        field_vals.push({
            field_type: field.type,
            field_name: field.name,
            field_val: record_val[field_uuid_name_map[field.uuid]] ?? record_val[field.name] ?? "",
            field_validations: record_fields_validations[field_uuid_name_map[field.uuid]] ?? record_fields_validations[field.name] ?? []
        });
    }

    return <div className={classNames("max-w-4xl pr-6 outer-div  border-gray-200", show_full_tables ? "" : "max-h-[500px]")}>
        <table className="border-l border-t">
            <tbody>
                {field_vals.map(({ field_type, field_name, field_val, field_validations }, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border-r border-b  border-gray-200 text-left text-sm font-semibold align-top w-[250px]",
                        field_type === "compute" ? "italic" : ""
                    )}>
                        {field_name}
                    </td>
                    <td className={classNames(
                        "border-b border-r py-1 px-2 min-w-[200px] max-w-[350px] overflow-hidden text-left text-sm align-top",
                        field_validations.length > 0 ? (field_validations.every(v => v.status === "pass") ? "text-green-600" : "text-red-600") : "",
                        field_validations.some(v => v.status === "fail") ? "bg-red-50" : "bg-white"
                    )}>
                        <FieldValueRender value={field_val} />
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleArrayScrapeTableProps = {
    fields: IContextField[];
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
    show_full_tables: boolean;
}

function ExampleArrayScrapeTable(props: ExampleArrayScrapeTableProps) {
    const { fields, records, field_name_uuid_pairs, show_full_tables } = props;

    // prepare field uuid => name map
    const field_uuid_name_map: { [key: string]: string } = {};
    field_name_uuid_pairs.forEach(p => field_uuid_name_map[p.uuid] = p.name);

    // prepare field type => name => value map
    const records_field_vals: RenderFieldVals[][] = [];
    for (const record of records) {
        const record_field_vals: RenderFieldVals[] = [];
        for (const field of fields) {
            record_field_vals.push({
                field_type: field.type,
                field_name: field.name,
                field_val: record.val[field_uuid_name_map[field.uuid]] ?? record.val[field.name] ?? "",
                field_validations: record.fields_validations?.[field_uuid_name_map[field.uuid]] ?? record.fields_validations?.[field.name] ?? []
            });
        }
        records_field_vals.push(record_field_vals);
    }

    return <div className={classNames("outer-div border-l border-t border-gray-200", show_full_tables ? "" : "max-h-[500px]")}>
        <table className="min-w-full">
            <thead>
                <tr>
                    <th className="sticky left-0 top-0 z-11 min-w-[20px] border-r border-b bg-gray-100 border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className={classNames(
                        "sticky top-0 z-10 py-1 px-2 min-w-[100px] bg-gray-100 border-r border-b border-gray-200 text-left text-sm font-semibold align-top",
                        field.type === "compute" ? "italic" : ""
                    )}>
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {records_field_vals.map((record_field_vals, row_idx) => <tr key={row_idx}>
                    <td className="sticky left-0 z-9 py-1 px-2 border-r border-b bg-gray-100 border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {record_field_vals.map(({ field_val, field_validations }, col_idx) => <td key={col_idx} className={classNames(
                        "py-1 px-2 min-w-[100px] border-r border-b text-left text-sm align-top cursor-text",
                        field_validations.length > 0 ? (field_validations.every(v => v.status === "pass") ? "text-green-600" : "text-red-600") : "",
                        field_validations.some(v => v.status === "fail") ? "bg-red-50" : "bg-white"
                    )}>
                        <FieldValueRender value={field_val} />
                    </td>)}
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleScrapeTableProps = {
    fields: IContextField[];
    context_type: ContextType;
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
    show_full_tables: boolean;
}

export function ExampleScrapeTable(props: ExampleScrapeTableProps) {
    const { fields, context_type, records, field_name_uuid_pairs, show_full_tables } = props;

    return <div className="px-1 py-4">
        {["object"].includes(context_type) && <ExampleObjectScrapeTable fields={fields} records={records} field_name_uuid_pairs={field_name_uuid_pairs} show_full_tables={show_full_tables} />}
        {["array", "lookup_table"].includes(context_type) && <ExampleArrayScrapeTable fields={fields} records={records} field_name_uuid_pairs={field_name_uuid_pairs} show_full_tables={show_full_tables} />}
    </div >;
}

type ExampleItemTablesProps = {
    template: ITemplate;
    item: IItemBase;
    show_full_tables: boolean;
};

export function ExampleItemTables(props: ExampleItemTablesProps) {
    const { template, item, show_full_tables } = props;
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);

    const scrapes: {
        scrape: IScrapeBase,
        context_name: string,
        context_type: ContextType,
        context_weight_score: number,
        fields: IContextField[]
    }[] = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = template.contexts.find(c => c.uuid === scrape.context_uuid);
        if (context !== undefined && !context.extract_params.skip_on_confirm) {
            scrapes.push({
                scrape,
                context_name: context.name,
                context_type: context.type,
                context_weight_score: context.weight_score,
                fields: context.fields.filter(f => f.skip_on_confirm !== true)
            });
        }
    }
    scrapes.sort((a, b) => a.context_weight_score - b.context_weight_score);

    return <Fragment>
        {scrapes.map(({ scrape, context_name, context_type, fields }, i) => <div className="pt-4" key={i}>
            {context_name.length > 0 && <div className="px-2 font-medium text-sm flex items-center gap-2">
                {context_name}
                {is_admin && scrape.debug_log.length > 0 && (
                    <BugAntIcon
                        className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-700"
                        onClick={() => setFullScreenText(JSON.stringify(scrape.debug_log, null, 2))}
                    />
                )}
            </div>}
            <ExampleScrapeTable key={i}
                fields={fields}
                context_type={context_type}
                records={scrape.records}
                field_name_uuid_pairs={scrape.field_name_uuid_pairs}
                show_full_tables={show_full_tables}
            />
        </div>)}
        <FullScreenText
            text={full_screen_text || ""}
            show={full_screen_text !== undefined}
            onClose={() => setFullScreenText(undefined)}
        />
    </Fragment >;
}

type ExampleCellDiffProps = {
    field: IContextField;
    diff: IScrapeEvalDiff;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
}

function ExampleCellDiff(props: ExampleCellDiffProps) {
    const { field, diff, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs } = props;

    // prepare field uuid => name map
    const old_field_name: string = old_field_name_uuid_pairs.find(p => p.uuid === field.uuid)?.name ?? field.name;
    const new_field_name: string = new_field_name_uuid_pairs.find(p => p.uuid === field.uuid)?.name ?? field.name;

    // first try to find the field by the uuid, then by the name
    const field_diff = diff.diff?.find(d => d.prop === new_field_name) ?? diff.diff?.find(d => d.prop === field.name);

    if (diff.type === "extra") {
        const record = new_records[diff.idx_new].val;
        const val = record[new_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-mint-100 text-left text-sm align-top cursor-text">
            <FieldValueRender value={val} />
        </td>;
    }
    if (diff.type === "missing") {
        const record = old_records[diff.idx_old].val;
        const val = record[old_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-red-100 line-through text-left text-sm align-top cursor-text">
            <FieldValueRender value={val} />
        </td>;
    }
    if (diff.type === "exact") {
        const record = new_records[diff.idx_new].val;
        const val = record[new_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-white text-left text-sm align-top cursor-text">
            <FieldValueRender value={val} />
        </td>;
    }
    if (diff.type === "approx") {
        const old_record = old_records[diff.idx_old].val;
        const old_val = old_record[old_field_name] ?? old_record[field.name];
        const new_record = new_records[diff.idx_new].val;
        const new_val = new_record[new_field_name] ?? new_record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-white text-left text-sm align-top cursor-text">
            {field_diff !== undefined ?
                <Fragment>
                    {new_val !== undefined && <span className="bg-mint-100">
                        <FieldValueRender value={new_val} />
                    </span>}
                    {old_val !== undefined && new_val !== undefined && <span>&nbsp;/&nbsp;</span>}
                    {old_val !== undefined && <span className="bg-red-100 line-through">
                        <FieldValueRender value={old_val} />
                    </span>}
                </Fragment> :
                <FieldValueRender value={new_record[field.name] ?? ""} />}
        </td>;
    }

    return null;
}

type ExampleDiffObjectScrapeTableProps = {
    fields: IContextField[];
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
    show_full_tables: boolean;
}

export function ExampleDiffObjectScrapeTable(props: ExampleDiffObjectScrapeTableProps) {
    const { fields, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs, show_full_tables } = props;

    return <div className={classNames("max-w-4xl pr-6 outer-div  border-gray-200", show_full_tables ? "" : "max-h-[500px]")}>
        <table className="border-l border-t">
            <tbody>
                {fields.map((field, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border-r border-b  border-gray-200 text-left text-sm font-semibold align-top w-[250px]",
                        field.type === "compute" ? "italic" : ""
                    )}>
                        {field.name}
                    </td>
                    {diffs.map((diff, idx) => <ExampleCellDiff
                        key={idx}
                        field={field}
                        diff={diff}
                        old_records={old_records}
                        old_field_name_uuid_pairs={old_field_name_uuid_pairs}
                        new_records={new_records}
                        new_field_name_uuid_pairs={new_field_name_uuid_pairs} />)}
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleRowDiffProps = {
    fields: IContextField[];
    diff: IScrapeEvalDiff;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
}

function ExampleRowDiff(props: ExampleRowDiffProps) {
    const { fields, diff, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs } = props;

    // prepare field uuid => name map
    const old_field_uuid_name_map: { [key: string]: string } = {};
    old_field_name_uuid_pairs.forEach(p => old_field_uuid_name_map[p.uuid] = p.name);
    const new_field_uuid_name_map: { [key: string]: string } = {};
    new_field_name_uuid_pairs.forEach(p => new_field_uuid_name_map[p.uuid] = p.name);

    if (diff.type === "extra") {
        const record = new_records[diff.idx_new].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-mint-100 text-left text-sm align-top cursor-text">
                    <FieldValueRender value={record[new_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? ""} />
                </td>)}
        </Fragment>;
    }
    if (diff.type === "missing") {
        const record = old_records[diff.idx_old].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-red-100 line-through text-left text-sm align-top cursor-text">
                    <FieldValueRender value={record[old_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? ""} />
                </td>)}
        </Fragment>;
    }
    if (diff.type === "exact") {
        const record = new_records[diff.idx_new].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-white text-left text-sm align-top cursor-text">
                    {record[new_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? ""}
                </td>)}
        </Fragment>;
    }
    if (diff.type === "approx") {
        const old_record = old_records[diff.idx_old].val;
        const new_record = new_records[diff.idx_new].val;
        const diff_fields = diff.diff ? diff.diff.map(d => d.prop) : [];
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] border-r border-b bg-white text-left text-sm align-top cursor-text">
                    {diff_fields.includes(new_field_uuid_name_map[field.uuid] ?? field.name) ?
                        <Fragment>
                            {(new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name]) !== undefined &&
                                <span className="bg-mint-100">
                                    <FieldValueRender value={new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name]} />
                                </span>}
                            {(new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name]) !== undefined &&
                                (old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name]) !== undefined && <span>&nbsp;/&nbsp;</span>}
                            {(old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name]) !== undefined &&
                                <span className="bg-red-100 line-through">
                                    <FieldValueRender value={old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name]} />
                                </span>}
                        </Fragment> :
                        (new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name] ?? "")}
                </td>)}
        </Fragment>;
    }

    return null;
}

type ExampleDiffArrayScrapeTableProps = {
    fields: IContextField[];
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
    show_full_tables: boolean;
}

export function ExampleDiffArrayScrapeTable(props: ExampleDiffArrayScrapeTableProps) {
    const { fields, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs, show_full_tables } = props;

    return <div className={classNames("outer-div border-l border-t border-gray-200", show_full_tables ? "" : "max-h-[500px]")}>
        <table className="min-w-full">
            <thead>
                <tr>
                    <th className="sticky left-0 top-0 z-11 min-w-[20px] bg-gray-100 border border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className="sticky top-0 z-10 py-1 px-2 min-w-[100px] bg-gray-100 border-r border-b border-gray-200 text-left text-sm font-semibold align-top">
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {diffs.map((diff, row_idx) => <tr key={row_idx}>
                    <td className="sticky left-0 z-9 py-1 px-2 border-r border-b bg-gray-100 border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    <ExampleRowDiff
                        fields={fields}
                        diff={diff}
                        old_records={old_records}
                        old_field_name_uuid_pairs={old_field_name_uuid_pairs}
                        new_records={new_records}
                        new_field_name_uuid_pairs={new_field_name_uuid_pairs} />
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleDiffScrapeTableProps = {
    fields: IContextField[];
    context_type: ContextType;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[];
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
    show_full_tables: boolean;
}

export function ExampleDiffScrapeTable(props: ExampleDiffScrapeTableProps) {
    const { fields, context_type, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs, show_full_tables } = props;

    const is_object = context_type === CONTEXT_TYPES.object && (0 <= diffs.length && diffs.length <= 2);
    const is_array = (context_type === CONTEXT_TYPES.object && diffs.length > 2) || ["array", "lookup_table"].includes(context_type);

    return <div className="px-1 py-4">
        {is_object && <ExampleDiffObjectScrapeTable
            fields={fields}
            old_records={old_records}
            old_field_name_uuid_pairs={old_field_name_uuid_pairs}
            new_records={new_records}
            new_field_name_uuid_pairs={new_field_name_uuid_pairs}
            diffs={diffs}
            show_full_tables={show_full_tables} />}
        {is_array && <ExampleDiffArrayScrapeTable
            fields={fields}
            old_records={old_records}
            old_field_name_uuid_pairs={old_field_name_uuid_pairs}
            new_records={new_records}
            new_field_name_uuid_pairs={new_field_name_uuid_pairs}
            diffs={diffs}
            show_full_tables={show_full_tables} />}
    </div>;
}

type ExampleDiffTablesProps = {
    contexts: (IContextNoUUID & { uuid: string })[];
    item: IItemBase;
    scrapes_eval_metrics?: IScrapeEvalMetrics[];
    hidden_contexts: string[];
    hidden_fields: string[];
    show_diff: boolean;
    show_full_tables: boolean;
}

export function ExampleDiffTables(props: ExampleDiffTablesProps) {
    const { contexts, item, scrapes_eval_metrics, hidden_contexts, hidden_fields, show_diff, show_full_tables } = props;
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [scrape_debug_log, setScrapeDebugLog] = useState<IScrapeDebugLog[] | undefined>(undefined);

    const sheets: {
        debug_log: IScrapeDebugLog[],
        context_name: string,
        context_type: ContextType,
        fields: IContextField[],
        old_records: IRecord[],
        old_field_name_uuid_pairs: IFieldNameUuidPair[],
        new_records?: IRecord[],
        new_field_name_uuid_pairs?: IFieldNameUuidPair[],
        diffs?: IScrapeEvalDiff[]
    }[] = [];

    for (const context of contexts) {
        if (hidden_contexts.includes(context.uuid)) { continue; }
        const visible_fields = context.fields.filter(f => !hidden_fields.includes(f.uuid));
        if (visible_fields.length === 0) { continue; }
        const old_scrape = item.scrapes.find(s => s.context_uuid === context.uuid);
        const scrape_eval_metrics = scrapes_eval_metrics?.find(s => s.new_scrape.context_uuid === context.uuid);
        if (scrape_eval_metrics === undefined) {
            sheets.push({
                debug_log: old_scrape?.debug_log ?? [],
                context_name: context.name,
                context_type: context.type,
                fields: visible_fields,
                old_records: old_scrape?.records ?? [],
                old_field_name_uuid_pairs: old_scrape?.field_name_uuid_pairs ?? []
            });
        } else {
            sheets.push({
                debug_log: scrape_eval_metrics.new_scrape.debug_log,
                context_name: context.name,
                context_type: context.type,
                fields: visible_fields,
                old_records: old_scrape?.records ?? [],
                old_field_name_uuid_pairs: old_scrape?.field_name_uuid_pairs ?? [],
                new_records: scrape_eval_metrics.new_scrape.records,
                new_field_name_uuid_pairs: scrape_eval_metrics.new_scrape.field_name_uuid_pairs,
                diffs: scrape_eval_metrics.diffs
            });
        }
    }

    return <Fragment>
        {sheets.map((sheet, i) => (<div key={i}>
            <div className="px-2 font-medium text-sm flex items-center gap-2">
                {sheet.context_name}
                {is_admin && sheet.debug_log.length > 0 && (
                    <BugAntIcon
                        className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-700"
                        onClick={() => setScrapeDebugLog(sheet.debug_log)}
                    />
                )}
            </div>
            {sheet.new_records !== undefined && sheet.diffs !== undefined && show_diff && <ExampleDiffScrapeTable
                fields={sheet.fields}
                context_type={sheet.context_type}
                old_records={sheet.old_records}
                old_field_name_uuid_pairs={sheet.old_field_name_uuid_pairs}
                new_records={sheet.new_records ?? []}
                new_field_name_uuid_pairs={sheet.new_field_name_uuid_pairs ?? []}
                diffs={sheet.diffs ?? []}
                show_full_tables={show_full_tables}
            />}
            {sheet.new_records !== undefined && sheet.diffs !== undefined && !show_diff && <ExampleScrapeTable
                fields={sheet.fields}
                context_type={sheet.context_type}
                records={sheet.new_records}
                field_name_uuid_pairs={sheet.new_field_name_uuid_pairs ?? []}
                show_full_tables={show_full_tables}
            />}
            {(sheet.new_records === undefined || sheet.diffs === undefined) && <ExampleScrapeTable
                fields={sheet.fields}
                context_type={sheet.context_type}
                records={sheet.old_records}
                field_name_uuid_pairs={sheet.old_field_name_uuid_pairs}
                show_full_tables={show_full_tables}
            />}

            <FullScreen
                show={scrape_debug_log !== undefined}
                onClose={() => setScrapeDebugLog(undefined)}
            >
                <div className="p-4 text-sm font-medium">Debug log</div>
                <table className="w-full border border-gray-200">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="text-xs text-left font-light px-2 py-1">Rec</th>
                            <th className="text-xs text-left font-light px-2 py-1">Field</th>
                            <th className="text-xs text-left font-light px-2 py-1">Type</th>
                            <th className="text-xs text-left font-light px-2 py-1">Message</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {scrape_debug_log?.map((log, idx) => (
                            <tr key={idx}>
                                <td className="text-xs font-mono px-2 py-1">{log.record_idx}</td>
                                <td className="text-xs font-mono font-light px-2 py-1">{log.field_name ?? "-"}</td>
                                <td className="text-xs font-mono font-light px-2 py-1">{log.type}</td>
                                <td className="text-xs font-mono font-light px-2 py-1">{log.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </FullScreen>
        </div>)
        )}
    </Fragment>;
}
