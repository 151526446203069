import { CodeBox } from "../components/CodeBox";

export function HandlebarsDoc() {
    return <div>
        <div className="w-full flex flex-col items-start pt-10">
            <p className="mx-4 pb-4 ">
                Simple example. Check the <a href="https://handlebarsjs.com/guide/" target="_blank" rel="noreferrer" className="text-space_blue-600">Handlebars documentation</a> for more information.
            </p>
            <CodeBox language="Handlebars" code={`<?xml version="1.0" encoding="utf-8"?>
<Forecasts>
  <Forecast>
    <Head>
      <Client>{{ [Demand Forecast - Head].Client }}</Client>
      <Date>{{ [Demand Forecast - Head].Date }}</Date>
    </Head>
    <Orders>
      {{#[Demand Forecast]}}
      <Order>
        <Material>{{ SKU }}</Material>
        <Quantity>{{ Quantity }}</Quantity>
        <Date>{{ Date }}</Date>
      </Order>
      {{/[Demand Forecast]}}
    </Orders>
  </Forecast>
</Forecasts>`}/>
            <p className="mx-4 pb-4 ">
                Simple example data JSON:
            </p>
            <CodeBox language="JSON" code={`{
  "Demand Forecast - Head": { "Client": "Client1", "Date": "2021-01-01" },
  "Demand Forecast": [
    { "SKU": "SKU1", "Quantity": 1, "Date": "2021-01-01" },
    { "SKU": "SKU1", "Quantity": 2, "Date": "2021-01-02" },
    { "SKU": "SKU2", "Quantity": 3, "Date": "2021-01-03" }
  ]
}`} />
            <p className="mx-4 pb-4 ">
                Simple example rendered xml:
            </p>
            <CodeBox language="xml" code={`<?xml version="1.0" encoding="utf-8"?>
<Forecasts>
  <Forecast>
    <Head>
      <Client>Client1</Client>
      <Date>2021-01-01</Date>
    </Head>
    <Orders>
      <Order>
        <Material>SKU1</Material>
        <Quantity>1</Quantity>
        <Date>2021-01-01</Date>
      </Order>
      <Order>
        <Material>SKU1</Material>
        <Quantity>2</Quantity>
        <Date>2021-01-02</Date>
      </Order>
      <Order>
        <Material>SKU2</Material>
        <Quantity>3</Quantity>
        <Date>2021-01-03</Date>
      </Order>
    </Orders>
  </Forecast>
</Forecasts>`} />
        </div>

        <div className="w-full flex flex-col items-start pt-10">
            <p className="mx-4 pb-4 ">
                Example using groupBy.
            </p>
            <CodeBox language="Handlebars" code={`<?xml version="1.0" encoding="utf-8"?>
<Forecasts>
  {{#each (groupBy Forecast "SKU") }}
  <MaterialSchedule>
    <Schedule>
      {{#values}}
      <QuantityDate>
        <Quantity>{{ Quantity }}</Quantity>
        <Date>{{ Date }}</Date>
      </QuantityDate>
      {{/values}}
    </Schedule>
    <Material>{{ SKU }}</Material>
  </MaterialSchedule>
  {{/each}}
</Forecasts>`}/>
            <p className="mx-4 pb-4 ">
                GroupBy example data JSON:
            </p>
            <CodeBox language="JSON" code={`{
  "Demand Forecast - Head": { "Client": "Client1", "Date": "2021-01-01" },
  "Demand Forecast": [
    { "SKU": "SKU1", "Quantity": 1, "Date": "2021-01-01" },
    { "SKU": "SKU1", "Quantity": 2, "Date": "2021-01-02" },
    { "SKU": "SKU2", "Quantity": 3, "Date": "2021-01-03" }
  ]
}`} />
            <p className="mx-4 pb-4 ">
                GroupBy example rendered xml:
            </p>
            <CodeBox language="xml" code={`<?xml version="1.0" encoding="utf-8"?>
<Forecasts>
  <MaterialSchedule>
    <Schedule>
      <QuantityDate>
        <Quantity>1</Quantity>
        <Date>2021-01-01</Date>
      </QuantityDate>
      <QuantityDate>
        <Quantity>2</Quantity>
        <Date>2021-01-02</Date>
      </QuantityDate>
    </Schedule>
    <Material>SKU1</Material>
  </MaterialSchedule>
  <MaterialSchedule>
    <Schedule>
      <QuantityDate>
        <Quantity>3</Quantity>
        <Date>2021-01-03</Date>
      </QuantityDate>
    </Schedule>
    <Material>SKU2</Material>
  </MaterialSchedule>
</Forecasts>`} />
        </div>

        <div className="w-full flex flex-col items-start pt-10">
            <p className="mx-4 pb-4 ">
                Example using groupByMany. Use • to separate multiple keys.
            </p>
            <CodeBox language="Handlebars" code={`<?xml version="1.0" encoding="utf-8"?>
<Forecasts>
  {{#each (groupByMany Forecast "SKU•Order") }}
  <MaterialOrderSchedule>
    <Schedule>
      {{#values}}
      <QuantityDate>
        <Quantity>{{ Quantity }}</Quantity>
        <Date>{{ Date }}</Date>
      </QuantityDate>
      {{/values}}
    </Schedule>
    <Material>{{ SKU }}</Material>
    <Order>{{ Order }}</Order>
  </MaterialOrderSchedule>
  {{/each}}
</Forecasts>`}/>
            <p className="mx-4 pb-4 ">
                GroupByMany example data JSON:
            </p>
            <CodeBox language="JSON" code={`{
  "Forecast": [
    { "SKU": "SKU1", "Order": "123", "Quantity": 1, "Date": "2021-01-01" },
    { "SKU": "SKU1", "Order": "123", "Quantity": 2, "Date": "2021-01-02" },
    { "SKU": "SKU1", "Order": "345", "Quantity": 3, "Date": "2021-01-03" },
    { "SKU": "SKU2", "Order": "123", "Quantity": 4, "Date": "2021-01-04" }
  ]
}`} />
            <p className="mx-4 pb-4 ">
                GroupByMany example rendered xml:
            </p>
            <CodeBox language="xml" code={`<?xml version="1.0" encoding="utf-8"?>
<Forecasts>
  <MaterialOrderSchedule>
    <Schedule>
      <QuantityDate>
        <Quantity>1</Quantity>
        <Date>2021-01-01</Date>
      </QuantityDate>
      <QuantityDate>
        <Quantity>2</Quantity>
        <Date>2021-01-02</Date>
      </QuantityDate>
    </Schedule>
    <Material>SKU1</Material>
    <Order>123</Order>
  </MaterialOrderSchedule>
  <MaterialOrderSchedule>
    <Schedule>
      <QuantityDate>
        <Quantity>3</Quantity>
        <Date>2021-01-03</Date>
      </QuantityDate>
    </Schedule>
    <Material>SKU1</Material>
    <Order>345</Order>
  </MaterialOrderSchedule>
  <MaterialOrderSchedule>
    <Schedule>
      <QuantityDate>
        <Quantity>4</Quantity>
        <Date>2021-01-04</Date>
      </QuantityDate>
    </Schedule>
    <Material>SKU2</Material>
    <Order>123</Order>
  </MaterialOrderSchedule>
</Forecasts>`} />
        </div>
    </div>;
}
