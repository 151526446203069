import {
    Fragment,
    useEffect,
    useState
} from "react";

import { SIMPLE_MIME_TYPES } from "../lib/consts";
import {
    IItemAttachmentBase,
    IItemBase
} from "../lib/types";
import { flattenScrapeDocuments } from "../lib/utils";

import { ExcelViewer } from "./ExcelViewer";
import { LongText } from "./LongText";
import { PdfViewerVertical } from "./PdfViewer";
import { CompactTabs, ITab } from "./Tabs";

function EmailContent(props: { item: IItemBase }) {
    const { item } = props;
    const is_html = item.details.email_html !== undefined;
    return <div className="text-sm p-5 mt-5 border border-gray-200 rounded shadow bg-gray-50">
        <div className="pb-2"><span className="font-bold">From:</span> {item.details.from_email_address}</div>
        {item.details.to_email_addresses && <div className="pb-2 truncate"><span className="font-bold">To:</span> {item.details.to_email_addresses?.join(", ")}</div>}
        {item.details.email_subject && <div className="pb-2"><span className="font-bold">Subject:</span> {item.details.email_subject}</div>}
        {/* render html from item.details.email_html in iframe */}
        {is_html && <iframe
            title="email"
            srcDoc={item.details.email_html ?? ""}
            style={{ width: "100%", height: "400px" }}
            className="border bg-white mt-2"
            sandbox=""
        />}
        {/* render text from item.text */}
        {!is_html && <LongText text={flattenScrapeDocuments(item.documents)} />}
    </div>
}

function AttachmentContent(props: { attachment: IItemAttachmentBase }) {
    const { attachment } = props;

    const url = `/api/attachment/get?uuid=${attachment.uuid}`;
    const link = <a
        className="text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
        href={url} target="_blank" rel="noreferrer">
        [download]
    </a>

    const lc_filename = attachment.filename.toLowerCase();
    if (
        attachment.mimetype.startsWith("image/") ||
        lc_filename.endsWith(".jpg") ||
        lc_filename.endsWith(".jpeg") ||
        lc_filename.endsWith(".png") ||
        lc_filename.endsWith(".tiff") ||
        lc_filename.endsWith(".bmp") ||
        lc_filename.endsWith(".gif")
    ) {
        return <div className="pt-3">
            <img src={url} alt={attachment.filename} />
            {link}
        </div>;
    }

    if (attachment.mimetype === "application/pdf" || lc_filename.endsWith(".pdf")) {
        return <div className="pt-3">
            <PdfViewerVertical file={url} />
            {link}
        </div>;
    }

    if (lc_filename.endsWith(".xlsx") || lc_filename.endsWith(".xls") || lc_filename.endsWith(".ods")) {
        return <div className="pt-3">
            <ExcelViewer attachment_uuid={attachment.uuid} />
            {link}
        </div>;
    }

    if (SIMPLE_MIME_TYPES.includes(attachment.mimetype)) {
        return <div className="pt-3">
            <div className="text-sm mb-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
                <LongText text={flattenScrapeDocuments([attachment.document])} />
            </div>
            {link}
        </div>;
    }

    return <div className="p-5">{link}</div>
}

function DocumentContent(props: { item: IItemBase }) {
    const { item } = props;

    return <div className="text-sm p-5 mt-5 border-gray-200 bg-gray-50 rounded shadow">
        {item.details.document_subject && <div className="pb-2 mb-2 border-b"><span className="font-bold">Subject:</span> {item.details.document_subject}</div>}
        <LongText text={flattenScrapeDocuments(item.documents)} />
    </div>;
}

export function ItemContent(props: { item: IItemBase, attachments: IItemAttachmentBase[] }) {
    const { item, attachments } = props;

    const [tabs, setTabs] = useState<ITab[]>([]);
    const [selected_tab, setSelectedTab] = useState<string>("");

    useEffect(() => {
        if (item === undefined) { return; }
        const tabs: ITab[] = [];
        if (item.details.input_type === "email") {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.input_type === "documents") {
            if (attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else if (item.details.from_email_address !== undefined) {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.document_subject !== undefined) {
            if (attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else if (item.details.document_filename !== undefined) {
            if (attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else {
            tabs.push({ name: "Text", key: "text" });
        }
        for (const attachment of attachments) {
            tabs.push({ name: attachment.filename, key: attachment.uuid });
        }
        setTabs(tabs);
        const first_content_tab = tabs.find(tab => tab.name.endsWith(".pdf")) || tabs[0];
        if (first_content_tab !== undefined) {
            setSelectedTab(first_content_tab.key);
        }
    }, [item, attachments]);

    return <Fragment>
        <CompactTabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
        {selected_tab === "email" && <EmailContent item={item} />}
        {selected_tab === "doc" && <DocumentContent item={item} />}
        {selected_tab === "text" && <div className="text-sm p-5 mt-5 border-gray-200 bg-gray-50 rounded shadow">
            <LongText text={flattenScrapeDocuments(item.documents)} />
        </div>}
        {attachments.map((attachment, idx) => {
            if (selected_tab === attachment.uuid) {
                return <AttachmentContent key={idx} attachment={attachment} />;
            }
            return null;
        })}
    </Fragment >;
}