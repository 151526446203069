import {
    useEffect,
    useState
} from "react";

import {
    IContextBase,
    IContextField,
    IEndpointOutputNameElement
} from "../lib/types";
import { DropdownMenu } from "./DropdownMenu";
import { MultiselectInputField } from "./MultiselectInputField";

type SplitField = {
    context_uuid: string;
    field: IContextField;
}

type EndpointOutputNameProps = {
    object_contexts: IContextBase[];
    template_split_fields: SplitField[];
    elements: IEndpointOutputNameElement[];
    addElement: (context_uuid: string, field_uuid: string) => void;
    removeElement: (element_idx: number) => void;
    title: string;
    separator?: "underline" | "space";
}

interface IOutputNameField {
    context_uuid: string;
    field_uuid: string;
    field_name: string;
}

export function EndpointOutputName(props: EndpointOutputNameProps) {
    const { object_contexts, template_split_fields, elements, addElement, removeElement, title, separator } = props;

    const [fields, setFields] = useState<IOutputNameField[]>([]);
    const [filename_fields, setFilenameFields] = useState<IOutputNameField[]>([]);

    useEffect(() => {
        // initialize fields
        const new_fields: IOutputNameField[] = object_contexts.flatMap(c => c.fields.map(f => ({
            context_uuid: c.uuid,
            field_uuid: f.uuid,
            field_name: f.name
        })));
        // we can also add fields from template_split_fields
        for (const split_field of template_split_fields) {
            const { context_uuid, field } = split_field;
            // check if field is already in new_fields
            if (new_fields.find(f => f.context_uuid === context_uuid && f.field_uuid === field.uuid)) {
                continue;
            }
            new_fields.push({
                context_uuid,
                field_uuid: field.uuid,
                field_name: field.name
            });
        }
        setFields(new_fields);
    }, [object_contexts, template_split_fields]);

    useEffect(() => {
        const new_filename_fields: (IOutputNameField | undefined)[] = elements.map(e => {
            return fields.find(f => f.context_uuid === e.context_uuid && f.field_uuid === e.field_uuid);
        });
        setFilenameFields(new_filename_fields.filter(f => f !== undefined) as IOutputNameField[]);
    }, [fields, elements]);

    return <div className="w-full flex flex-col">
        <div className="flex flex-row items-center">
            <label className="flex-grow text-sm font-medium text-gray-700">{title}</label>
            <DropdownMenu title="Add field" align="right" items={fields.map(f => ({
                title: f.field_name,
                onClick: () => { addElement(f.context_uuid, f.field_uuid) }
            }))} />
        </div>
        <div className="mt-3 w-full">
            <MultiselectInputField
                values={filename_fields.map(f => f.field_name)}
                placeholder="No fields selected"
                separator={separator || "underline"}
                onRemove={removeElement} />
        </div>
        <div className="mt-3">
        </div>
    </div>
}