import * as Recharts from "recharts"
import {
    IBarDateChartData,
    IBarTagChartData,
    IChart,
} from "../lib/backend/extractions.types.generated";

type StackedBarChartProps = {
    data: IBarDateChartData[];
    title: string;
}

function StackedBarChart(props: StackedBarChartProps) {
    const { data, title } = props;

    return (
        <div className="w-full h-full text-sm">
            <div className="pb-1 text-sm font-medium leading-6 text-gray-600 text-center">{title}</div>
            <Recharts.ResponsiveContainer width="100%" aspect={2}>
                <Recharts.BarChart data={data} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
                    <Recharts.XAxis dataKey="date" fontSize={12} stroke="#4b5563" />
                    <Recharts.YAxis fontSize={12} width={40} stroke="#4b5563" />
                    <Recharts.Tooltip contentStyle={{ color: "#4b5563", fontSize: "12px" }} />
                    <Recharts.Legend fontSize={12} />
                    <Recharts.Bar dataKey="pass" fill="#82ca9d" stackId="a" />
                    <Recharts.Bar dataKey="fail" fill="#ef4444" stackId="a" />
                </Recharts.BarChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type BarChartProps = {
    data: IBarDateChartData[];
    title: string;
}

function BarChart(props: BarChartProps) {
    const { data, title } = props;
    const tags = Object.keys(data[0]).filter(key => key.startsWith("tag "));
    const BAR_COLORS = ["#7dd3fc", "#38bdf8", "#0ea5e9", "#0284c7", "#0369a1", "#075985", "#0c4a6e"];

    return (
        <div className="w-full h-full text-sm">
            <div className="pb-1 text-sm font-medium leading-6 text-gray-600 text-center">{title}</div>
            <Recharts.ResponsiveContainer width="100%" aspect={2}>
                <Recharts.ComposedChart data={data} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
                    <Recharts.XAxis dataKey="date" fontSize={12} stroke="#4b5563" />
                    <Recharts.YAxis fontSize={12} width={40} stroke="#4b5563" />
                    <Recharts.Tooltip contentStyle={{ color: "#4b5563", fontSize: "12px" }} />
                    <Recharts.Legend fontSize={12} />
                    {tags.map((tag, index) => (
                        <Recharts.Bar
                            key={tag}
                            dataKey={tag}
                            name={tag.replace("tag ", "")}
                            fill={BAR_COLORS[index % BAR_COLORS.length]}
                        />
                    ))}
                </Recharts.ComposedChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type BarTagChartProps = {
    data: IBarTagChartData[];
    title: string;
}

function BarTagChart(props: BarTagChartProps) {
    const { data, title } = props;

    const clean_data = data.map((d) => ({
        tag: d.tag.replace("tag ", ""),
        count: d.count
    }));

    return (
        <div className="w-full h-full text-sm">
            <div className="pb-1 text-sm font-medium leading-6 text-gray-600 text-center">{title}</div>
            <Recharts.ResponsiveContainer width="100%" aspect={2}>
                <Recharts.BarChart
                    data={clean_data}
                    margin={{ top: 10, right: 20, left: 40, bottom: 0 }}
                    layout="vertical"
                >
                    <Recharts.YAxis
                        dataKey="tag"
                        type="category"
                        fontSize={12}
                        width={60}
                        stroke="#4b5563"
                    />
                    <Recharts.XAxis
                        type="number"
                        fontSize={12}
                        stroke="#4b5563"
                    />
                    <Recharts.Tooltip contentStyle={{ color: "#4b5563", fontSize: "12px" }} />
                    <Recharts.Bar
                        dataKey="count"
                        fill="#0ea5e9"
                        label={{
                            position: "right",
                            fontSize: 12,
                            fill: "#4b5563"
                        }}
                    />
                </Recharts.BarChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type SingleBarChartProps = {
    data: IBarDateChartData[];
    title: string;
}

function SingleBarChart(props: SingleBarChartProps) {
    const { data, title } = props;

    return (
        <div className="w-full h-full text-sm">
            <div className="pb-1 text-sm font-medium leading-6 text-gray-600 text-center">{title}</div>
            <Recharts.ResponsiveContainer width="100%" aspect={2}>
                <Recharts.ComposedChart data={data} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
                    <Recharts.XAxis dataKey="date" fontSize={12} stroke="#4b5563" />
                    <Recharts.YAxis fontSize={12} width={40} stroke="#4b5563" />
                    <Recharts.Tooltip contentStyle={{ color: "#4b5563", fontSize: "12px" }} />
                    <Recharts.Bar dataKey="count" fill="#0ea5e9" />
                </Recharts.ComposedChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type ChartProps = {
    title: string;
    chart: IChart;
}

export function Chart({ title, chart }: ChartProps) {
    if (chart.chart_type === "stacked_bar" && chart.data_type === "bar_date") {
        return <StackedBarChart data={chart.data} title={title} />;
    }
    if (chart.chart_type === "bar" && chart.data_type === "bar_date") {
        return <BarChart data={chart.data} title={title} />;
    }
    if (chart.chart_type === "single_bar" && chart.data_type === "bar_date") {
        return <SingleBarChart data={chart.data} title={title} />;
    }
    if (chart.chart_type === "bar_tag" && chart.data_type === "bar_tag") {
        return <BarTagChart data={chart.data} title={title} />;
    }
    return null;
}