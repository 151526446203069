import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../store";
import { scraperUser } from "../lib/scraper.slice";
import * as t from "../lib/types";
import * as c from "../lib/consts";
import {
    classNames,
    getNextOnboardingStep
} from "../lib/utils";
import { Backend } from "../lib/backend";

import { Button } from "../components/Button";
import { Textbox } from "../components/Textbox";

type UserDetailsProps = {
    user: t.IUser;
    is_onboarding: boolean;
    is_admin: boolean;
}

const priority_country_codes: string[] = ["us", "de"];

export function UserDetails(props: UserDetailsProps) {
    const dispatch = useDispatch<AppDispatch>();

    const { user, is_onboarding, is_admin } = props;

    const [company_name, setCompanyName] = useState<string>(user.details.company_name || "");
    const [company_sizes, setCompanySizes] = useState<string[]>([]);
    const [company_size, setCompanySize] = useState<string>(user.details.company_size || "");
    const [team_sizes, setTeamSizes] = useState<string[]>([]);
    const [team_size, setTeamSize] = useState<string>(user.details.team_size || "");
    const [industries, setIndustries] = useState<string[]>([]);
    const [industry, setIndustry] = useState<string>(user.details.industry || "");
    const [job_roles, setJobRoles] = useState<string[]>([]);
    const [job_role, setJobRole] = useState<string>(user.details.job_role || "");
    const [countries, setCountries] = useState<t.ICountry[]>([]);
    const [country_code, setCountryCode] = useState<string>(user.details.country_code || "");

    const [is_processing_continue, setIsProcessingContinue] = useState<boolean>(false);

    useEffect(() => {
        // load consts
        setCompanySizes(c.company_sizes);
        setTeamSizes(c.team_sizes);
        setIndustries(Object.keys(c.industry_and_roles));
        // prepare countries
        const priority_country_list: t.ICountry[] = [];
        for (const code of priority_country_codes) {
            const country = c.countries.find((c) => c.code === code);
            if (country) {
                priority_country_list.push(country);
            }
        }
        // compose country list
        setCountries([
            ...priority_country_list,
            { code: "", name: "----------------" },
            ...c.countries
        ]);
    }, []);

    useEffect(() => {
        const roles = c.industry_and_roles[industry];
        if (roles) {
            setJobRoles(roles);
        } else {
            setJobRoles([]);
        }
    }, [industry]);

    const onContinue = async () => {
        setIsProcessingContinue(true);

        await Backend.updateUserDetails({
            details: {
                company_name,
                company_size,
                team_size,
                industry,
                job_role,
                country_code
            }
        });

        if (is_onboarding) {
            await Backend.updateOnboardingStep({
                step: `${getNextOnboardingStep(c.ONBOARDING_STEPS.welcome)}`
            });
        }

        dispatch(scraperUser());

        setTimeout(() => { setIsProcessingContinue(false); }, 1000);
    };

    return <Fragment>
        <div className={classNames(is_admin ? "pg-8" : "border-b border-gray-900/10 pb-12")}>
            <div className={classNames("grid grid-cols-1 sm:grid-cols-6", is_admin ? "gap-x-2 gap-y-2" : "mt-4 gap-x-6 gap-y-6")}>
                <div className="col-span-full">
                    <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                        Company Name
                    </label>
                    <div className="mt-2">
                        <Textbox
                            placeholder="Enter your company name (optional)"
                            disabled={is_processing_continue || is_admin}
                            value={company_name}
                            onChange={(value) => { setCompanyName(value); }}
                        />
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="industry" className="block text-sm font-medium leading-6 text-gray-900">
                        Industry
                    </label>
                    <div className="mt-2">
                        <select
                            id="industry" name="industry" autoComplete="industry"
                            className={classNames(
                                "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6",
                                industry.length === 0 ? "text-gray-400" : "text-gray-900"
                            )}
                            disabled={is_processing_continue || is_admin}
                            value={industry}
                            onChange={(e) => { setIndustry(e.target.value); }}
                        >
                            <option value="">Which industry/sector does your company belong to?</option>
                            <option value="">----------------</option>
                            {industries.map((i) => {
                                return <option key={i} value={i}>{i}</option>;
                            })}
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="job-role" className="block text-sm font-medium leading-6 text-gray-900">
                        Job Role
                    </label>
                    <div className="mt-2">
                        <select
                            id="job-role" name="job-role" autoComplete="job-role"
                            className={classNames(
                                "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6",
                                job_role.length === 0 ? "text-gray-400" : "text-gray-900"
                            )}
                            disabled={job_roles.length === 0 || is_processing_continue || is_admin}
                            value={job_role}
                            onChange={(e) => { setJobRole(e.target.value); }}
                        >
                            <option value="">Your job title or role</option>
                            <option value="">----------------</option>
                            {job_roles.map((jr) => {
                                return <option key={jr} value={jr}>{jr}</option>;
                            })}
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="company-size" className="block text-sm font-medium leading-6 text-gray-900">
                        Company Size
                    </label>
                    <div className="mt-2">
                        <select
                            id="company-size" name="company-size" autoComplete="company-size"
                            className={classNames(
                                "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6",
                                company_size.length === 0 ? "text-gray-400" : "text-gray-900"
                            )}
                            disabled={is_processing_continue || is_admin}
                            value={company_size}
                            onChange={(e) => { setCompanySize(e.target.value); }}
                        >
                            <option value="">Size of your comapny</option>
                            <option value="">----------------</option>
                            {company_sizes.map((cs) => {
                                return <option key={cs} value={cs}>{cs}</option>;
                            })}
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="team-size" className="block text-sm font-medium leading-6 text-gray-900">
                        Team Size
                    </label>
                    <div className="mt-2">
                        <select
                            id="team-size" name="team-size" autoComplete="team-size"
                            className={classNames(
                                "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6",
                                team_size.length === 0 ? "text-gray-400" : "text-gray-900"
                            )}
                            disabled={is_processing_continue || is_admin}
                            value={team_size}
                            onChange={(e) => { setTeamSize(e.target.value); }}
                        >
                            <option value="">Size of your team or department</option>
                            <option value="">----------------</option>
                            {team_sizes.map((ts) => {
                                return <option key={ts} value={ts}>{ts}</option>;
                            })}
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                        Country
                    </label>
                    <div className="mt-2">
                        <select
                            id="country" name="country" autoComplete="country"
                            className={classNames(
                                "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6",
                                country_code.length === 0 ? "text-gray-400" : "text-gray-900"
                            )}
                            disabled={is_processing_continue || is_admin}
                            value={country_code}
                            onChange={(e) => { setCountryCode(e.target.value); }}
                        >
                            <option value="">Select your country</option>
                            {countries.map((c, idx) => {
                                return <option key={idx} value={c.code}>{c.name}</option>;
                            })}
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-3">
                </div>
            </div>
        </div>
        {!is_admin && <div className="mt-6 flex items-center justify-end">
            <Button text={is_onboarding ? "Continue" : "Update"}
                disabled={is_processing_continue}
                loading={is_processing_continue}
                highlight={true}
                onClick={onContinue} />
        </div>}
    </Fragment>;
}

