import {
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    useNavigate
} from "react-router-dom";

import * as hi from "@heroicons/react/24/outline";
import { TbTable } from "react-icons/tb";

import {
    ORG_TYPES
} from "../lib/consts";
import * as t from "../lib/types";
import {
    classNames,
    prettySmartDateTime
} from "../lib/utils";
import {
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    BackendObj
} from "../lib/backend";

import {
    LoadingSpinner
} from "../components/LoadingSpinner";
import {
    Button
} from "../components/Button";
import { LongText } from "../components/LongText";
import { OrgPill } from "../components/OrgPill";
import { MultiselectInputField } from "../components/MultiselectInputField";
import { DropdownMenu, IDropdownMenuItem } from "../components/DropdownMenu";
import { TextboxModal } from "../components/TextboxModal";

function EmptyList() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="flex justify-center items-center h-screen w-full">
            <div className="text-center">
                <TbTable className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No templates</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by creating a new template.</p>
                <div className="mt-6">
                    <Button icon={hi.PlusIcon} text="Create Template" href="/template/new" />
                </div>
            </div>
        </div>
    </div >;
}

type SortBy = "name_up" | "name_down" | "created_at_up" | "created_at_down" | "org_name_up" | "org_name_down";

function sortTemplates(templates: t.ITemplateBase[], sort_by: SortBy, orgs: t.IOrganization[]) {
    templates.sort((a, b) => {
        if (sort_by === "name_up") {
            return a.name.localeCompare(b.name);
        } else if (sort_by === "name_down") {
            return b.name.localeCompare(a.name);
        } else if (sort_by === "created_at_up") {
            return a.created_at - b.created_at;
        } else if (sort_by === "created_at_down") {
            return b.created_at - a.created_at;
        } else if (sort_by === "org_name_up") {
            const org_a = orgs.find(org => org.uuid === a.org_uuid)
            const org_b = orgs.find(org => org.uuid === b.org_uuid);
            return org_a?.name.localeCompare(org_b?.name ?? "") ?? 0;
        } else if (sort_by === "org_name_down") {
            const org_a = orgs.find(org => org.uuid === a.org_uuid)
            const org_b = orgs.find(org => org.uuid === b.org_uuid);
            return org_b?.name.localeCompare(org_a?.name ?? "") ?? 0;
        }
        return 0;
    });
}

interface IFilter {
    type: "name" | "org";
    value?: string;
    uuid?: string;
}

export function Templates() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const is_admin = user.role === "admin";
    const memberships = useSelector(selectMemberships);

    const [templates, setTemplates] = useState<t.ITemplateBase[] | undefined>(undefined);
    const [is_loading, setIsLoading] = useState(false);
    const [sort_by, _setSortBy] = useState<SortBy>(localStorage.getItem("sort_templates_by") as SortBy ?? "name");
    const [filters, _setFilters] = useState<IFilter[]>(localStorage.getItem("filters_templates") ? JSON.parse(localStorage.getItem("filters_templates") as string) : []);
    const [is_filter_dialog_open, setIsFilterDialogOpen] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        BackendObj.extractions.listTemplates({})
            .then(({ templates: new_templates }) => {
                sortTemplates(new_templates, sort_by, orgs);
                setTemplates(new_templates);
                setIsLoading(false);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const orgs = memberships.map((membership) => membership.org);

    const setSortBy = (sort_by: SortBy) => {
        _setSortBy(sort_by);
        localStorage.setItem("sort_templates_by", sort_by);
        const new_templates = templates ?? [];
        sortTemplates(new_templates, sort_by, orgs);
        setTemplates(new_templates);
    }

    const selectTemplate = (template_uuid: string, event?: React.MouseEvent) => {
        if (is_loading) { return; }
        if (event?.metaKey || event?.ctrlKey) {
            window.open(`/template/${template_uuid}`, "_blank");
        } else {
            navigate(`/template/${template_uuid}`);
        }
    }

    const setFilters = (new_filters: IFilter[]) => {
        _setFilters(new_filters);
        localStorage.setItem("filters_templates", JSON.stringify(new_filters));
    }

    const addFilter = (filter: IFilter) => {
        setFilters([...filters, filter]);
    }

    const removeFilter = (idx: number) => {
        setFilters(filters.filter((_, i) => i !== idx));
    }

    const onFilterDialogClose = (result: boolean, input_text?: string) => {
        if (result) {
            addFilter({ type: "name", value: input_text });
        }
        setIsFilterDialogOpen(false);
    }

    const org_map = new Map<string, t.IOrganization>();
    for (const org of orgs) {
        org_map.set(org.uuid, org);
    }

    // if undefined we are still loading
    if (templates === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (templates.length === 0) {
        return <EmptyList />;
    }

    const org_menu_items: IDropdownMenuItem[] = memberships.map(({ org }) => ({
        title: org.name,
        onClick: () => addFilter({ type: "org", value: org.name, uuid: org.uuid }),
    }));

    const filtered_templates = templates.filter(template => {
        for (const filter of filters) {
            if (filter.type === "name" && filter.value !== undefined) {
                if (!template.name.toLowerCase().includes(filter.value.toLowerCase())) {
                    return false;
                }
            }
            if (filter.type === "org" && filter.uuid !== undefined) {
                if (template.org_uuid !== filter.uuid) {
                    return false;
                }
            }
        }
        return true;
    });

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-16 w-full bg-white border-b border-b-sea_blue-700">
            <div className="px-10 py-4 flex flex-row items-center max-w-5xl">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-2xl sm:tracking-tight">
                    Process Templates
                </h2>
                <div className="flex-grow" />
                <Button highlight={true} icon={hi.PlusIcon} text="New" href="/template/new" />
            </div>
        </div>
        <div className="max-w-5xl">
            <div className="p-10 py-4">
                <div className="flex flex-row items-center pb-4 gap-x-4">
                    <div className={classNames("flex-grow", is_admin ? "pr-10" : "")}>
                        <MultiselectInputField
                            values={filters.map(filter => filter.value ?? "")}
                            placeholder={is_admin ? "Filter by name or organization" : "Filter by name"}
                            separator="space"
                            onAdd={() => setIsFilterDialogOpen(true)}
                            onClick={(idx) => removeFilter(idx)}
                            onRemove={(idx) => removeFilter(idx)} />
                    </div>
                    {is_admin && <DropdownMenu title="Organizations" items={org_menu_items} align="right" />}
                    <TextboxModal
                        open={is_filter_dialog_open}
                        title="Add filter by name"
                        init_text={""}
                        cancel="Cancel"
                        confirm="Add"
                        validate={(text) => text.length > 0}
                        onClose={onFilterDialogClose} />
                </div>
                <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
                    <table className="w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "name_up" ? "name_down" : "name_up")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline  mr-1", sort_by === "name_up" || sort_by === "name_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Name
                                </th>
                                <th className="w-32 text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "created_at_down" ? "created_at_up" : "created_at_down")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline mr-1", sort_by === "created_at_up" || sort_by === "created_at_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Created
                                </th>
                                <th className="w-40 text-right px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "org_name_up" ? "org_name_down" : "org_name_up")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline mr-1", sort_by === "org_name_up" || sort_by === "org_name_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Organization
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {filtered_templates.map((template) => (
                                <tr key={template.uuid}
                                    className="hover:bg-sea_blue-100 cursor-pointer hover:rounded-md"
                                    onClick={(e) => selectTemplate(template.uuid, e)}>
                                    <td className="p-3 text-sm text-gray-900">
                                        <LongText text={template.name} line_limit={1} />
                                    </td>
                                    <td className="p-3 text-sm text-gray-400">
                                        {prettySmartDateTime(template.created_at)}
                                    </td>
                                    <td className="p-3 flex justify-end">
                                        {org_map.has(template.org_uuid) && <OrgPill
                                            name={org_map.get(template.org_uuid)?.name ?? ""}
                                            type={org_map.get(template.org_uuid)?.type ?? ORG_TYPES.personal} />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    </div>;
}