import {
    Fragment,
    useEffect
} from "react"

import {
    Listbox,
    Transition
} from "@headlessui/react"
import {
    CheckIcon,
    ChevronUpDownIcon
} from "@heroicons/react/20/solid"

import { IOrganization } from "../lib/types";
import {
    classNames,
    prettySmartDateTime
} from "../lib/utils";
import { OrgPill } from "./OrgPill";
import { useSelector } from "react-redux";
import { selectUser } from "../lib/scraper.slice";
import { USER_ROLES } from "../lib/consts";

export interface IRichDropdownValue {
    id: string;
    name: string;
    org?: IOrganization;
    ts?: number;
    separator?: boolean;
    icon?: any;
    hide?: boolean;
    only_admin?: boolean;
}

type RichDropdownProps = {
    values: IRichDropdownValue[];
    selected: string;
    disabled?: boolean;
    onChange: (id: string) => void;
    auto_select_first?: boolean;
}

export function RichDropdown(props: RichDropdownProps) {
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const { values: all_values, selected, disabled, onChange, auto_select_first = false } = props;

    useEffect(() => {
        if (selected === "" && all_values.length > 0 && auto_select_first) {
            onChange(all_values[0].id);
        }
    }, [selected, all_values, auto_select_first, onChange]);

    const selected_value = all_values.find((value) => value.id === selected);
    const values = all_values.filter((value) => !value.only_admin || is_admin).filter((value) => !value.hide);

    return (
        <Listbox value={selected} onChange={onChange} disabled={disabled}>
            {({ open }) => (
                <Fragment>
                    <div className="relative">
                        <Listbox.Button className={classNames("relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-space_blue-500 sm:text-sm sm:leading-6", disabled ? "bg-gray-100" : "bg-white")}>
                            <span className="flex items-center">
                                {selected_value && selected_value.icon && <selected_value.icon className="text-space_blue-500 h-5 w-5 flex-shrink-0 rounded-full" />}
                                <span className={classNames("block truncate", selected_value?.icon ? "ml-3" : "", selected_value ? "" : "text-gray-400")}>{selected_value?.name ?? "/"}</span>
                                {selected_value && selected_value.org && <span className="ml-3"><OrgPill name={selected_value.org.name} type={selected_value.org.type} small={true} /></span>}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="outer-div absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {values.map((value, idx) => (
                                    <Listbox.Option
                                        key={idx}
                                        className={({ active }) =>
                                            classNames(
                                                active ? "bg-space_blue-600 text-white" : "text-gray-900",
                                                "relative cursor-default select-none py-2 pl-3 pr-9",
                                                value.separator ? "border-b border-gray-200" : "",
                                                value.only_admin ? "bg-gray-50" : ""
                                            )
                                        }
                                        value={value.id}
                                    >
                                        {({ selected, active }) => (
                                            <Fragment>
                                                <div className="flex items-center">
                                                    {value.icon && <value.icon className="text-space_blue-500 h-5 w-5 flex-shrink-0 rounded-full" />}
                                                    <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate", value.icon ? "ml-3" : "")}>{value.name}</span>
                                                    {value.org && <span className="ml-3"><OrgPill name={value.org.name} type={value.org.type} small={true} /></span>}
                                                    {value.ts && <span className="flex-grow text-right pr-6 text-xs text-gray-400">{prettySmartDateTime(value.ts)}</span>}
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? "text-white" : "text-space_blue-600",
                                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </Fragment>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Fragment>
            )}
        </Listbox>
    )
}
