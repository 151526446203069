import { Link } from "react-router-dom";
import { classNames } from "../lib/utils";

type BigButtonProps = {
    title: string;
    subtitle?: string;
    icon?: any;
    href: string;
};

export function BigButton(props: BigButtonProps) {
    return <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-zinc-50 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-space_blue-500 focus-within:ring-offset-2 hover:border-gray-400 hover:bg-space_blue-100">
        <div className="flex-shrink-0">
            {props.icon && <props.icon className="h-6 w-6 text-space_blue-300" aria-hidden="true" />}
        </div>
        <div className="min-w-0 flex-1">
            <Link to={props.href} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className={classNames("font-medium text-gray-900 truncate", props.subtitle ? "text-base" : "text-lg")}>{props.title}</p>
                {props.subtitle && <p className="text-sm text-gray-500 truncate">{props.subtitle}</p>}
            </Link>
        </div>
    </div>;
}
