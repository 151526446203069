import { Fragment } from "react";

import {
    Popover,
    PopoverButton,
    PopoverPanel,
    Transition
} from "@headlessui/react";
import { ChevronDownIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { classNames } from "../lib/utils";
import { useSelector } from "react-redux";
import { selectUser } from "../lib/scraper.slice";
import { USER_ROLES } from "../lib/consts";

export interface IDropdownMenuItem {
    title: string;
    separator?: boolean;
    admin_only?: boolean;
    hide?: boolean;
    onClick: () => void;
}

type DropdownMenuProps = {
    title: string;
    items: IDropdownMenuItem[];
    align?: "left" | "right";
};

export function DropdownMenu(props: DropdownMenuProps) {
    const { title, items, align = "left" } = props;

    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const show_items = items
    .filter(item => !item.admin_only || is_admin)
        .filter(item => !item.hide);

    return <Popover className="relative">
        <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-700 cursor-pointer">
            <span>{title}</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </PopoverButton>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
        >
            <PopoverPanel className={classNames("absolute", align === "right" ? "right-0" : "left-0", "z-10 mt-2 flex w-screen max-w-min")}>
                <div className="w-80 shrink rounded bg-white p-2 text-sm leading-6 text-gray-700 shadow-lg ring-1 ring-gray-900/5 max-h-[300px] overflow-y-auto">
                    {show_items.map(({ title, separator, admin_only, onClick }, idx) => (
                        <PopoverButton key={idx} className={classNames(
                            "flex flex-row items-center p-2 hover:text-space_blue-600 cursor-pointer w-full text-left",
                            separator && (idx !== items.length - 1) ? "border-b border-gray-200" : "",
                            admin_only ? "text-gray-400" : "text-gray-900"
                        )} onClick={onClick}>
                            {admin_only && <LockClosedIcon className="w-4 h-4 mr-2" />}
                            {title}
                        </PopoverButton>
                    ))}
                </div>
            </PopoverPanel>
        </Transition>
    </Popover>;
}