import React from "react";
import {
    ChevronDownIcon,
    ChevronUpIcon
} from "@heroicons/react/24/outline";

type NumberInputProps = {
    value: number;
    setValue: (value: number) => void;
    min: number;
    max: number;
}

export function NumberInput(props: NumberInputProps) {
    const { value, setValue, min, max } = props;

    const increment = (e: React.MouseEvent<SVGSVGElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (value < max) {
            setValue(value + 1);
        }
    };

    const decrement = (e: React.MouseEvent<SVGSVGElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (value > min) {
            setValue(value - 1);
        }
    };

    return <div className="bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-space_blue-600">
        <div className="flex flex-row">
            <input
                id="numberInput"
                type="text"
                value={value}
                readOnly
                className="w-full border-0 bg-transparent focus:ring-0 h-10"
            />
            <div className="flex flex-col">
                <ChevronUpIcon className="h-5 w-8 cursor-pointer border-l border-gray-300 px-2" onClick={increment} />
                <ChevronDownIcon className="h-5 w-8 cursor-pointer border-l border-gray-300 px-2" onClick={decrement} />
            </div>
        </div>
    </div>;
};
