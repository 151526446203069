import {
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import * as t from "../lib/types";
import { Backend } from "../lib/backend";
import { selectEnv } from "../lib/scraper.slice";

import { Button } from "../components/Button";
import { FieldsTable } from "../components/FieldsTable";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { SlChemistry } from "react-icons/sl";
import { newUuid } from "../lib/utils";
import { CONTEXT_TYPES } from "../lib/consts";

const DIALOG_STATES = {
    "example": "example",
    "fields": "fields",
    "check": "check"
}

const STEP_STATES = {
    "complete": "complete",
    "current": "current",
    "upcoming": "upcoming"
}

type Step = {
    id: string;
    name: string;
    state: string;
    status: string;
}

const INIT_STEPS: Step[] = [
    { id: "Step 1", name: "Provide Example", state: DIALOG_STATES.example, status: "upcoming" },
    { id: "Step 2", name: "Define Fields and Extract", state: DIALOG_STATES.fields, status: "upcoming" }
];

const EXAMPLE = `From: jane.demaggio@tier2.com

Maria,

Sorry for not replying earlier. I was on vacation.
We are ordering 10500 pieces of SKU 100356000. Can you confirm delivery by 10.8.2023?
Also there is possibility to order in September for additional 20000 pieces of the same SKU, delivery by end of the month.

Regards, Jane `;

const FIELDS: t.IContextField[] = [
    { uuid: newUuid(), name: "sender", datatype: "string", type: "extract" },
    { uuid: newUuid(), name: "SKU", datatype: "string", type: "extract" },
    { uuid: newUuid(), name: "quantity", datatype: "string", type: "extract" },
    { uuid: newUuid(), name: "delivery date", datatype: "string", type: "extract" },
    { uuid: newUuid(), name: "status", datatype: "string", type: "extract" }
];

const DEFAULT_EXAMPLE_FORMAT: { [key: string]: string } = {
    "sender": "first and last name (email)",
    "SKU": "number",
    "quantity": "number",
    "delivery date": "DD-MM-YYYY",
    "status": "confirmed, pending confirmation, possible, canceled or unknown"
};

const DEFAULT_EXAMPLE_EXAMPLE: { [key: string]: string } = {
    "delivery date": "20-10-2023",
    "status": "confirmed"
};

export function Demo() {
    const env = useSelector(selectEnv);

    const [dialog_state, setDialogState] = useState<string>(DIALOG_STATES.example);
    const [steps, setSteps] = useState<Step[]>(INIT_STEPS);

    const [example_text, setExampleText] = useState<string>("");
    const [fields, setFields] = useState<t.IContextField[]>([]);
    const [is_init_fields_done, setIsInitFieldsDone] = useState<boolean>(false);
    const [check_result, setCheckResult] = useState<t.IContextDemoCheck | undefined>(undefined);
    const [is_ready, setIsReady] = useState<boolean>(false);
    const [is_processing, setIsProcessing] = useState<boolean>(false);

    // set debug start
    useEffect(() => {
        setExampleText(EXAMPLE);
    }, [env]);

    useEffect(() => {
        const new_steps = [...steps];
        let did_current = false;
        let did_change = false;
        for (const step of steps) {
            const old_status = step.status;
            if (step.state === dialog_state) {
                step.status = STEP_STATES.current;
                did_current = true;
            } else {
                step.status = did_current ? STEP_STATES.upcoming : STEP_STATES.complete;
            }
            did_change = did_change || old_status !== step.status;
        }
        if (did_change) {
            setSteps(new_steps);
        }
    }, [dialog_state, steps]);

    const addNextField = (array_level: number, char_level: number) => {
        // check if current field finished
        if (array_level < FIELDS.length && char_level < FIELDS[array_level].name.length) {
            // not yet add another character
            setFields([
                ...FIELDS.slice(0, array_level),
                { uuid: newUuid(), name: FIELDS[array_level].name.substring(0, char_level + 1), datatype: "string", type: "extract" }
            ]);
            setTimeout(() => addNextField(array_level, char_level + 1), 40);
        } else if (array_level < FIELDS.length) {
            // field name done, add another field
            setFields(FIELDS.slice(0, array_level + 1));
            setTimeout(() => addNextField(array_level + 1, 0), 100);
        } else {
            // all fields done
            setIsInitFieldsDone(true);
        }
    };

    const onNext = () => {
        if (!is_init_fields_done) {
            setTimeout(() => addNextField(0, 0), 100);
        }
        setDialogState(DIALOG_STATES.fields);
    }

    const onCheck = async () => {
        setIsProcessing(true);
        // prepare fields in case we have default example
        const new_fields = JSON.parse(JSON.stringify(fields));
        for (const field of new_fields) {
            if (DEFAULT_EXAMPLE_FORMAT[field.name]) {
                field.format = DEFAULT_EXAMPLE_FORMAT[field.name];
            }
            if (DEFAULT_EXAMPLE_EXAMPLE[field.name]) {
                field.example = DEFAULT_EXAMPLE_EXAMPLE[field.name];
            }
        }
        // check example
        const check_result = await Backend.checkDemo({
            fields: new_fields,
            example_text
        });
        setCheckResult(check_result);
        setIsProcessing(false);
        setIsReady(true);
    };

    // helpers
    const is_example_invalid = example_text.length === 0;
    const is_fields_invalid = fields.length === 0;

    return <div className="flex justify-center w-full">
        <div className="flex-row lg:fixed overflow-y-auto w-full max-w-4xl mx-auto justi">
            <div className="px-4 pt-2 pb-2 hidden md:block">
                <nav aria-label="Progress">
                    <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                        {steps.map((step) => (
                            <li key={step.name} className="md:flex-1">
                                {step.status === "complete" ? (
                                    <span className="group flex flex-col border-l-4 border-space_blue-700 py-2 pl-4 hover:border-space_blue-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                                        <span className="text-sm font-medium text-space_blue-600 group-hover:text-space_blue-800">{step.id}</span>
                                        <span className="text-sm font-medium">{step.name}</span>
                                    </span>
                                ) : step.status === "current" ? (
                                    <span className="flex flex-col border-l-4 border-space_blue-700 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" aria-current="step">
                                        <span className="text-sm font-medium text-space_blue-600">{step.id}</span>
                                        <span className="text-sm font-medium">{step.name}</span>
                                    </span>
                                ) : (
                                    <span className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                                        <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                                        <span className="text-sm font-medium">{step.name}</span>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>

            <div className="px-4 pt-2 pb-0 md:hidden">
                <nav aria-label="Progress">
                    <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                        {steps.filter(step => step.status === "current").map((step) => (
                            <li key={step.name} className="md:flex-1">
                                <span className="flex flex-row border-l-4 border-space_blue-700 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" aria-current="step">
                                    <span className="text-sm font-medium text-space_blue-600 mr-5">{step.id}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </span>
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>

            {dialog_state === DIALOG_STATES.example && <div className="px-2 py-2">
                <form>
                    <div className="space-y-12 sm:space-y-16">
                        <div>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-800">
                                Provide an example of a text (e.g. email) you want to extract fields from.
                            </p>

                            <div className="mt-4 space-y-8 sm:pb-0">
                                <textarea
                                    id="about"
                                    name="about"
                                    rows={11}
                                    className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6"
                                    value={example_text}
                                    onChange={(e) => { setExampleText(e.target.value); setIsReady(false); }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 flex items-center justify-end gap-x-1">
                        <Button text="Next"
                            disabled={is_example_invalid}
                            highlight={true}
                            onClick={onNext} />
                    </div>
                </form>
            </div>}

            {dialog_state === DIALOG_STATES.fields && <div className="px-2 py-2">
                <form>
                    <div className="space-y-12 sm:space-y-16">
                        <div>
                            {!is_ready && <div>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-800">
                                    List fields you want to extract and provide a short description of each field.
                                </p>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-800">
                                    You can add additional fields to the table or remove existing fields.
                                </p>
                            </div>}

                            {is_ready && <div>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-800">
                                    Wow! Here is your extracted data.
                                </p>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-800">
                                    You can create a free-trail account to extract more data.
                                </p>
                            </div>}

                            <div className="mt-4 space-y-8 sm:pb-0 outer-div">
                                <FieldsTable
                                    fields={fields}
                                    prev_fields={[]}
                                    context_type={CONTEXT_TYPES.array}
                                    records={check_result?.records || []}
                                    disabled={is_processing}
                                    is_editable={true}
                                    show_delete={true}
                                    focus_on_col={fields.length > 0 ? fields.length - 1 : undefined}
                                    is_demo={true}
                                    setFields={async (fields: t.IContextField[]) => {
                                        setFields(fields);
                                        return true;
                                    }}
                                    setInvalidate={() => setIsReady(false)} />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 flex items-center justify-end gap-x-2">
                        <Button text="Back"
                            onClick={() => { setDialogState(DIALOG_STATES.example); }} />
                        {!is_ready && <Button
                            icon={is_processing ? undefined : SlChemistry}
                            text="Extract"
                            disabled={is_fields_invalid || is_processing}
                            highlight={true}
                            loading={is_processing}
                            onClick={onCheck} />}
                        {is_ready && <Button text="Get Started"
                            icon={ArrowRightIcon}
                            highlight={true}
                            href="https://app.nordoon.ai/register"
                            open_in_new_tab={true} />}
                    </div>
                </form>
            </div>}
        </div>
    </div>;
}