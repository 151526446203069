import { useState } from "react";

import {
    PencilIcon,
    TrashIcon
} from "@heroicons/react/24/outline";

import { prettySmartDateTime } from "../lib/utils";
import { IEndpointToLookupTable } from "../lib/types";

import { Button } from "./Button";
import { LoadingSpinnerLimit } from "./LoadingSpinner";
import { ConfirmModal } from "./ConfirmModal";

type EndpointToLookupTableProps = {
    can_edit: boolean;
    source_type: "endpoint" | "lookup_table";
    source_uuid: string;
    endpoint_to_lookup_table_mappings?: IEndpointToLookupTable[];
    is_deleting: boolean;
    deleteMapping: (endpoint_to_lookup_table_uuid: string) => Promise<void>;
}

export function EndpointToLookupTable(props: EndpointToLookupTableProps) {
    const { can_edit, source_type, source_uuid, endpoint_to_lookup_table_mappings, is_deleting, deleteMapping } = props;

    const [confirm_mapping_uuid, setConfirmMappingUuid] = useState<string | undefined>(undefined);

    return <div className="">
        {endpoint_to_lookup_table_mappings !== undefined && endpoint_to_lookup_table_mappings.length > 0 &&
            <table className="w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="w-1 px-3 py-2 text-left text-sm font-semibold text-gray-900">Endpoint</th>
                        <th className="w-1 px-3 py-2 text-left text-sm font-semibold text-gray-900">Lookup Table</th>
                        <th className="w-1 px-3 py-2 text-left text-sm font-semibold text-gray-900">Created</th>
                        {can_edit && <th className="w-1 px-3 py-2 text-left text-sm font-semibold text-gray-900"></th>}
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {endpoint_to_lookup_table_mappings.map((mapping, idx) => (
                        <tr key={idx}>
                            <td className="px-3 py-2 text-sm align-middle">{mapping.endpoint_name}</td>
                            <td className="px-3 py-2 text-sm align-middle">{mapping.lookup_table_name}</td>
                            <td className="px-3 py-2 text-sm align-middle">{prettySmartDateTime(mapping.created_at)}</td>
                            {can_edit && <td className="px-3 py-2 text-sm text-right">
                                <Button
                                    icon={PencilIcon}
                                    text=""
                                    disabled={is_deleting}
                                    href={`/endpoint-lookup-table-mapping/edit/${source_type}/${source_uuid}/${mapping.uuid}`} />
                                <Button
                                    icon={is_deleting ? undefined : TrashIcon}
                                    text=""
                                    loading={is_deleting}
                                    disabled={is_deleting}
                                    onClick={() => setConfirmMappingUuid(mapping.uuid)} />
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </table>}
        {endpoint_to_lookup_table_mappings && endpoint_to_lookup_table_mappings.length === 0 && <span className="text-sm">No connected endpoints</span>}
        {!endpoint_to_lookup_table_mappings && <LoadingSpinnerLimit />}
        <ConfirmModal
            open={confirm_mapping_uuid !== undefined}
            title="Delete Mapping"
            message={["Are you sure you want to delete this mapping?"]}
            confirm="Delete"
            onClose={(result: boolean) => {
                if (result && confirm_mapping_uuid) {
                    deleteMapping(confirm_mapping_uuid);
                }
                setConfirmMappingUuid(undefined);
            }} />
    </div>;
}