import { useState } from "react";

import * as hi from "@heroicons/react/24/outline";

import {
    IItemValidationSummary,
    IItemValidationAnalyticsTag,
    IValidationResultEx
} from "../lib/backend/extractions.types.generated";
import { classNames } from "../lib/utils";

const STATUS_COLORS = {
    pass: "mint",
    warn: "red",
    fail: "red"
};

const STATUS_ICONS = {
    pass: hi.CheckIcon,
    warn: hi.XMarkIcon,
    fail: hi.XMarkIcon
};

interface IValidationResultProps {
    result: IValidationResultEx;
}

function ValidationResult({ result }: IValidationResultProps) {
    const STATUS_COLOR = STATUS_COLORS[result.status];
    const STATUS_ICON = STATUS_ICONS[result.status];

    const context_name = result.context_name.length > 0 ? result.context_name : `Step ${result.context_idx + 1}`;

    const result_title = (result.type === "context") ?
        `${context_name}` :
        (result.type === "record") ?
            `${context_name} [ row ${(result.record_idx ?? 0) + 1} ]` :
            `${context_name} [ row ${(result.record_idx ?? 0) + 1} ] ➜ ${result.field_name}`;

    return (
        <div className="pl-8 pr-4 py-2 text-xs flex flex-row items-center">
            <STATUS_ICON className={`h-5 w-5 mr-2 text-${STATUS_COLOR}-600`} />
            <div className={`font-mono text-${STATUS_COLOR}-600`}>{result_title}</div>
            <div className="pl-4 text-gray-400">{result.name} {result.reason ? `- ${result.reason}` : ""}</div>
        </div>
    );
}

interface IAnalyticsTagProps {
    tag: IItemValidationAnalyticsTag;
    last_tag: boolean;
}

function AnalyticsTag({ tag, last_tag }: IAnalyticsTagProps) {
    const STATUS_COLOR = STATUS_COLORS[tag.tag_status];
    const STATUS_ICON = STATUS_ICONS[tag.tag_status];

    console.log(tag.tag_status, STATUS_COLOR, STATUS_ICON);

    const [is_expanded, setIsExpanded] = useState(false);

    return (
        <div className="">
            <div
                className={classNames(
                    "flex items-center py-2 pl-6 pr-3 cursor-pointer",
                    `bg-${STATUS_COLOR}-50 text-${STATUS_COLOR}-600`,
                    (last_tag && !is_expanded) ? "rounded-b" : ""
                )}
                onClick={() => setIsExpanded(!is_expanded)}
            >
                <STATUS_ICON className="h-5 w-5 mr-2" />
                <span className="text-sm">{tag.analytics_tag}</span>
                <div className="flex-grow" />
                <hi.ChevronDownIcon
                    className={`h-5 w-5 transform transition-transform ${is_expanded ? "rotate-180" : ""}`}
                />
            </div>
            {is_expanded && (
                <div className="mt-1 pl-3">
                    {tag.results.map((result, idx) => (
                        <ValidationResult key={idx} result={result} />
                    ))}
                </div>
            )}
        </div>
    );
}

interface IValidationSummaryProps {
    summary: IItemValidationSummary;
    hide_empty?: boolean;
}

export function ValidationSummary({ summary, hide_empty }: IValidationSummaryProps) {
    const STATUS_ICON = STATUS_ICONS[summary.final_status];
    const STATUS_COLOR = STATUS_COLORS[summary.final_status];

    const [is_expanded, setIsExpanded] = useState(false);

    const did_check = summary.analytics_tags.length > 0;

    return did_check ?
        <div className="py-0">
            <div className="flex flex-col items-center w-full rounded border">
                <div
                    className={classNames(
                        "w-full flex items-center p-3 rounded-t border-b cursor-pointer",
                        `bg-${STATUS_COLOR}-50 text-${STATUS_COLOR}-600`
                    )}
                    onClick={() => setIsExpanded(!is_expanded)}
                >
                    <STATUS_ICON className="h-5 w-5 mr-2" />
                    <span className="text-sm">
                        {summary.final_status === "pass" ? "Everything OK" : "Validations errors found"}
                    </span>
                    <div className="flex-grow" />
                    <hi.ChevronDownIcon
                        className={`h-5 w-5 transform transition-transform ${is_expanded ? "rotate-180" : ""}`}
                    />
                </div>
                {is_expanded && <div className="w-full">
                    {summary.analytics_tags.map((tag, idx) => (
                        <AnalyticsTag key={idx} tag={tag} last_tag={idx === summary.analytics_tags.length - 1} />
                    ))}
                </div>}
            </div>

        </div > :
        hide_empty ?
            null :
            <div className="px-2 text-gray-500 text-xs">
                No validation checks were performed
            </div>;
}