import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux";

import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react"

import { scraperUser, selectUser } from "../lib/scraper.slice";
import { BackendObj } from "../lib/backend";
import { AppDispatch } from "../store";

export function ChangeModal() {
    const dispatch = useDispatch<AppDispatch>();

    const user = useSelector(selectUser);

    const open = user.notify_about_changes;

    const handleClose = async () => {
        await BackendObj.extractions.confirmNotifyAboutChanges({});
        dispatch(scraperUser());
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={handleClose}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-start pt-20 px-4 justify-center text-center sm:items-center sm:p-10">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden bg-transparent text-left transition-all sm:w-full sm:max-w-4xl">
                                <img src="/changes/change_01.svg" alt="Change" className="w-full cursor-pointer" onClick={handleClose} />
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
