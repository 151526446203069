import {
    useEffect,
    useState
} from "react";
import {
    useSelector
} from "react-redux";

import {
    useNavigate,
} from "react-router-dom";

import * as hi from "@heroicons/react/24/outline";
import { SlChemistry } from "react-icons/sl";

import * as t from "../lib/types";
import {
    EXTRACT_CONFIRMATION_STATUS,
    ORG_TYPES,
} from "../lib/consts";
import {
    ExtractConfirmationStatus
} from "../lib/backend/extractions.types.generated";
import { Backend, BackendObj } from "../lib/backend";
import {
    selectIsSidebarLarge,
    selectMemberships,
    selectUser,
} from "../lib/scraper.slice";
import {
    classNames,
    prettySmartDateTime,
    redirectToExternalPageWithPostData,
} from "../lib/utils";

import { Button } from "../components/Button";
import { LoadingSpinner, LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { OrgPill } from "../components/OrgPill";
import { Checkbox } from "../components/Checkbox";
import { ButtonMenu, IButtonMenuItem } from "../components/ButtonMenu";
import { ConfirmModal } from "../components/ConfirmModal";
import { DropdownMenu, IDropdownMenuItem } from "../components/DropdownMenu";
import { MultiselectInputField } from "../components/MultiselectInputField";
import { TextboxModal } from "../components/TextboxModal";
import { IExtractJobSimple } from "../lib/types";
import { JobIcon } from "../components/ExtractJobs";

export function EmptyItemList() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="flex justify-center items-center h-screen w-full">
            <div className="text-center">
                <SlChemistry className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No items</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by creating a new job.</p>
                <div className="mt-6">
                    <Button text="New Job" href="/extraction/new" />
                </div>
            </div>
        </div>
    </div >;
}

type ItemListProps = {
    items: t.IItemSlim[];
    show_template: boolean;
    onItemSelected: (item: t.IItemSlim) => void;
};

export function ItemList(props: ItemListProps) {
    const { items, show_template, onItemSelected } = props;

    const getStatus = (item: t.IItemSlim): ExtractConfirmationStatus => {
        return (item.extract_confirmations_status || EXTRACT_CONFIRMATION_STATUS.none) as ExtractConfirmationStatus;
    }

    return <div className="rounded-lg overflow-hidden shadow ring-1 ring-black ring-opacity-5">
        <table className="w-full divide-y divide-gray-300 table-fixed">
            <thead className="bg-gray-50">
                <tr>
                    <th className="w-12 px-3 py-3 text-sm cursor-pointer font-semibold text-gray-900"></th>
                    <th className={classNames("text-left px-3 py-3 text-sm cursor-pointer font-semibold text-gray-900", show_template ? "w-[55%]" : "w-[90%]")}>Name</th>
                    {show_template && <th className="w-[35%] text-left px-3 py-3 text-sm cursor-pointer font-semibold text-gray-900">Template</th>}
                    <th className="hidden lg:table-cell w-32 text-left px-3 py-3 text-sm cursor-pointer font-semibold text-gray-900">Created</th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
                {items.map((item, idx) => (
                    <tr key={item.uuid}
                        className="hover:bg-sea_blue-100 cursor-pointer hover:rounded-md"
                        onClick={() => onItemSelected(item)}>
                        <td className="text-center pl-3 py-4 text-xs cursor-pointer text-gray-400">
                            {idx + 1}
                        </td>
                        <td className="p-3 text-sm text-gray-900">
                            <div className="flex flex-row items-center">
                                <div className={classNames("pl-2 border-l-8 flex-shrink-0",
                                    getStatus(item) === EXTRACT_CONFIRMATION_STATUS.pending ?
                                        "border-yellow-400" :
                                        getStatus(item) === EXTRACT_CONFIRMATION_STATUS.confirmed ?
                                            "border-mint-400" :
                                            getStatus(item) === EXTRACT_CONFIRMATION_STATUS.rejected ?
                                                "border-red-400" :
                                                "border-gray-400")}>
                                    &nbsp;
                                </div>
                                <span className="ml-2 truncate">{item.name}</span>
                            </div>
                        </td>
                        {show_template && <td className="p-3 text-sm text-gray-400">
                            <div className="truncate">{item.template_name}</div>
                        </td>}
                        <td className="hidden lg:table-cell p-3 text-sm text-gray-400 whitespace-nowrap">
                            {prettySmartDateTime(item.created_at)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>;
}

function RunningJobs({ running_jobs }: { running_jobs?: IExtractJobSimple[] }) {
    const navigate = useNavigate();

    const memberships = useSelector(selectMemberships);

    const selectJob = (job_uuid: string) => {
        navigate(`/job/${job_uuid}`);
    }

    if (running_jobs === undefined || running_jobs.length === 0) {
        return null;
    }

    const orgs = memberships.map((membership) => membership.org);
    const org_map = new Map<string, t.IOrganization>();
    for (const org of orgs) {
        org_map.set(org.uuid, org);
    }

    return <div className="flex flex-col gap-5 pt-4">
        <div className="mx-5 font-bold">Currently running</div>
        <div className="rounded-lg shadow ring-1 ring-black ring-opacity-5">
            <table className="w-full divide-y divide-gray-300 table-fixed">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="w-12 pl-3 pr-2 py-2 text-xs cursor-pointer font-semibold text-gray-900"></th>
                        <th className="w-12 pl-3 pr-2 py-2 text-xs cursor-pointer font-semibold text-gray-900"></th>
                        <th className="w-[45%] text-left px-3 py-2 text-sm cursor-pointer font-semibold text-gray-900">Name</th>
                        <th className="w-[25%] text-left px-3 py-2 text-sm cursor-pointer font-semibold text-gray-900"></th>
                        <th className="w-32 text-left px-3 py-2 text-sm cursor-pointer font-semibold text-gray-900">Started</th>
                        <th className="w-40 text-right px-3 py-2 text-sm cursor-pointer font-semibold text-gray-900"></th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {running_jobs.map((job, idx) => (
                        <tr key={idx}
                            className="hover:bg-sea_blue-100 cursor-pointer hover:rounded-md"
                            onClick={(e) => selectJob(job.uuid)}>
                            <td className="text-center pl-3 py-3 text-xs cursor-pointer text-gray-400">
                                {idx + 1}
                            </td>
                            <td className={classNames("text-center pl-3 py-3 text-xs cursor-pointer",
                                job.status === "running" ? "text-gray-400" :
                                    job.status === "done" ? "text-space_blue-400" :
                                        job.status === "error" ? "text-red-400" :
                                            "text-gray-400")}>
                                <JobIcon job_type={job.type} job_status={job.status} fallback="icon" />
                            </td>
                            <td className="p-3 text-sm text-gray-900">
                                {job.title.length > 0 ? job.title : "[no subject]"}
                            </td>
                            <td className="p-3 text-sm text-gray-400">
                                {job.subtitle.length > 0 ? job.subtitle : ""}
                            </td>
                            <td className="p-3 text-sm text-gray-400">
                                {prettySmartDateTime(job.start_ts)}
                            </td>
                            <td className="p-3 flex justify-end">
                                {org_map.has(job.org_uuid) && <OrgPill
                                    name={org_map.get(job.org_uuid)?.name ?? ""}
                                    type={org_map.get(job.org_uuid)?.type ?? ORG_TYPES.personal} />}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <div className="py-5 mx-5 font-bold">Previous results</div>
    </div>;
}

interface IFilter {
    type: "name" | "template" | "org";
    value?: string;
    uuid?: string;
}

export function Items() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const is_admin = user.role === "admin";
    const memberships = useSelector(selectMemberships);

    const [items, setItems] = useState<t.IItemSlim[] | undefined>(undefined);
    const [filtered_items, setFilteredItems] = useState<t.IItemSlim[] | undefined>(undefined);
    const [is_loading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(100);
    const [is_loading_more, setIsLoadingMore] = useState(false);
    const [no_more_items, setNoMoreItems] = useState(false);
    const [selected_items, setSelectedItems] = useState<Set<string>>(new Set());
    const [show_confirm, setShowConfirm] = useState(false);
    const [allow_remove, setAllowRemove] = useState(true);
    const [filters, setFilters] = useState<IFilter[]>([]);
    const [is_filter_dialog_open, setIsFilterDialogOpen] = useState(false);
    const [running_jobs, setRunningJobs] = useState<IExtractJobSimple[] | undefined>(undefined);
    const [previous_job_uuids, setPreviousJobUuids] = useState<string[]>([]);
    const [ticker, setTicker] = useState<number>(0);

    useEffect(() => {
        setIsLoading(true);
        BackendObj.extractions.listItems({ limit, offset: 0 })
            .then(({ items: new_items }) => {
                setItems(new_items);
                setNoMoreItems(new_items.length < limit);
                setIsLoading(false);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const increaseLimit = async () => {
        const inc_limit = limit + 100;
        setIsLoadingMore(true);
        const { items: new_items } = await BackendObj.extractions.listItems({ limit: inc_limit, offset: 0 });
        setItems(new_items);
        setLimit(inc_limit);
        setNoMoreItems(new_items.length < inc_limit);
        setIsLoadingMore(false);
    };

    useEffect(() => {
        if (items !== undefined) {
            let new_filtered_items = filters.length > 0 ? items.filter(item => {
                // all filters must be true
                for (const filter of filters) {
                    if (filter.type === "name" && filter.value !== undefined) {
                        if (!item.name.toLowerCase().includes(filter.value.toLowerCase())) {
                            return false;
                        }
                    }
                    if (filter.type === "template" && filter.uuid !== undefined) {
                        if (item.template_uuid !== filter.uuid) {
                            return false;
                        }
                    }
                    if (filter.type === "org" && filter.uuid !== undefined) {
                        if (item.template_org_uuid !== filter.uuid) {
                            return false;
                        }
                    }
                }
                return true;
            }) : items;
            setFilteredItems(new_filtered_items);
            // if filtered items is less than limit and there are more items, increase limit
            if (new_filtered_items.length < limit && !no_more_items) {
                increaseLimit();
            }
        }
    }, [filters, items]); // eslint-disable-line react-hooks/exhaustive-deps

    const refresh = async () => {
        setIsLoading(true);
        setItems(undefined);
        const { items: new_items } = await BackendObj.extractions.listItems({ limit, offset: 0 });
        setItems(new_items);
        setNoMoreItems(new_items.length < limit);
        setIsLoading(false);
    };

    useEffect(() => {
        Backend.getExtractJobs({ offset: 0, limit: 10, status: "running" })
            .then(({ jobs: new_jobs }) => {
                setRunningJobs(new_jobs);
                // refresh every 1 seconds if there are new jobs, else refresh every 10 seconds
                if (ticker < 3600) {
                    setTimeout(() => { setTicker(ticker + 1); }, new_jobs.length > 0 ? 1000 : 10000);
                }
                // check if any past uuid not in new_jobs
                const new_uuids = new_jobs.map(job => job.uuid);
                const missing_uuids = previous_job_uuids.filter(uuid => !new_uuids.includes(uuid));
                if (missing_uuids.length > 0) {
                    // some jobs are missing, refresh items
                    refresh();
                }
                setPreviousJobUuids(new_uuids);
            })
            .catch((error) => { console.error(error.message); });
    }, [ticker]); // eslint-disable-line react-hooks/exhaustive-deps

    const onItemSelected = (item: t.IItemSlim, event?: React.MouseEvent) => {
        if (is_loading) { return; }

        if (event?.metaKey || event?.ctrlKey) {
            window.open(`/item/${item.uuid}`, "_blank");
        } else {
            navigate(`/item/${item.uuid}`);
        }
    }

    const getStatus = (item: t.IItemSlim): ExtractConfirmationStatus => {
        return (item.extract_confirmations_status || EXTRACT_CONFIRMATION_STATUS.none) as ExtractConfirmationStatus;
    }


    const orgs = memberships.map((membership) => membership.org);
    const org_map = new Map<string, t.IOrganization>();
    for (const org of orgs) {
        org_map.set(org.uuid, org);
    }

    const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        // Check if scrolled to bottom (with a small threshold)
        if (scrollHeight - scrollTop - clientHeight < 50 && !no_more_items) {
            increaseLimit();
        }
    };

    const toggleSelectAll = (checked: boolean) => {
        if (checked) {
            setSelectedItems(new Set(filtered_items?.map(item => item.uuid)));
        } else {
            setSelectedItems(new Set());
        }
    };

    const toggleItemSelection = (uuid: string) => {
        const new_selected = new Set(selected_items);
        if (new_selected.has(uuid)) {
            new_selected.delete(uuid);
        } else {
            new_selected.add(uuid);
        }
        setSelectedItems(new_selected);
    };

    const onRemoveClose = async () => {
        setShowConfirm(false);
        setAllowRemove(false);
        await BackendObj.extractions.deleteItems({ item_uuids: Array.from(selected_items) });
        refresh();
        setAllowRemove(true);
        setSelectedItems(new Set());
    };

    const downloadItems = (type: "excel" | "json") => {
        const url = `/api/items/${type}`;
        const post_data = {
            item_uuids: Array.from(selected_items)
        };
        redirectToExternalPageWithPostData(url, true, post_data);
        setSelectedItems(new Set());
    }

    const addFilter = (filter: IFilter) => {
        setFilters([...filters, filter]);
        // on filter change, reset selected items
        setSelectedItems(new Set());
    }

    const removeFilter = (idx: number) => {
        setFilters(filters.filter((_, i) => i !== idx));
        // on filter change, reset selected items
        setSelectedItems(new Set());
    }

    const onFilterDialogClose = (result: boolean, input_text?: string) => {
        if (result) {
            addFilter({ type: "name", value: input_text });
        }
        setIsFilterDialogOpen(false);
    }

    if (items === undefined || filtered_items === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (items.length === 0) {
        return <EmptyItemList />;
    }

    const download_buttons: IButtonMenuItem[] = [
        { title: "Excel", onClick: () => downloadItems("excel") },
        { title: "JSON", onClick: () => downloadItems("json") },
    ];
    const has_selected_items = selected_items.size > 0;
    let selected_template_uuid: string | undefined = undefined;
    let all_selected_items_same_template = true;
    for (const selected_item of selected_items) {
        const item = items.find(item => item.uuid === selected_item);
        if (item === undefined) { continue; }
        if (selected_template_uuid === undefined) {
            selected_template_uuid = item.template_uuid;
        } else if (item.template_uuid !== selected_template_uuid) {
            all_selected_items_same_template = false;
            break;
        }
    }

    // get list of templates, sort by name
    const template_map = new Map<string, { uuid: string, name: string }>();
    for (const item of filtered_items) {
        template_map.set(item.template_uuid, { uuid: item.template_uuid, name: item.template_name });
    }
    const template_menu_items: IDropdownMenuItem[] = Array.from(template_map.values())
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ uuid, name }) => ({
            title: name,
            onClick: () => addFilter({ type: "template", value: name, uuid }),
        }));

    const org_menu_items: IDropdownMenuItem[] = memberships.map(({ org }) => ({
        title: org.name,
        onClick: () => addFilter({ type: "org", value: org.name, uuid: org.uuid }),
    }));

    const is_select_all = selected_items.size === filtered_items.length &&
        filtered_items.every(item => selected_items.has(item.uuid));

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}
        onScroll={handleScroll}>
        <div className="h-20 w-full bg-white border-b border-b-sea_blue-700">
            <div className="px-10 py-4 flex flex-row items-center max-w-5xl">
                <div className="pl-4 flex flex-col justify-start gap-1 overflow-hidden text-ellipsis">
                    <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-2xl sm:tracking-tight">
                        Results
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">
                        <span>&nbsp;</span>
                    </h2>
                </div>
                <div className="flex-grow" />
                <div className="flex flex-col items-end">
                    <div className="flex flex-row items-center">
                        <Button icon={hi.ArrowPathIcon} tooltip="Refresh" onClick={refresh} />
                        <ButtonMenu title="Download" items={download_buttons} disabled={!has_selected_items || !all_selected_items_same_template} />
                        <Button icon={hi.TrashIcon} onClick={() => setShowConfirm(true)} disabled={!allow_remove || !has_selected_items} />
                        <Button highlight={true} text="New Job" href="/extraction/new" />
                    </div>
                    <div className="p-2 flex flex-row gap-x-2 items-center text-gray-400 text-xs whitespace-nowrap">
                        {!has_selected_items && <span>&nbsp;</span>}
                        {has_selected_items && all_selected_items_same_template && <span>{selected_items.size} items selected</span>}
                        {has_selected_items && !all_selected_items_same_template && <span className="text-red-400">You can download only items with the same template</span>}
                    </div>
                </div>
            </div>
            <ConfirmModal open={show_confirm}
                title="Remove extractions"
                message={[`Are you sure you want to remove ${selected_items.size} extractions?`]}
                confirm="Remove"
                onClose={onRemoveClose} />
        </div>
        <div className="max-w-5xl">
            <div className="px-10 pt-8 text-sm text-gray-600">
                All your results are listed below. Click their <span className="font-bold">Name</span> to view details. Select the one(s) you want to download or remove.
            </div>
            <div className="px-10 py-4">
                <RunningJobs running_jobs={running_jobs} />
                <div className="flex flex-row items-center pb-4 gap-x-4">
                    <div className="flex-grow pr-10">
                        <MultiselectInputField
                            values={filters.map(filter => filter.value ?? "")}
                            placeholder={is_admin ? "Filter by name, template, or organization" : "Filter by name or template"}
                            separator="space"
                            onAdd={() => setIsFilterDialogOpen(true)}
                            onClick={(idx) => removeFilter(idx)}
                            onRemove={(idx) => removeFilter(idx)} />
                    </div>
                    <DropdownMenu title="Templates" items={template_menu_items} align="right" />
                    {is_admin && <DropdownMenu title="Organizations" items={org_menu_items} align="right" />}
                    <TextboxModal
                        open={is_filter_dialog_open}
                        title="Add filter by name"
                        init_text={""}
                        cancel="Cancel"
                        confirm="Add"
                        validate={(text) => text.length > 0}
                        onClose={onFilterDialogClose} />
                </div>
                <div className="rounded-lg overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                    <table className="w-full divide-y divide-gray-300 table-fixed">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="hidden md:table-cell w-12 px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"></th>
                                <th className="w-12 pl-3 pr-2 py-4 text-xs cursor-pointer font-semibold text-gray-900">
                                    <Checkbox checked={is_select_all} setChecked={toggleSelectAll} />
                                </th>
                                <th className="w-[55%] text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900">Name</th>
                                <th className="w-[35%] text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"></th>
                                <th className="hidden lg:table-cell w-32 text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"></th>
                                <th className="hidden md:table-cell w-40 text-right px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"></th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {filtered_items.map((item, idx) => (
                                <tr key={item.uuid} className="hover:bg-sea_blue-100 cursor-pointer hover:rounded-md">
                                    <td className="hidden md:table-cell text-center pl-3 py-4 text-xs cursor-pointer text-gray-400" onClick={(e) => onItemSelected(item, e)}>{idx + 1}</td>
                                    <td className="text-center pl-3 pr-2 py-4 text-xs cursor-pointer text-gray-400" onClick={() => toggleItemSelection(item.uuid)}>
                                        <Checkbox checked={selected_items.has(item.uuid)} setChecked={() => toggleItemSelection(item.uuid)} />
                                    </td>
                                    <td className="p-3 text-sm text-gray-900" onClick={(e) => onItemSelected(item, e)}>
                                        <div className="flex flex-row items-center">
                                            <div className={classNames("pl-1 border-l-4 flex-shrink-0",
                                                getStatus(item) === EXTRACT_CONFIRMATION_STATUS.pending ?
                                                    "border-yellow-400" :
                                                    getStatus(item) === EXTRACT_CONFIRMATION_STATUS.confirmed ?
                                                        "border-mint-400" :
                                                        getStatus(item) === EXTRACT_CONFIRMATION_STATUS.rejected ?
                                                            "border-red-400" :
                                                            "border-gray-400")}>
                                                &nbsp;
                                            </div>
                                            <span className="ml-1 truncate">{item.name}</span>
                                        </div>
                                    </td>
                                    <td className="p-3 text-sm text-gray-400" onClick={(e) => onItemSelected(item, e)}>
                                        <div className="truncate">{item.template_name}</div>
                                    </td>
                                    <td className="hidden lg:table-cell p-3 text-sm text-gray-400 whitespace-nowrap" onClick={(e) => onItemSelected(item, e)}>
                                        {prettySmartDateTime(item.created_at)}
                                    </td>
                                    <td className="hidden md:flex p-3 justify-end" onClick={(e) => onItemSelected(item, e)}>
                                        {org_map.has(item.template_org_uuid) && <OrgPill
                                            name={org_map.get(item.template_org_uuid)?.name ?? ""}
                                            type={org_map.get(item.template_org_uuid)?.type ?? ORG_TYPES.personal} />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {is_loading_more && <div className="flex justify-center items-center h-screen w-full">
                <LoadingSpinnerLimit />
            </div>}
        </div >
    </div>;
}