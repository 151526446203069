import * as t from "./types";
import {
    ContextDatatype,
    ContextFieldConditionType,
    ContextType,
    EmailType,
    EndpointType,
    ExtractConfirmationStatus,
    ExtractJobEventStatus,
    ExtractJobEventType,
    ExtractJobStatus,
    ExtractJobType,
    IPreprocessParams,
    OnboardingStep,
    OrgAuditType,
    OrgRole,
    OrgType,
    TransactionType,
    UserAuditType,
    UserRole
} from "./backend/extractions.types.generated";

export type IConsts = Record<string, string>;

///////////////////////////////////////////////////////
// GENERAL CONSTS

export const ENVS: Record<string, Env> = {
    dev: "dev",
    main: "main",
    prod: "prod",
    staging: "staging"
};
export type Env = "prod" | "staging" | "main" | "dev";

///////////////////////////////////////////////////////
// USER CONSTS
export const ORG_TYPES: Record<string, OrgType> = {
    personal: "personal",
    business: "business"
};
export const org_types: string[] = Object.keys(ORG_TYPES).map((k) => ORG_TYPES[k]);

export const ORG_ROLES: Record<string, OrgRole> = {
    admin: "admin",
    editor: "editor",
    member: "member"
};
export const org_roles: string[] = Object.keys(ORG_ROLES).map((k) => ORG_ROLES[k]);

export const USER_ROLES: Record<string, UserRole> = {
    admin: "admin",
    demo: "demo",
    enterprise: "enterprise",
    professional: "professional",
    professional_plus: "professional_plus",
    free: "free"
};
export const user_roles: string[] = Object.keys(USER_ROLES).map((k) => USER_ROLES[k]);

///////////////////////////////////////////////////////
// CONTEXT CONSTS

export const CONTEXT_TYPES: Record<string, ContextType> = {
    array: "array",
    object: "object",
    hierarchical: "hierarchical",
    lookup_table: "lookup_table",
    classifier: "classifier"
};
export const context_types: string[] = Object.keys(CONTEXT_TYPES).map((k) => CONTEXT_TYPES[k]);

export const CONTEXT_DATATYPES: Record<string, ContextDatatype> = {
    string: "string",
    number: "number",
    enum: "enum"
};
export const context_datatypes: string[] = Object.keys(CONTEXT_DATATYPES).map((k) => CONTEXT_DATATYPES[k]);

export const CONTEXT_FACT_ENTITY: IConsts = {
    sender: "CONTEXT_FACT_EMAIL_SENDER",
    recipient: "CONTEXT_FACT_EMAIL_RECIPIENT"
}
export const CONTEXT_FACT_ENTITIES: string[] = Object.keys(CONTEXT_FACT_ENTITY).map((k) => CONTEXT_FACT_ENTITY[k]);

export const CONTEXT_FIELD_CONDITION_TYPE: Record<string, ContextFieldConditionType> = {
    only_with: "only_with",
    skip_if_match: "skip_if_match",
    skip_if_not_match: "skip_if_not_match",
    skip_if_includes: "skip_if_includes",
    skip_if_not_includes_any: "skip_if_not_includes_any"
};

///////////////////////////////////////////////////////
// TEMPLATE EVALUATION

export const TEMPLATE_EXAMPLE_TAGS = {
    new_example: "new_example",
    in_progress: "in_progress",
    fixed: "fixed",
    does_not_work: "does_not_work",
    will_not_fix: "will_not_fix"
};

export const template_example_tags: string[] = Object.values(TEMPLATE_EXAMPLE_TAGS);

///////////////////////////////////////////////////////
// PREPROCESSING DEFAULT PARAMS

export const DEFAULT_PREPROCESS_PARAMS: IPreprocessParams = {
    preprocess_excel_strategy: "without_col_names",
    preprocess_ocr_strategy: "fix_rotation",
    orientation_segments_strategy: "only_main",
    preprocess_ocr_table_strategy: "plain_text_only"
};

///////////////////////////////////////////////////////
// ENDPOINT CONSTS

export const ENDPOINT_TYPE: Record<string, EndpointType> = {
    email: "email",
    rest_api: "rest_api"
};
export const endpoint_types: string[] = Object.keys(ENDPOINT_TYPE).map((k) => ENDPOINT_TYPE[k]);

///////////////////////////////////////////////////////
// ONBOARDING CONSTS
export const ONBOARDING_STEPS: Record<string, OnboardingStep> = {
    new_account: "new_account",
    welcome: "welcome",
    context: "context",
    scrape: "scrape",
    email: "email",
    download: "download",
    feedback: "feedback",
    done: "done"
};
export const onboarding_steps: string[] = Object.keys(ONBOARDING_STEPS).map((k) => ONBOARDING_STEPS[k]);

///////////////////////////////////////////////////////
// EMAIL CONSTS

export const EMAIL_TYPES: Record<string, EmailType> = {
    terms_of_service: "terms_of_service",
};
export const email_types: string[] = Object.keys(EMAIL_TYPES).map((k) => EMAIL_TYPES[k]);

///////////////////////////////////////////////////////
// CREDITS
export const TRANSACTION_TYPE: Record<string, TransactionType> = {
    purchase: "purchase",
    refund: "refund",
    usage: "usage",
    subscription: "subscription",
    expiration: "expiration",
    free: "free"
};

// maximal number of pages or sheets processed as separate units in a document
export const MAX_PAGE_SHEET_UNITS = 200;

///////////////////////////////////////////////////////
// AI CONSTS

// DECIMAL SEPARATOR
export const DEFAULT_DECIMAL_SEPARATOR = ",";

///////////////////////////////////////////////////////
// PROCESSING JOB CONSTS
export const EXTRACT_JOB_TYPE: Record<string, ExtractJobType> = {
    suggest: "suggest",
    email: "email",
    api: "api",
    web: "web",
    eval: "eval",
    lookup_table_update: "lookup_table_update",
    lookup_table_get: "lookup_table_get"
};
export const extract_job_types: string[] = Object.keys(EXTRACT_JOB_TYPE).map((k) => EXTRACT_JOB_TYPE[k]);

export const EXTRACT_JOB_STATUS: Record<string, ExtractJobStatus> = {
    pending: "pending",
    running: "running",
    error: "error",
    done: "done"
};
export const extract_job_statuses: string[] = Object.keys(EXTRACT_JOB_STATUS).map((k) => EXTRACT_JOB_STATUS[k]);

export const EXTRACT_JOB_EVENT_TYPE: Record<string, ExtractJobEventType> = {
    attachment: "attachment",
    to_email: "to_email",
    reply: "reply",
    passthrough: "passthrough",
    email_process: "email_process",
    document_process: "document_process",
    eval_scrape: "eval_scrape",
    webhook: "webhook",
    ip_check: "ip_check",
    api_key_check: "api_key_check",
    verify: "verify",
    error: "error",
    suggest_ct: "suggest_ct",
    detect_sep: "detect_sep",
    detect_wizard_template: "detect_wizard_template",
    lookup_table_update: "lookup_table_update",
    confirmation: "confirmation",
    classify: "classify"
};

export const EXTRACT_JOB_EVENT_STATUS: Record<string, ExtractJobEventStatus> = {
    start: "start",
    milestone: "milestone",
    end: "end",
    error: "error"
};

///////////////////////////////////////////////////////
// EXTRACT CONFIRMATION CONSTS

export const EXTRACT_CONFIRMATION_STATUS: Record<string, ExtractConfirmationStatus> = {
    none: "none",
    pending: "pending",
    confirmed: "confirmed",
    rejected: "rejected",
    retry: "retry"
};

export const CONFIRMATION_LOG_LOOKUP_TABLE_HEADER_NAMES = [
    "endpoint id",
    "template id",
    "job id",
    "item id",
    "created at",
    "stage",
    "validation result"
];

///////////////////////////////////////////////////////
// AUDIT CONSTS

export const USER_AUDIT_TYPE: Record<string, UserAuditType> = {
    register: "register",
    password_reset: "password_reset",
    password_change: "password_change",
    email_change: "email_change",
    role_change: "role_change",
    org_add: "org_add",
    org_remove: "org_remove",
    context_download: "context_download",
    item_download: "item_download",
    api_key_create: "api_key_create",
    api_key_delete: "api_key_delete",
    api_key_view: "api_key_view",
    debug_mode: "debug_mode"
};

export const ORG_AUDIT_TYPE: Record<string, OrgAuditType> = {
    create: "create",
    add_member: "add_member",
    remove_member: "remove_member",
    context_download: "context_download",
    item_download: "item_download",
    api_key_create: "api_key_create",
    api_key_delete: "api_key_delete",
    api_key_view: "api_key_view"
};

///////////////////////////////////////////////////////
// FILE CONSTS

export const FILE_EXTENSIONS_TO_MIME_TYPES: Record<string, string> = {
    "pdf": "application/pdf",
    "jpeg": "image/jpeg",
    "jpg": "image/jpeg",
    "png": "image/png",
    "tiff": "image/tiff",
    "bmp": "image/bmp",
    "gif": "image/gif",
    "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "xls": "application/vnd.ms-excel",
    "ods": "application/vnd.oasis.opendocument.spreadsheet",
    "txt": "text/plain",
    "json": "application/json",
    "csv": "text/csv",
    "html": "text/html",
    "xml": "text/xml",
    "tsv": "text/tab-separated-values",
    // "doc": "application/msword",
    // "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
};

export const IMAGE_MIME_TYPES = [
    "image/jpeg",
    "image/png",
    "image/tiff",
    "image/bmp",
    "image/gif",
];

export const PDF_MIME_TYPE = "application/pdf";

export const WORD_MIME_TYPES = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];

export const OCR_MIME_TYPES = [
    PDF_MIME_TYPE,
    ...IMAGE_MIME_TYPES,
    // ...WORD_MIME_TYPES
];

export const EXCEL_MIME_TYPES = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.oasis.opendocument.spreadsheet"
];

export const SIMPLE_MIME_TYPES = [
    "text/plain",
    "application/json",
    "text/csv",
    "text/html",
    "text/xml",
    "application/xml",
    "text/tab-separated-values"
];

///////////////////////////////////////////////////////
// TEMP CONSTS TO BE MOVED TO DATABASE
export const industry_and_roles: t.IIndustryAndRoles = {
    "Administration & Office": [
        "Administrative Assistant",
        "Receptionist",
        "Office Manager",
        "Executive Assistant",
        "Operations Manager",
        "Data Entry Clerk",
        "Other"
    ],
    "Sales & Marketing": [
        "Sales Representative",
        "Account Manager",
        "Sales Manager",
        "Marketing Coordinator",
        "Marketing Manager",
        "Digital Marketing Specialist",
        "Brand Manager",
        "SEO Specialist",
        "Content Manager",
        "Other"
    ],
    "Finance & Accounting": [
        "Accountant",
        "Financial Analyst",
        "Chief Financial Officer (CFO)",
        "Bookkeeper",
        "Payroll Specialist",
        "Auditor",
        "Tax Consultant",
        "Other"
    ],
    "IT & Technology": [
        "Software Developer",
        "IT Support Specialist",
        "Systems Administrator",
        "Network Engineer",
        "Data Scientist",
        "Product Manager",
        "UX/UI Designer",
        "Other"
    ],
    "Human Resources": [
        "HR Specialist",
        "HR Manager",
        "Recruiter",
        "Talent Acquisition Specialist",
        "Training and Development Manager",
        "Compensation and Benefits Specialist",
        "Other"
    ],
    "Healthcare": [
        "Registered Nurse",
        "Doctor",
        "Pharmacist",
        "Medical Assistant",
        "Laboratory Technician",
        "Therapist",
        "Other"
    ],
    "Engineering & Manufacturing": [
        "Mechanical Engineer",
        "Electrical Engineer",
        "Civil Engineer",
        "Production Manager",
        "Quality Control Specialist",
        "Chemical Engineer",
        "Other"
    ],
    "Education & Training": [
        "Teacher",
        "Professor",
        "Academic Advisor",
        "Curriculum Developer",
        "School Counselor",
        "Librarian",
        "Other"
    ],
    "Art & Design": [
        "Graphic Designer",
        "Interior Designer",
        "Photographer",
        "Art Director",
        "Illustrator",
        "Animator",
        "Other"
    ],
    "Legal": [
        "Lawyer",
        "Paralegal",
        "Legal Secretary",
        "Judge",
        "Legal Consultant",
        "Other"
    ],
    "Real Estate & Construction": [
        "Real Estate Agent",
        "Property Manager",
        "Architect",
        "Urban Planner",
        "Construction Manager",
        "Other"
    ],
    "Science & Research": [
        "Research Scientist",
        "Lab Technician",
        "Field Researcher",
        "Environmental Scientist",
        "Other"
    ],
    "Hospitality & Tourism": [
        "Hotel Manager",
        "Chef",
        "Event Planner",
        "Tour Guide",
        "Flight Attendant",
        "Other"
    ],
    "Agriculture & Environment": [
        "Farmer",
        "Agricultural Technician",
        "Environmental Consultant",
        "Forester",
        "Other"
    ],
    "Media & Communications": [
        "Journalist",
        "Editor",
        "Public Relations Specialist",
        "Communications Manager",
        "TV Producer",
        "Other"
    ],
    "Transportation & Logistics": [
        "Logistics Manager",
        "Supply Chain Specialist",
        "Pilot",
        "Train Operator",
        "Truck Driver",
        "Other"
    ],
    "Other": [
        "Other"
    ]
};

export const countries: t.ICountry[] = [
    {
        "code": "af",
        "name": "Afghanistan"
    },
    {
        "code": "ax",
        "name": "Åland Islands"
    },
    {
        "code": "al",
        "name": "Albania"
    },
    {
        "code": "dz",
        "name": "Algeria"
    },
    {
        "code": "as",
        "name": "American Samoa"
    },
    {
        "code": "ad",
        "name": "Andorra"
    },
    {
        "code": "ao",
        "name": "Angola"
    },
    {
        "code": "ai",
        "name": "Anguilla"
    },
    {
        "code": "aq",
        "name": "Antarctica"
    },
    {
        "code": "ag",
        "name": "Antigua and Barbuda"
    },
    {
        "code": "ar",
        "name": "Argentina"
    },
    {
        "code": "am",
        "name": "Armenia"
    },
    {
        "code": "aw",
        "name": "Aruba"
    },
    {
        "code": "au",
        "name": "Australia"
    },
    {
        "code": "at",
        "name": "Austria"
    },
    {
        "code": "az",
        "name": "Azerbaijan"
    },
    {
        "code": "bs",
        "name": "Bahamas"
    },
    {
        "code": "bh",
        "name": "Bahrain"
    },
    {
        "code": "bd",
        "name": "Bangladesh"
    },
    {
        "code": "bb",
        "name": "Barbados"
    },
    {
        "code": "by",
        "name": "Belarus"
    },
    {
        "code": "be",
        "name": "Belgium"
    },
    {
        "code": "bz",
        "name": "Belize"
    },
    {
        "code": "bj",
        "name": "Benin"
    },
    {
        "code": "bm",
        "name": "Bermuda"
    },
    {
        "code": "bt",
        "name": "Bhutan"
    },
    {
        "code": "bo",
        "name": "Bolivia (Plurinational State of)"
    },
    {
        "code": "bq",
        "name": "Bonaire, Sint Eustatius and Saba"
    },
    {
        "code": "ba",
        "name": "Bosnia and Herzegovina"
    },
    {
        "code": "bw",
        "name": "Botswana"
    },
    {
        "code": "bv",
        "name": "Bouvet Island"
    },
    {
        "code": "br",
        "name": "Brazil"
    },
    {
        "code": "io",
        "name": "British Indian Ocean Territory"
    },
    {
        "code": "bn",
        "name": "Brunei Darussalam"
    },
    {
        "code": "bg",
        "name": "Bulgaria"
    },
    {
        "code": "bf",
        "name": "Burkina Faso"
    },
    {
        "code": "bi",
        "name": "Burundi"
    },
    {
        "code": "kh",
        "name": "Cambodia"
    },
    {
        "code": "cm",
        "name": "Cameroon"
    },
    {
        "code": "ca",
        "name": "Canada"
    },
    {
        "code": "cv",
        "name": "Cabo Verde"
    },
    {
        "code": "ky",
        "name": "Cayman Islands"
    },
    {
        "code": "cf",
        "name": "Central African Republic"
    },
    {
        "code": "td",
        "name": "Chad"
    },
    {
        "code": "cl",
        "name": "Chile"
    },
    {
        "code": "cn",
        "name": "China"
    },
    {
        "code": "cx",
        "name": "Christmas Island"
    },
    {
        "code": "cc",
        "name": "Cocos (Keeling) Islands"
    },
    {
        "code": "co",
        "name": "Colombia"
    },
    {
        "code": "km",
        "name": "Comoros"
    },
    {
        "code": "cg",
        "name": "Congo"
    },
    {
        "code": "cd",
        "name": "Congo, Democratic Republic of the"
    },
    {
        "code": "ck",
        "name": "Cook Islands"
    },
    {
        "code": "cr",
        "name": "Costa Rica"
    },
    {
        "code": "ci",
        "name": "Côte d'Ivoire"
    },
    {
        "code": "hr",
        "name": "Croatia"
    },
    {
        "code": "cu",
        "name": "Cuba"
    },
    {
        "code": "cw",
        "name": "Curaçao"
    },
    {
        "code": "cy",
        "name": "Cyprus"
    },
    {
        "code": "cz",
        "name": "Czechia"
    },
    {
        "code": "dk",
        "name": "Denmark"
    },
    {
        "code": "dj",
        "name": "Djibouti"
    },
    {
        "code": "dm",
        "name": "Dominica"
    },
    {
        "code": "do",
        "name": "Dominican Republic"
    },
    {
        "code": "ec",
        "name": "Ecuador"
    },
    {
        "code": "eg",
        "name": "Egypt"
    },
    {
        "code": "sv",
        "name": "El Salvador"
    },
    {
        "code": "gq",
        "name": "Equatorial Guinea"
    },
    {
        "code": "er",
        "name": "Eritrea"
    },
    {
        "code": "ee",
        "name": "Estonia"
    },
    {
        "code": "et",
        "name": "Ethiopia"
    },
    {
        "code": "fk",
        "name": "Falkland Islands (Malvinas)"
    },
    {
        "code": "fo",
        "name": "Faroe Islands"
    },
    {
        "code": "fj",
        "name": "Fiji"
    },
    {
        "code": "fi",
        "name": "Finland"
    },
    {
        "code": "fr",
        "name": "France"
    },
    {
        "code": "gf",
        "name": "French Guiana"
    },
    {
        "code": "pf",
        "name": "French Polynesia"
    },
    {
        "code": "tf",
        "name": "French Southern Territories"
    },
    {
        "code": "ga",
        "name": "Gabon"
    },
    {
        "code": "gm",
        "name": "Gambia"
    },
    {
        "code": "ge",
        "name": "Georgia"
    },
    {
        "code": "de",
        "name": "Germany"
    },
    {
        "code": "gh",
        "name": "Ghana"
    },
    {
        "code": "gi",
        "name": "Gibraltar"
    },
    {
        "code": "gr",
        "name": "Greece"
    },
    {
        "code": "gl",
        "name": "Greenland"
    },
    {
        "code": "gd",
        "name": "Grenada"
    },
    {
        "code": "gp",
        "name": "Guadeloupe"
    },
    {
        "code": "gu",
        "name": "Guam"
    },
    {
        "code": "gt",
        "name": "Guatemala"
    },
    {
        "code": "gg",
        "name": "Guernsey"
    },
    {
        "code": "gn",
        "name": "Guinea"
    },
    {
        "code": "gw",
        "name": "Guinea-Bissau"
    },
    {
        "code": "gy",
        "name": "Guyana"
    },
    {
        "code": "ht",
        "name": "Haiti"
    },
    {
        "code": "hm",
        "name": "Heard Island and McDonald Islands"
    },
    {
        "code": "va",
        "name": "Holy See"
    },
    {
        "code": "hn",
        "name": "Honduras"
    },
    {
        "code": "hk",
        "name": "Hong Kong"
    },
    {
        "code": "hu",
        "name": "Hungary"
    },
    {
        "code": "is",
        "name": "Iceland"
    },
    {
        "code": "in",
        "name": "India"
    },
    {
        "code": "id",
        "name": "Indonesia"
    },
    {
        "code": "ir",
        "name": "Iran (Islamic Republic of)"
    },
    {
        "code": "iq",
        "name": "Iraq"
    },
    {
        "code": "ie",
        "name": "Ireland"
    },
    {
        "code": "im",
        "name": "Isle of Man"
    },
    {
        "code": "il",
        "name": "Israel"
    },
    {
        "code": "it",
        "name": "Italy"
    },
    {
        "code": "jm",
        "name": "Jamaica"
    },
    {
        "code": "jp",
        "name": "Japan"
    },
    {
        "code": "je",
        "name": "Jersey"
    },
    {
        "code": "jo",
        "name": "Jordan"
    },
    {
        "code": "kz",
        "name": "Kazakhstan"
    },
    {
        "code": "ke",
        "name": "Kenya"
    },
    {
        "code": "ki",
        "name": "Kiribati"
    },
    {
        "code": "kp",
        "name": "Korea (Democratic People's Republic of)"
    },
    {
        "code": "kr",
        "name": "Korea, Republic of"
    },
    {
        "code": "kw",
        "name": "Kuwait"
    },
    {
        "code": "kg",
        "name": "Kyrgyzstan"
    },
    {
        "code": "la",
        "name": "Lao People's Democratic Republic"
    },
    {
        "code": "lv",
        "name": "Latvia"
    },
    {
        "code": "lb",
        "name": "Lebanon"
    },
    {
        "code": "ls",
        "name": "Lesotho"
    },
    {
        "code": "lr",
        "name": "Liberia"
    },
    {
        "code": "ly",
        "name": "Libya"
    },
    {
        "code": "li",
        "name": "Liechtenstein"
    },
    {
        "code": "lt",
        "name": "Lithuania"
    },
    {
        "code": "lu",
        "name": "Luxembourg"
    },
    {
        "code": "mo",
        "name": "Macao"
    },
    {
        "code": "mk",
        "name": "North Macedonia"
    },
    {
        "code": "mg",
        "name": "Madagascar"
    },
    {
        "code": "mw",
        "name": "Malawi"
    },
    {
        "code": "my",
        "name": "Malaysia"
    },
    {
        "code": "mv",
        "name": "Maldives"
    },
    {
        "code": "ml",
        "name": "Mali"
    },
    {
        "code": "mt",
        "name": "Malta"
    },
    {
        "code": "mh",
        "name": "Marshall Islands"
    },
    {
        "code": "mq",
        "name": "Martinique"
    },
    {
        "code": "mr",
        "name": "Mauritania"
    },
    {
        "code": "mu",
        "name": "Mauritius"
    },
    {
        "code": "yt",
        "name": "Mayotte"
    },
    {
        "code": "mx",
        "name": "Mexico"
    },
    {
        "code": "fm",
        "name": "Micronesia (Federated States of)"
    },
    {
        "code": "ma",
        "name": "Morocco"
    },
    {
        "code": "md",
        "name": "Moldova, Republic of"
    },
    {
        "code": "mc",
        "name": "Monaco"
    },
    {
        "code": "mn",
        "name": "Mongolia"
    },
    {
        "code": "me",
        "name": "Montenegro"
    },
    {
        "code": "ms",
        "name": "Montserrat"
    },
    {
        "code": "mz",
        "name": "Mozambique"
    },
    {
        "code": "mm",
        "name": "Myanmar"
    },
    {
        "code": "na",
        "name": "Namibia"
    },
    {
        "code": "nr",
        "name": "Nauru"
    },
    {
        "code": "np",
        "name": "Nepal"
    },
    {
        "code": "nl",
        "name": "Netherlands"
    },
    {
        "code": "nc",
        "name": "New Caledonia"
    },
    {
        "code": "nz",
        "name": "New Zealand"
    },
    {
        "code": "ni",
        "name": "Nicaragua"
    },
    {
        "code": "ne",
        "name": "Niger"
    },
    {
        "code": "ng",
        "name": "Nigeria"
    },
    {
        "code": "nu",
        "name": "Niue"
    },
    {
        "code": "nf",
        "name": "Norfolk Island"
    },
    {
        "code": "mp",
        "name": "Northern Mariana Islands"
    },
    {
        "code": "no",
        "name": "Norway"
    },
    {
        "code": "om",
        "name": "Oman"
    },
    {
        "code": "pk",
        "name": "Pakistan"
    },
    {
        "code": "pw",
        "name": "Palau"
    },
    {
        "code": "ps",
        "name": "Palestine, State of"
    },
    {
        "code": "pa",
        "name": "Panama"
    },
    {
        "code": "pg",
        "name": "Papua New Guinea"
    },
    {
        "code": "py",
        "name": "Paraguay"
    },
    {
        "code": "pe",
        "name": "Peru"
    },
    {
        "code": "ph",
        "name": "Philippines"
    },
    {
        "code": "pn",
        "name": "Pitcairn"
    },
    {
        "code": "pl",
        "name": "Poland"
    },
    {
        "code": "pt",
        "name": "Portugal"
    },
    {
        "code": "pr",
        "name": "Puerto Rico"
    },
    {
        "code": "qa",
        "name": "Qatar"
    },
    {
        "code": "re",
        "name": "Réunion"
    },
    {
        "code": "ro",
        "name": "Romania"
    },
    {
        "code": "ru",
        "name": "Russian Federation"
    },
    {
        "code": "rw",
        "name": "Rwanda"
    },
    {
        "code": "bl",
        "name": "Saint Barthélemy"
    },
    {
        "code": "sh",
        "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
        "code": "kn",
        "name": "Saint Kitts and Nevis"
    },
    {
        "code": "lc",
        "name": "Saint Lucia"
    },
    {
        "code": "mf",
        "name": "Saint Martin (French part)"
    },
    {
        "code": "pm",
        "name": "Saint Pierre and Miquelon"
    },
    {
        "code": "vc",
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "code": "ws",
        "name": "Samoa"
    },
    {
        "code": "sm",
        "name": "San Marino"
    },
    {
        "code": "st",
        "name": "Sao Tome and Principe"
    },
    {
        "code": "sa",
        "name": "Saudi Arabia"
    },
    {
        "code": "sn",
        "name": "Senegal"
    },
    {
        "code": "rs",
        "name": "Serbia"
    },
    {
        "code": "sc",
        "name": "Seychelles"
    },
    {
        "code": "sl",
        "name": "Sierra Leone"
    },
    {
        "code": "sg",
        "name": "Singapore"
    },
    {
        "code": "sx",
        "name": "Sint Maarten (Dutch part)"
    },
    {
        "code": "sk",
        "name": "Slovakia"
    },
    {
        "code": "si",
        "name": "Slovenia"
    },
    {
        "code": "sb",
        "name": "Solomon Islands"
    },
    {
        "code": "so",
        "name": "Somalia"
    },
    {
        "code": "za",
        "name": "South Africa"
    },
    {
        "code": "gs",
        "name": "South Georgia and the South Sandwich Islands"
    },
    {
        "code": "ss",
        "name": "South Sudan"
    },
    {
        "code": "es",
        "name": "Spain"
    },
    {
        "code": "lk",
        "name": "Sri Lanka"
    },
    {
        "code": "sd",
        "name": "Sudan"
    },
    {
        "code": "sr",
        "name": "Suriname"
    },
    {
        "code": "sj",
        "name": "Svalbard and Jan Mayen"
    },
    {
        "code": "sz",
        "name": "Eswatini"
    },
    {
        "code": "se",
        "name": "Sweden"
    },
    {
        "code": "ch",
        "name": "Switzerland"
    },
    {
        "code": "sy",
        "name": "Syrian Arab Republic"
    },
    {
        "code": "tw",
        "name": "Taiwan, Province of China"
    },
    {
        "code": "tj",
        "name": "Tajikistan"
    },
    {
        "code": "tz",
        "name": "Tanzania, United Republic of"
    },
    {
        "code": "th",
        "name": "Thailand"
    },
    {
        "code": "tl",
        "name": "Timor-Leste"
    },
    {
        "code": "tg",
        "name": "Togo"
    },
    {
        "code": "tk",
        "name": "Tokelau"
    },
    {
        "code": "to",
        "name": "Tonga"
    },
    {
        "code": "tt",
        "name": "Trinidad and Tobago"
    },
    {
        "code": "tn",
        "name": "Tunisia"
    },
    {
        "code": "tr",
        "name": "Türkiye"
    },
    {
        "code": "tm",
        "name": "Turkmenistan"
    },
    {
        "code": "tc",
        "name": "Turks and Caicos Islands"
    },
    {
        "code": "tv",
        "name": "Tuvalu"
    },
    {
        "code": "ug",
        "name": "Uganda"
    },
    {
        "code": "ua",
        "name": "Ukraine"
    },
    {
        "code": "ae",
        "name": "United Arab Emirates"
    },
    {
        "code": "gb",
        "name": "United Kingdom of Great Britain and Northern Ireland"
    },
    {
        "code": "us",
        "name": "United States of America"
    },
    {
        "code": "um",
        "name": "United States Minor Outlying Islands"
    },
    {
        "code": "uy",
        "name": "Uruguay"
    },
    {
        "code": "uz",
        "name": "Uzbekistan"
    },
    {
        "code": "vu",
        "name": "Vanuatu"
    },
    {
        "code": "ve",
        "name": "Venezuela (Bolivarian Republic of)"
    },
    {
        "code": "vn",
        "name": "Viet Nam"
    },
    {
        "code": "vg",
        "name": "Virgin Islands (British)"
    },
    {
        "code": "vi",
        "name": "Virgin Islands (U.S.)"
    },
    {
        "code": "wf",
        "name": "Wallis and Futuna"
    },
    {
        "code": "eh",
        "name": "Western Sahara"
    },
    {
        "code": "ye",
        "name": "Yemen"
    },
    {
        "code": "zm",
        "name": "Zambia"
    },
    {
        "code": "zw",
        "name": "Zimbabwe"
    }
];

export const company_sizes: string[] = [
    "1-10",
    "11-50",
    "51-200",
    "201-500",
    "501-1000",
    "1001-5000",
    "5001-10000",
    "10001+"
];

export const team_sizes: string[] = [
    "1-4",
    "5-10",
    "11-20",
    "21-50",
    "51-100",
    "101+"
];

export const languages: string[] = [
    "Bulgarian",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "Estonian",
    "Finnish",
    "French",
    "German",
    "Greek",
    "Hungarian",
    "Irish",
    "Italian",
    "Latvian",
    "Lithuanian",
    "Maltese",
    "Polish",
    "Portuguese",
    "Romanian",
    "Slovak",
    "Slovenian",
    "Spanish",
    "Swedish"
];