
type CheckboxProps = {
    checked: boolean,
    setChecked: (checked: boolean) => void,
    disabled?: boolean,
    id?: string
}

export function Checkbox(props: CheckboxProps) {
    const { checked, setChecked, disabled, id } = props;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // ensure components under do not get the event
        e.stopPropagation();
        // set the checked state
        setChecked(e.target.checked);
    }

    return (
        <input
            id={id}
            aria-describedby={id}
            name={id}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-space_blue-600 focus:ring-space_blue-600 disabled:text-gray-200"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
        />)
}