import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";

import { saveAs } from "file-saver";

import {
    ArrowLeftIcon,
    ArrowUpTrayIcon,
    CheckIcon,
    EyeIcon,
    FunnelIcon,
    PencilSquareIcon,
    PlusCircleIcon,
    PlusIcon,
    TrashIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import {
    BrowserIcon,
    FileCodeIcon,
    MarkGithubIcon
} from "@primer/octicons-react";
import { TableCellsIcon } from "@heroicons/react/20/solid";

import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectSelfUrl,
    selectUser
} from "../lib/scraper.slice";
import {
    IEndpointToLookupTable,
    ILookupTable,
    ILookupTableRowSlim
} from "../lib/types";
import {
    Backend,
    BackendObj
} from "../lib/backend";
import {
    classNames,
    escapeCsvField,
    getExcelColumnName,
    prettyDateTime,
    setDocumentTitle
} from "../lib/utils";
import {
    IChart,
    IWidget,
    IWidgetDateCountChartDetails,
    IWidgetDateDistributionChartDetails,
    IWidgetDateTagsChartDetails,
    IWidgetDetails,
    IWidgetFilter,
    IWidgetTagsChartDetails,
    WidgetType
} from "../lib/backend/extractions.types.generated";

import { ITab, Tabs } from "../components/Tabs";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ORG_TYPES } from "../lib/consts";
import { Button, ButtonGroup } from "../components/Button";
import { OrgPill } from "../components/OrgPill";
import { CopyTextbox } from "../components/CopyTextbox";
import { Sheet, SheetEditor } from "../components/Sheets";
import { Pill } from "../components/Pill";
import { Chart } from "../components/Chart";
import { ConfirmModal } from "../components/ConfirmModal";
import { EndpointToLookupTable } from "../components/EndpointToLookupTable";
import { Textbox } from "../components/Textbox";
import { Dropdown } from "../components/Dropdown";
import { SidePanelRaw } from "../components/SidePanel";
import { LongText } from "../components/LongText";

function getWidgetDetails(type: WidgetType): IWidgetDetails {
    switch (type) {
        case "date_count_chart": return { date_header_idx: 0 };
        case "date_distribution_chart": return { date_header_idx: 0, values_header_idx: 1 };
        case "date_tags_chart": return { date_header_idx: 0, tags_headers_idx: [1] };
        case "tags_chart": return { date_header_idx: 0, tags_headers_idx: [1] };
    }
}

type WidgetListProps = {
    lookup_table: ILookupTable;
}

function WidgetList(props: WidgetListProps) {
    const { lookup_table } = props;

    const memberships = useSelector(selectMemberships);
    const is_org_admin = memberships.filter((m) => m.org.uuid === lookup_table?.org_uuid && m.role === "admin").length > 0;

    const [widgets, setWidgets] = useState<IWidget[]>([]);
    const [show_side_panel, setShowSidePanel] = useState<"new_widget" | "edit_widget" | undefined>(undefined);
    const [edit_widget_uuid, setEditWidgetUuid] = useState<string | undefined>(undefined);
    const [delete_widget_uuid, setDeleteWidgetUuid] = useState<string | undefined>(undefined);
    const [new_widget_name, setNewWidgetName] = useState("");
    const [new_widget_filters, setNewWidgetFilters] = useState<IWidgetFilter[]>([]);
    const [new_widget_type, setNewWidgetType] = useState<WidgetType>("date_count_chart");
    const [new_widget_details, setNewWidgetDetails] = useState<IWidgetDetails>(getWidgetDetails("date_count_chart"));
    const [widget_chart, setWidgetChart] = useState<{ chart: IChart, title: string } | undefined>(undefined);
    const [is_saving_widget, setIsSavingWidget] = useState(false);

    useEffect(() => {
        BackendObj.extractions.listWidgets({ lookup_table_uuid: lookup_table.uuid })
            .then(({ widgets }) => { setWidgets(widgets); })
            .catch((error) => { setWidgets([]); console.error(error); });
    }, [lookup_table]);

    const openNewWidget = () => {
        setShowSidePanel("new_widget");
        setNewWidgetName("");
        setNewWidgetFilters([]);
        setNewWidgetType("date_count_chart");
        setNewWidgetDetails(getWidgetDetails("date_count_chart"));
    }

    const openEditWidget = (widget: IWidget) => {
        setShowSidePanel("edit_widget");
        setEditWidgetUuid(widget.uuid);
        setNewWidgetName(widget.title);
        setNewWidgetFilters(widget.filters);
        setNewWidgetType(widget.type);
        setNewWidgetDetails(widget.details);
    }

    const openDeleteWidget = (widget: IWidget) => {
        setDeleteWidgetUuid(widget.uuid);
    }

    const addNewWidgetFilter = () => {
        setNewWidgetFilters([...new_widget_filters, { column_idx: 0, values: [""] }]);
    }

    const updateNewWidgetFilterColumnIdx = (idx: number, value: number) => {
        const old_filter = new_widget_filters[idx];
        if (old_filter === undefined) { return; }
        old_filter.column_idx = value;
        setNewWidgetFilters([...new_widget_filters.slice(0, idx), old_filter, ...new_widget_filters.slice(idx + 1)]);
    }

    const updateNewWidgetFilterValue = (idx: number, value_idx: number, value: string) => {
        const old_filter = new_widget_filters[idx];
        if (old_filter === undefined) { return; }
        old_filter.values[value_idx] = value;
        // remove empty values
        old_filter.values = old_filter.values.filter((value) => value.trim() !== "");
        // ensure at least one value
        if (old_filter.values.length === 0) { old_filter.values.push(""); }
        setNewWidgetFilters([...new_widget_filters.slice(0, idx), old_filter, ...new_widget_filters.slice(idx + 1)]);
    }

    const addNewWidgetFilterValue = (idx: number) => {
        const old_filter = new_widget_filters[idx];
        if (old_filter === undefined) { return; }
        old_filter.values.push("");
        setNewWidgetFilters([...new_widget_filters.slice(0, idx), old_filter, ...new_widget_filters.slice(idx + 1)]);
    }

    const removeNewWidgetFilter = (idx: number) => {
        setNewWidgetFilters(new_widget_filters.filter((_, i) => i !== idx));
    }

    const updateNewWidgetType = (type: WidgetType) => {
        setNewWidgetType(type);
        setNewWidgetDetails(getWidgetDetails(type));
    }

    const updateNewWidgetTagsHeadersIdx = (tag_idx: number, new_value: string) => {
        console.log({ tag_idx, new_value });
        if (new_value === "/") {
            // remove tag header
            setNewWidgetDetails({
                ...new_widget_details,
                tags_headers_idx: (new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails))
                    .tags_headers_idx.filter((_, idx) => idx !== tag_idx)
            });
        } else {
            // update tag value
            setNewWidgetDetails({
                ...new_widget_details,
                tags_headers_idx: (new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails))
                    .tags_headers_idx.map((idx, idx_idx) => (idx_idx === tag_idx) ? parseInt(new_value) : idx)
            });
        }
    }

    const addNewWidgetTagHeaderIdx = () => {
        setNewWidgetDetails({
            ...new_widget_details,
            tags_headers_idx: [...(new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails)).tags_headers_idx, 0]
        });
    }

    const cancelNewWidget = () => {
        setShowSidePanel(undefined);
        setNewWidgetName("");
        setNewWidgetFilters([]);
        setNewWidgetType("date_count_chart");
        setNewWidgetDetails(getWidgetDetails("date_count_chart"));
    }

    const createNewWidget = async () => {
        setIsSavingWidget(true);
        setShowSidePanel(undefined);
        try {
            await BackendObj.extractions.createWidget({
                lookup_table_uuid: lookup_table.uuid,
                title: new_widget_name,
                type: new_widget_type,
                filters: new_widget_filters,
                details: new_widget_details
            });
            // refresh widgets
            const { widgets: new_widgets } = await BackendObj.extractions.listWidgets({ lookup_table_uuid: lookup_table.uuid });
            setWidgets(new_widgets);
        } catch (error) {
            console.error(error);
        }
        setIsSavingWidget(false);
    }

    const saveEditWidget = async () => {
        if (edit_widget_uuid === undefined) { return; }
        setIsSavingWidget(true);
        setShowSidePanel(undefined);
        try {
            await BackendObj.extractions.updateWidget({
                widget_uuid: edit_widget_uuid,
                title: new_widget_name,
                filters: new_widget_filters,
                details: new_widget_details
            });
            // refresh widgets
            const { widgets: new_widgets } = await BackendObj.extractions.listWidgets({ lookup_table_uuid: lookup_table.uuid });
            setWidgets(new_widgets);
        } catch (error) {
            console.error(error);
        }
        setIsSavingWidget(false);
    }

    const previewEditWidget = async (widget: IWidget) => {
        const { chart, widget: widget_data } = await BackendObj.extractions.previewWidget({ widget_uuid: widget.uuid, range: "7d" });
        setWidgetChart({ chart, title: widget_data.title });
    }

    const removeWidget = async (result: boolean) => {
        if (delete_widget_uuid === undefined) { return; }
        if (result) {
            setIsSavingWidget(true);
            try {
                await BackendObj.extractions.deleteWidget({ widget_uuid: delete_widget_uuid });
                const { widgets: new_widgets } = await BackendObj.extractions.listWidgets({ lookup_table_uuid: lookup_table.uuid });
                setWidgets(new_widgets);
            } catch (error) {
                console.error(error);
            }
            setIsSavingWidget(false);
        }
        setDeleteWidgetUuid(undefined);
    }

    // prepare list of tags headers for dropdown
    const possible_tags_headers_ids = lookup_table.headers.map((_, idx) => `${idx}`);
    const possible_tags_headers_values = lookup_table.headers.map((header) => header);
    // if there is only one tag header, we don't need to show the "/" separator
    if (["date_tags_chart", "tags_chart"].includes(new_widget_type) && (new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails)).tags_headers_idx.length > 1) {
        possible_tags_headers_ids.unshift("/");
        possible_tags_headers_values.unshift("/");
    }

    return <div className="w-full">
        {widgets.length > 0 && <div className="py-3 flex flex-row items-center w-full">
            <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5 w-full">
                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900">Title</th>
                            <th className="text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900">Type</th>
                            <th className="w-1 text-right px-3 text-sm cursor-pointer font-semibold text-gray-900">
                                {is_org_admin && <Button icon={PlusIcon} onClick={openNewWidget} disabled={is_saving_widget} loading={is_saving_widget} />}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {widgets.map((widget) => <tr key={widget.uuid}>
                            <td className="p-3 text-sm text-gray-900">
                                <LongText text={widget.title} line_limit={1} />
                            </td>
                            <td className="p-3 text-sm text-gray-900">
                                <LongText text={widget.type} line_limit={1} />
                            </td>
                            <td className="w-32 text-right px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900">
                                {is_org_admin && <ButtonGroup buttons={[
                                    { icon: PencilSquareIcon, tooltip: "Edit", text: "", onClick: () => openEditWidget(widget) },
                                    { icon: EyeIcon, tooltip: "Preview", text: "", onClick: () => previewEditWidget(widget) },
                                    { icon: TrashIcon, tooltip: "Delete", text: "", onClick: () => openDeleteWidget(widget) }
                                ]} disabled={is_saving_widget} />}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>}
        {widgets.length === 0 && <div className="py-3 flex flex-row items-center w-full">
            <div className="text-sm text-gray-900">No visualizations found</div>
            <div className="flex-grow" />
            {is_org_admin && <Button icon={PlusIcon} onClick={openNewWidget} disabled={is_saving_widget} loading={is_saving_widget} />}
        </div>}

        <SidePanelRaw open={show_side_panel !== undefined} onClose={cancelNewWidget}>
            <div className="grid grid-cols-3 gap-4 px-4 py-6 items-center">
                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Visualization Title
                </label>
                <div className="col-span-2">
                    <Textbox
                        value={new_widget_name || ""}
                        onChange={(value) => setNewWidgetName(value)}
                    />
                </div>
                {new_widget_filters.map((filter, filter_idx) => <Fragment key={filter_idx}>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Filter {filter_idx + 1}
                    </label>
                    <div className="flex flex-col items-start">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${filter.column_idx}`}
                            onChange={(value) => updateNewWidgetFilterColumnIdx(filter_idx, parseInt(value))}
                        />
                    </div>
                    {filter.values.map((value, value_idx) => <Fragment key={value_idx}>
                        {value_idx > 0 && <div className="flex flex-col w-full gap-y-2" />}
                        {value_idx > 0 && <div className="flex flex-col w-full gap-y-2" />}
                        <div className="flex flex-col w-full gap-y-2">
                            <Textbox
                                key={value_idx}
                                value={value}
                                onChange={(value) => updateNewWidgetFilterValue(filter_idx, value_idx, value)}
                            />
                        </div>
                    </Fragment>)}
                    {filter.values.length > 0 && <div className="flex flex-col w-full gap-y-2" />}
                    <Button icon={TrashIcon} text="Remove Filter" onClick={() => removeNewWidgetFilter(filter_idx)} />
                    <Button icon={PlusIcon} text="Add Value" onClick={() => addNewWidgetFilterValue(filter_idx)} />
                </Fragment>)}
                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Visualization Type
                </label>
                <div className="col-span-2">
                    <Dropdown
                        values={["date_count_chart", "date_distribution_chart", "date_tags_chart", "tags_chart"]}
                        ids={["date_count_chart", "date_distribution_chart", "date_tags_chart", "tags_chart"]}
                        selected={new_widget_type}
                        disabled={show_side_panel === "edit_widget"}
                        onChange={(value) => updateNewWidgetType(value as WidgetType)}
                    />
                </div>

                {new_widget_type === "date_count_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateCountChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                </Fragment>}

                {new_widget_type === "date_distribution_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateDistributionChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Values Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateDistributionChartDetails).values_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, values_header_idx: parseInt(value) })}
                        />
                    </div>
                </Fragment>}

                {new_widget_type === "date_tags_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateTagsChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Tags Headers
                    </label>
                    {(new_widget_details as IWidgetDateTagsChartDetails).tags_headers_idx.map((tag_headers_idx, tag_idx) => <Fragment key={tag_idx}>
                        {tag_idx > 0 && <div />}
                        <div className="flex flex-col w-full gap-y-2 col-span-2">
                            <Dropdown
                                values={possible_tags_headers_values}
                                ids={possible_tags_headers_ids}
                                selected={`${tag_headers_idx}`}
                                onChange={(value) => updateNewWidgetTagsHeadersIdx(tag_idx, value)}
                            />
                        </div>
                    </Fragment>)}
                    <div className="col-span-2" />
                    <Button icon={PlusIcon} text="Add Tag" onClick={addNewWidgetTagHeaderIdx} />
                </Fragment>}

                {new_widget_type === "tags_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetTagsChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Tags Headers
                    </label>
                    {(new_widget_details as IWidgetTagsChartDetails).tags_headers_idx.map((tag_headers_idx, tag_idx) => <Fragment key={tag_idx}>
                        {tag_idx > 0 && <div />}
                        <div className="flex flex-col w-full gap-y-2 col-span-2">
                            <Dropdown
                                values={possible_tags_headers_values}
                                ids={possible_tags_headers_ids}
                                selected={`${tag_headers_idx}`}
                                onChange={(value) => updateNewWidgetTagsHeadersIdx(tag_idx, value)}
                            />
                        </div>
                    </Fragment>)}
                    <div className="col-span-2" />
                    <Button icon={PlusIcon} text="Add Tag" onClick={addNewWidgetTagHeaderIdx} />
                </Fragment>}

            </div>
            <div className="flex flex-row items-center justify-end gap-x-2 p-4">
                <Button icon={FunnelIcon} text="Add Filter" onClick={addNewWidgetFilter} />
                <Button icon={XMarkIcon} text="Cancel" onClick={cancelNewWidget} />
                {show_side_panel === "new_widget" && <Button icon={PlusIcon} text="Create" onClick={createNewWidget} />}
                {show_side_panel === "edit_widget" && <Button icon={CheckIcon} text="Save" onClick={saveEditWidget} />}
            </div>
        </SidePanelRaw>

        {widget_chart !== undefined && <ConfirmModal
            open={widget_chart !== undefined}
            title="Preview"
            confirm="Close"
            hide_cancel={true}
            hide_icon={true}
            size="3xl"
            onClose={() => { setWidgetChart(undefined); }}
        >
            <div className="py-4"><Chart title={widget_chart.title} chart={widget_chart.chart} /></div>
        </ConfirmModal>}

        <ConfirmModal
            open={delete_widget_uuid !== undefined}
            title="Remove Visualization"
            message={["Are you sure you want to remove this visualization?"]}
            confirm="Remove"
            onClose={removeWidget} />
    </div>;
}


export function LookupTableDetail() {
    const navigate = useNavigate();
    const { lookup_table_uuid } = useParams<{ lookup_table_uuid: string | undefined }>();

    const env = useSelector(selectEnv);
    const self_url = useSelector(selectSelfUrl);
    const user = useSelector(selectUser);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);

    const [lookup_table, setLookupTable] = useState<ILookupTable | undefined>(undefined);
    const [lookup_table_rows, setLookupTableRows] = useState<ILookupTableRowSlim[] | undefined>(undefined);
    const [has_endpoints, setHasEndpoints] = useState(false);
    const [endpoint_to_lookup_table_mappings, setEndpointToLookupTableMappings] = useState<IEndpointToLookupTable[] | undefined>(undefined);
    const [is_deleting, setIsDeleting] = useState(false);
    const [is_activating, setIsActivating] = useState(false);
    const [selected_tab, setSelectedTab] = useState("observed_values");
    const [show_confirm, setShowConfirm] = useState(false);

    const is_org_admin = memberships.filter((m) => m.org.uuid === lookup_table?.org_uuid && m.role === "admin").length > 0;

    useEffect(() => {
        if (lookup_table_uuid === undefined) { return; }
        Backend.getLookupTable({ lookup_table_uuid })
            .then((lookup_table) => {
                if (lookup_table !== undefined) {
                    setLookupTable(lookup_table);
                    BackendObj.extractions.listLookupTableRows({ lookup_table_uuid, header_length: lookup_table.headers.length })
                        .then(({ rows }) => { setLookupTableRows(rows); })
                        .catch((error) => { console.error(error); setLookupTableRows(undefined); });
                    BackendObj.extractions.listEndpointToLookupTableMappings({ lookup_table_uuid })
                        .then(({ mappings }) => { setEndpointToLookupTableMappings(mappings); })
                        .catch((error) => { console.error(error); setEndpointToLookupTableMappings(undefined); });
                    // check if we have any endpoints so we can show the connected endpoints tab
                    BackendObj.extractions.listEndpoints({}).then(({ endpoints }) => {
                        setHasEndpoints(endpoints.filter((endpoint) => endpoint.org_uuid === lookup_table.org_uuid).length > 0);
                    });
                }
            });
    }, [lookup_table_uuid]);

    useEffect(() => {
        if (lookup_table === undefined) {
            setDocumentTitle("Lookup Table", env);
        } else {
            setDocumentTitle(`Lookup Table - ${lookup_table.name}`, env);
        }
    }, [lookup_table, env]);

    const is_rest_api_enabled = lookup_table?.details.rest_api_update || lookup_table?.details.rest_api_get;

    const tabs: ITab[] = [
        { name: "Observed Values", key: "observed_values" },
        { name: "Uploaded Data", key: "upload_data" },
        { name: "API Docs", key: "api_docs", hide: !(is_rest_api_enabled && is_org_admin) },
        { name: "Charts", key: "charts" },
        { name: "Connected Endpoints", key: "connected_endpoints", hide: !has_endpoints }
    ];

    const onDownload = async (type: "rows" | "upload") => {
        if (lookup_table === undefined) { return; }
        // prepare data
        const data: string[][] = type === "rows" ?
            [lookup_table.headers, ...lookup_table_rows?.map((row) => row.row) || []] :
            lookup_table.active_version?.sheet || [];
        // save workbook
        const blob = new Blob([data.map((row) => row.map(escapeCsvField).join(",")).join("\n")], { type: "text/csv" });
        saveAs(blob, `lookup_table${lookup_table.uuid}.csv`);
    };

    const deleteMapping = async (endpoint_to_lookup_table_uuid: string) => {
        setIsDeleting(true);
        await BackendObj.extractions.deleteEndpointToLookupTableMapping({ endpoint_to_lookup_table_uuid });
        if (endpoint_to_lookup_table_mappings !== undefined) {
            const new_endpoint_to_lookup_table_mappings = endpoint_to_lookup_table_mappings.filter((mapping) => mapping.uuid !== endpoint_to_lookup_table_uuid);
            setEndpointToLookupTableMappings(new_endpoint_to_lookup_table_mappings);
        }
        setIsDeleting(false);
    }

    const handleActivate = async (version_uuid: string) => {
        if (lookup_table === undefined) { return; }
        setIsActivating(true);
        // update version
        await BackendObj.extractions.setLookupTableActiveVersion({ lookup_table_uuid: lookup_table.uuid, version_uuid });
        // refresh lookup table
        const updated_lookup_table = await Backend.getLookupTable({ lookup_table_uuid: lookup_table.uuid });
        if (updated_lookup_table !== undefined) {
            setLookupTable(updated_lookup_table);
        }
        setIsActivating(false);
    }

    const onAddRow = async (row: string[]) => {
        if (lookup_table === undefined) { return; }
        const { row: new_row } = await BackendObj.extractions.addLookupTableRow({ lookup_table_uuid: lookup_table.uuid, row });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = [...lookup_table_rows, new_row];
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onDeleteRow = async (row_uuid: string) => {
        await BackendObj.extractions.deleteLookupTableRow({ row_uuid });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = lookup_table_rows.filter((row) => row.uuid !== row_uuid);
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onUpdateRow = async (row_uuid: string, row: string[]) => {
        await BackendObj.extractions.updateLookupTableRow({ row_uuid, row });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = lookup_table_rows.map((r) => (r.uuid === row_uuid) ? { ...r, row } : r);
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (is_remove && lookup_table_uuid !== undefined) {
            setIsDeleting(true);
            // delete from backend
            await Backend.deleteLookupTable({ lookup_table_uuid });
            // navigate back to list
            navigate("/endpoints");
        }
    };

    if (lookup_table === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const is_email_enabled = lookup_table.details.email_update !== undefined && lookup_table.details.email_update !== "disabled";
    const is_personal = memberships.some((m) => m.org.uuid === lookup_table.org_uuid && m.org.type === ORG_TYPES.personal);

    // reverse versions so the latest is first and truncate to 100
    const reverse_versions = [...lookup_table.versions].reverse().slice(0, 100);
    const hash_keys = lookup_table.details.merge_key_column_indices?.map((idx) => lookup_table.headers[idx]) || [];
    const compensate_leading_zeros_keys = lookup_table.details.compensate_leading_zeros_column_indices?.map((idx) => lookup_table.headers[idx]) || [];

    const lookup_table_org = memberships.find(({ org }) => org.uuid === lookup_table.org_uuid);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-20 w-full bg-white border-b border-b-sea_blue-700">
            <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
                <Button icon={ArrowLeftIcon} onClick={() => navigate("/endpoints")} />
                <div className="pl-4 flex flex-col justify-start gap-1">
                    <h2 className="flex flex-row items-center  gap-4 text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {lookup_table.name}
                        {lookup_table_org && <OrgPill name={lookup_table_org.org.name} type={lookup_table_org.org.type} />}
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">Lookup Table</h2>
                </div>
                <div className="grow"></div>
                <div className="flex items-center">
                    {lookup_table && is_org_admin && <ButtonGroup disabled={is_deleting}
                        buttons={[
                            { icon: ArrowUpTrayIcon, text: "", tooltip: "Upload", href: `/lookup_table/upload/${lookup_table.uuid}` },
                            { icon: PencilSquareIcon, text: "", tooltip: "Edit", href: `/lookup_table/edit/${lookup_table.uuid}` },
                            { icon: TrashIcon, text: "", tooltip: "Remove", onClick: () => setShowConfirm(true) }
                        ]} />}
                </div>
            </div>
        </div>

        <div className="px-10 py-7 max-w-5xl">
            <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Type</dt>
                <dd className="flex fle-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    <TableCellsIcon className="h-4 w-4 mr-2 text-slate-300" />Lookup Table
                </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Headers</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0 outer-div">
                    <table className="py-4 text-xs md:text-base">
                        <thead>
                            <tr>
                                {lookup_table.headers.map((_header, idx) => <th key={idx}
                                    className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-xs font-normal align-top w-32 focus:ring-1 focus:ring-space_blue-500 min-w-[150px] max-w-[300px]"
                                >
                                    {getExcelColumnName(idx)}
                                </th>)}
                            </tr>
                            <tr>
                                {lookup_table.headers.map((header, idx) => <th key={idx}
                                    className="py-1 px-4 bg-gray-100 border border-gray-300 text-gray-900 cursor-text hover:bg-sea_blue-100 text-left text-sm font-normal align-top w-32 focus:ring-1 focus:ring-space_blue-500 min-w-[150px] max-w-[300px]"
                                >
                                    {header}
                                </th>)}
                            </tr>
                        </thead>
                    </table>
                </dd>
            </div>
            <div className="pt-3 pb-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Merge keys</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-8 sm:mt-0">
                    {hash_keys.length === 0 && "/"}
                    {hash_keys.length > 0 && hash_keys.join(", ")}
                </dd>
            </div>

            <div className="pt-3 pb-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Compensate leading zeros</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-8 sm:mt-0">
                    {compensate_leading_zeros_keys.length === 0 && "/"}
                    {compensate_leading_zeros_keys.length > 0 && compensate_leading_zeros_keys.join(", ")}
                </dd>
            </div>

            {is_email_enabled && <div className="py-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Email connector</dt>
                <dd className="pr-4 text-sm leading-6 text-space_blue-600 sm:col-span-8 sm:mt-0">
                    <CopyTextbox text={lookup_table.email_address} email_pretty_name={`Update lookup table '${lookup_table.name}'`} is_email={true} />
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Upload via Email</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {[
                        { key: "replace", name: "Upload" },
                        { key: "append", name: "Append" },
                        { key: "merge_incoming_wins", name: "Merge incoming wins" },
                        { key: "merge_existing_wins", name: "Merge existing wins" }
                    ].find((f) => f.key === lookup_table.details.email_update)?.name || "/"}
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Attachment format</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {[
                        { key: "tsv", name: "TSV" }
                    ].find((f) => f.key === lookup_table.details.email_attachment_format)?.name || "TSV"}
                </dd>
            </div>}

            {is_rest_api_enabled && <div className="py-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">REST API Endpoint</dt>
                <dd className="pr-4 text-sm leading-6 sm:col-span-8 sm:mt-0 text-space_blue-600">
                    <CopyTextbox text={lookup_table.url} is_email={false} />
                </dd>
            </div>}
        </div>

        <div className="px-10 max-w-5xl">
            <div className="pt-6">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
            </div>

            {selected_tab === "observed_values" && lookup_table_rows &&
                <div className="px-4 py-3 pb-6">
                    <div>
                        <span
                            className="p-2 text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
                            onClick={() => onDownload("rows")}>
                            [download]
                        </span>
                    </div>
                    <div className="pb-4">
                        <SheetEditor
                            headers={lookup_table.headers}
                            rows={lookup_table_rows.map((row) => ({ uuid: row.uuid, row: row.row }))}
                            onAddRow={onAddRow}
                            onDeleteRow={onDeleteRow}
                            onUpdateRow={onUpdateRow} />
                    </div>
                </div>}

            {selected_tab === "upload_data" &&
                <div className="px-4 py-3 sm:px-0">
                    {lookup_table.active_version?.sheet !== undefined && lookup_table.active_version?.no_of_rows > 0 &&
                        <div className="pl-4 outer-div">
                            <span
                                className="p-2 text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
                                onClick={() => onDownload("upload")}>
                                [download]
                            </span>
                            <Sheet data={lookup_table.active_version.sheet} />
                        </div>}
                    {lookup_table.active_version?.sheet !== undefined && lookup_table.active_version?.no_of_rows === 0 &&
                        <div className="pl-4 text-sm text-gray-900">No uploaded data</div>}
                    {lookup_table.active_version?.sheet === undefined &&
                        <div className="pl-4 text-sm text-gray-900">Sheet not available</div>}

                    <div className="pl-4 pt-4 text-sm font-medium leading-6 text-gray-900">Upload History</div>
                    <div className="p-4">
                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Created</th>
                                    <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Rows</th>
                                    <th className="w-32 px-3 py-2 text-center text-sm font-semibold text-gray-900">Status</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {reverse_versions.map((version, idx) => (
                                    <tr key={idx}>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-600 align-top">{prettyDateTime(version.created_at)}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-600 align-top">{version.no_of_rows}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm align-top text-center">
                                            {version.uuid === lookup_table.active_version_uuid &&
                                                <Pill text="Active" type="info" disabled={is_activating} />}
                                            {version.uuid !== lookup_table.active_version_uuid &&
                                                <Pill text="Activate" type="default" disabled={is_activating} onClick={() => handleActivate(version.uuid)} />}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}

            {selected_tab === "api_docs" && <Fragment>
                <h3 className="px-4 pt-4 leading-7 text-lg font-medium">API Keys</h3>
                <div className="text-sm text-gray-500 flex-grow py-4">
                    {!is_personal && <span>You can manage API keys in the <Link to={`/org/${lookup_table.org_uuid}`} className="text-space_blue-600 underline">organization settings</Link>.</span>}
                    {is_personal && <span>You can manage API keys in the <Link to={`/user/${user.uuid}`} className="text-space_blue-600 underline">user settings</Link>.</span>}
                </div>
                <h3 className="px-4 pt-4 leading-7 text-lg font-medium">REST API</h3>
                <div className="my-4 flex flex-row gap-2 items-center">
                    <Button
                        icon={FileCodeIcon}
                        text="OpenAPI"
                        href={`${self_url}/public/open-api.json`}
                        open_in_new_tab={true} />
                    <Button
                        icon={BrowserIcon}
                        text="Swagger"
                        href={`${self_url}/public/swagger`}
                        open_in_new_tab={true} />
                    <Button
                        icon={MarkGithubIcon}
                        href="https://gist.github.com/blazf/3bb9abf9bab1d968b06413c36005337e"
                        text="Node.JS example"
                        open_in_new_tab={true} />
                </div>
            </Fragment>}

            {selected_tab === "charts" && <div className="p-4 w-full">
                <WidgetList lookup_table={lookup_table} />
            </div>}

            {selected_tab === "connected_endpoints" && has_endpoints && <div className="px-4 py-3 pb-6">
                <div className="py-3 flex flex-row items-center w-full">
                    <div className="flex-1 text-sm font-medium leading-6 text-gray-900">Connected Endpoints</div>
                    {is_org_admin && <Button icon={PlusCircleIcon} text="Connect" href={`/endpoint-lookup-table-mapping/create/lookup_table/${lookup_table.uuid}`} />}
                </div>
                <EndpointToLookupTable
                    can_edit={is_org_admin}
                    source_type="lookup_table"
                    source_uuid={lookup_table.uuid}
                    endpoint_to_lookup_table_mappings={endpoint_to_lookup_table_mappings}
                    is_deleting={is_deleting}
                    deleteMapping={deleteMapping} />
            </div>}
        </div>
        <ConfirmModal open={show_confirm}
            title="Remove Lookup Table"
            message={["Are you sure you want to remove this lookup table?"]}
            confirm="Remove"
            onClose={onRemoveClose} />
    </div>;
}
