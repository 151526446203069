import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom";
import { useSelector } from "react-redux";

import {
    ArrowLeftIcon,
    ArrowPathIcon,
    ClipboardDocumentIcon,
    ExclamationTriangleIcon,
    PlusCircleIcon,
    TrashIcon
} from "@heroicons/react/24/outline";

import {
    classNames,
    flattenScrapeDocuments,
    prettySmartDateTime,
    setDocumentTitle
} from "../lib/utils";
import {
    IItem,
    IItemAttachmentBase,
    IItemValidationSummary,
    IPreprocessParams,
    IScrapeDocument
} from "../lib/backend/extractions.types.generated";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    CONTEXT_TYPES,
    EXTRACT_CONFIRMATION_STATUS,
    EXTRACT_JOB_STATUS,
    USER_ROLES
} from "../lib/consts";
import { IEvalBundle } from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";

import { Dropdown } from "../components/Dropdown";
import { PdfViewerHorizontal } from "../components/PdfViewer";
import { ExcelViewer } from "../components/ExcelViewer";
import { Button } from "../components/Button";
import { Pill } from "../components/Pill";
import { EditExampleModal } from "../components/ExampleModals";
import { ITab, Tabs } from "../components/Tabs";
import { SupportLink } from "../components/SupportLink";
import { ValidationSummary } from "../components/ValidationSummary";
import { ViewItemTables } from "../components/ItemTables";
import { ExtractButton } from "../components/ExtractButton";
import { ConfirmModal } from "../components/ConfirmModal";
import { OrgPill } from "../components/OrgPill";
import { ButtonMenu, IButtonMenuItem } from "../components/ButtonMenu";
import { LongText } from "../components/LongText";
import { ErrorMessageBar } from "../components/ErrorMessageBar";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ItemBreadcrumb } from "../components/ItemBreadcrumb";

function EmailContentHtml(props: { item: IItem, html: string }) {
    const { item, html } = props;

    // go over attachments and see if we have any cid: links
    let clean_html = html;
    for (const attachment of item.attachments) {
        if (attachment.cidname !== undefined) {
            const cid = `cid:${attachment.cidname}`;
            const url = `/api/attachment/get?uuid=${attachment.uuid}`;
            clean_html = clean_html.replace(new RegExp(cid, "g"), url);
        }
    }

    return <iframe
        title="email"
        srcDoc={clean_html}
        style={{ width: "100%", height: "400px" }}
        className="border bg-white mt-2"
        sandbox="allow-same-origin" // Allows the iframe content to be treated as being from the same origin
    />;
}

function EmailContent(props: { item: IItem }) {
    const { item } = props;

    const is_html = item.details.email_html !== undefined;

    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <div className="pb-2"><span className="font-bold">From:</span> {item.details.from_email_address}</div>
        {item.details.to_email_addresses && <div className="pb-2"><span className="font-bold">To:</span> {item.details.to_email_addresses?.join(", ")}</div>}
        {item.details.email_subject && <div className="pb-2"><span className="font-bold">Subject:</span> {item.details.email_subject}</div>}
        {/* render html from item.details.email_html in iframe */}
        {is_html && <EmailContentHtml item={item} html={item.details.email_html || ""} />}
        {/* render text from item.text */}
        {!is_html && <LongText text={item.details.email_text || ""} />}
    </div>;
}

function DocumentContent(props: { item: IItem }) {
    const { item } = props;

    return item.attachments.length > 0 ? <Attachment attachment={item.attachments[0]} /> :
        <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
            {item.details.document_subject && <div className="pb-2 mb-2 border-b"><span className="font-bold">Subject:</span> {item.details.document_subject}</div>}
            <LongText text={flattenScrapeDocuments(item.documents)} />
        </div>;
}

function TextContent(props: { documents: IScrapeDocument[] }) {
    const { documents } = props;

    const onCopy = () => {
        navigator.clipboard.writeText(flattenScrapeDocuments(documents));
    }

    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <div className="flex flex-row">
            <div className="flex-grow" />
            <button
                type="button"
                className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-space_blue-500 focus:ring-offset-2"
                onClick={onCopy}
            >
                <span className="sr-only">Copy to clipboard</span>
                <ClipboardDocumentIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>
        <LongText text={flattenScrapeDocuments(documents)} />
    </div>;
}

function InputTextContent(props: { item: IItem }) {
    const { item } = props;

    const [renderings, setRenderings] = useState<{ name: string, context_uuid: string, preprocess_params?: IPreprocessParams }[]>([]);
    const [selected_context_uuid, setSelectedContextUuid] = useState<string>("");

    useEffect(() => {
        const getContextName = (context_uuid: string) => {
            return item.template.contexts.find((context) => context.uuid === context_uuid)?.name || "Unknown";
        }

        const renderings: { name: string, context_uuid: string, preprocess_params?: IPreprocessParams }[] = [];
        // check if any document/page has additional renderings
        for (const document of item.documents) {
            for (const page of document.pages) {
                // first page we see collect default preprocess params
                if (renderings.length === 0) {
                    renderings.push({ name: "Default", context_uuid: "", preprocess_params: page.preprocess_params });
                }
                // then we collect all additional renderings
                if (page.additional_renderings && page.additional_renderings.length > 0) {
                    for (const rendering of page.additional_renderings) {
                        // make sure we do not have this context already
                        if (renderings.find((r) => r.context_uuid === rendering.context_uuid) === undefined) {
                            renderings.push({ name: getContextName(rendering.context_uuid), context_uuid: rendering.context_uuid, preprocess_params: rendering.preprocess_params });
                        }
                    }
                }
            }
        }

        setRenderings(renderings);
        setSelectedContextUuid("");
    }, [item]);

    const onCopy = () => {
        navigator.clipboard.writeText(flattenScrapeDocuments(item.documents));
    }

    const render_docs = item.documents.map((document) => ({
        name: document.name,
        pages: document.pages.map((page) => ({
            name: page.name,
            classification: page.classification,
            preprocess_params: page.additional_renderings?.find((r) => r.context_uuid === selected_context_uuid)?.preprocess_params ?? page.preprocess_params,
            text: page.additional_renderings?.find((r) => r.context_uuid === selected_context_uuid)?.text ?? page.text
        }))
    }));

    // we have email, render it as such
    return <div className="px-2 py-4 mx-2 border border-gray-200 rounded shadow bg-gray-50">
        <div className="px-2 flex flex-row items-center gap-x-2">
            <div className="text-sm text-gray-400">Rendering:</div>
            <div className="max-w-sm w-full">
                <Dropdown
                    values={renderings.map((r) => r.name)}
                    ids={renderings.map((r) => r.context_uuid)}
                    selected={selected_context_uuid}
                    onChange={(context_uuid) => setSelectedContextUuid(context_uuid)}
                />
            </div>
            <div className="flex-grow" />
            <button
                type="button"
                className="mr-4 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-space_blue-500 focus:ring-offset-2"
                onClick={onCopy}
            >
                <span className="sr-only">Copy to clipboard</span>
                <ClipboardDocumentIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>

        {render_docs.map((document, i) => (
            <div key={i}>
                <h3 className="text-sm p-2 text-gray-600">Document {i + 1}: {document.name}</h3>
                {document.pages.map((page, j) => (
                    <div key={j}>
                        <h4 className="text-xs p-2 text-gray-600 flex flex-row gap-2 items-center">
                            <span className="font-semibold">{page.name}</span>
                            {page.preprocess_params !== undefined && <Fragment>
                                <span>(</span>
                                <div className="flex flex-row gap-1">
                                    {Object.keys(page.preprocess_params).map((key, idx) => <Fragment key={key}>
                                        <span className="font-mono text-xs text-gray-400">{(page.preprocess_params as any)[key]}</span>
                                        {idx < Object.keys(page.preprocess_params ?? {}).length - 1 && <span className="font-mono text-xs text-gray-400">|</span>}
                                    </Fragment>)}
                                </div>
                                <span>)</span>
                            </Fragment>}
                            {page.classification && page.classification.length > 0 && <Fragment>
                                <span>-</span>
                                {page.classification.map((classifier_results, idx) => <Fragment key={idx}>
                                    {idx > 0 && <span>-</span>}
                                    <span className="text-gray-500">
                                        Classifier {idx + 1}: {classifier_results.length > 0 ? classifier_results.join(", ") : "∅"}
                                    </span>
                                </Fragment>)}
                            </Fragment>}
                        </h4>
                        <div className="font-mono text-xs border bg-white">
                            <LongText text={page.text} />
                        </div>
                    </div>
                ))}
            </div>
        ))}
    </div>;
}

function Attachment(props: { attachment: IItemAttachmentBase }) {
    const { attachment } = props;

    const url = `/api/attachment/get?uuid=${attachment.uuid}`;
    const link = <a
        className="pl-2 pt-2 text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
        href={url} target="_blank" rel="noreferrer">
        [download]
    </a>

    const lc_filename = attachment.filename.toLowerCase();

    if (
        attachment.mimetype.startsWith("text/") ||
        lc_filename.endsWith(".txt")
    ) {
        return <TextContent documents={[attachment.document]} />;
    }

    if (
        attachment.mimetype.startsWith("image/") ||
        lc_filename.endsWith(".jpg") ||
        lc_filename.endsWith(".jpeg") ||
        lc_filename.endsWith(".png") ||
        lc_filename.endsWith(".tiff") ||
        lc_filename.endsWith(".bmp") ||
        lc_filename.endsWith(".gif")
    ) {
        return <div className="mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <img src={url} alt={attachment.filename} />
            {link}
        </div>;
    }

    if (attachment.mimetype === "application/pdf" || lc_filename.endsWith(".pdf")) {
        return <div className="mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50 overflow-y-auto">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <PdfViewerHorizontal display_name={attachment.filename} file={url} max_width={300} />
            {link}
        </div>;
    }

    if (lc_filename.endsWith(".xlsx") || lc_filename.endsWith(".xls") || lc_filename.endsWith(".ods")) {
        return <div className="mx-2 px-4 py-4 border border-gray-200 rounded shadow bg-gray-50">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <ExcelViewer attachment_uuid={attachment.uuid} />
            {link}
        </div>;
    }

    const extension = attachment.filename.split(".").pop();
    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
        <div className="pb-2" >Attachment of type <span className="font-semibold font-mono">{extension}</span> is not supported for extraction and cannot be displayed.</div>
        {link}
    </div>;
};

export function ItemDetail() {
    const navigate = useNavigate();

    const { item_uuid } = useParams<{ item_uuid: string | undefined }>();
    const [search_params] = useSearchParams();
    const job_uuid = search_params.get("job_uuid");

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const [item, setItem] = useState<IItem | undefined>(undefined);
    const [validation_summary, setValidationSummary] = useState<IItemValidationSummary | undefined>(undefined);
    const [job_status, setJobStatus] = useState<string | undefined>(undefined);

    const [tabs, setTabs] = useState<ITab[]>([]);
    const [selected_tab, setSelectedTab] = useState<string>("");

    const [is_cloned, setIsCloned] = useState(false);
    const [is_new_example_modal_open, setIsNewExampleModalOpen] = useState<boolean>(false);
    const [is_being_cloned, setIsBeingCloned] = useState(false);
    const [show_confirm, setShowConfirm] = useState(false);
    const [allow_remove, setAllowRemove] = useState(true);
    const [message, setMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        setItem(undefined);
        setValidationSummary(undefined);
        setJobStatus(undefined);
        setMessage(undefined);

        if (item_uuid !== undefined) {
            BackendObj.extractions.getItem({ item_uuid }).then(({ item: new_item, validation_summary: new_validation_summary, message: new_message }) => {
                setItem(new_item);
                setValidationSummary(new_validation_summary);
                setMessage(new_message);
                if (new_item?.details.extract_job_uuid !== undefined) {
                    const job_uuid = new_item.details.extract_job_uuid;
                    // loop for checking status of selected item
                    Backend.getExtractJobStatus({ job_uuid }).then((status) => {
                        // update status
                        setJobStatus(status);
                    });
                }
            });
        }
    }, [item_uuid]);

    useEffect(() => {
        if (item === undefined) {
            setDocumentTitle("Extraction - loading...", env);
        } else {
            setDocumentTitle(`Extraction - ${item.name.length > 0 ? item.name : "[no subject]"}`, env);
        }
    }, [env, item]);

    useEffect(() => {
        if (item === undefined) { return; }
        const tabs: ITab[] = [];
        if (item.details.input_type === "email") {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.input_type === "documents") {
            if (item.attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else if (item.details.from_email_address !== undefined) {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.document_subject !== undefined || item.details.document_filename !== undefined) {
            if (item.attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else {
            tabs.push({ name: "Text", key: "text" });
        }
        for (const attachment of item.attachments) {
            tabs.push({ name: attachment.filename, key: attachment.uuid });
        }
        if (user.role === USER_ROLES.admin) {
            tabs.push({ name: "Input Text", key: "input_text" });
        }
        setTabs(tabs);
        if (tabs.length > 0) {
            setSelectedTab(tabs[0].key);
        }
        setIsCloned(item?.details.clone_example_item_uuid !== undefined);
    }, [user, item]);

    const navigateBack = () => {
        if (job_uuid) {
            navigate(`/job/${job_uuid}`);
        } else {
            navigate("/items");
        }
    }

    const statusRefresh = async () => {
        setJobStatus(undefined);
        if (item === undefined) { return; }
        if (item.details.extract_job_uuid === undefined) { return; }
        const job_uuid = item.details.extract_job_uuid;
        const status = await Backend.getExtractJobStatus({ job_uuid });
        setJobStatus(status);
        // if job is not running, refresh item to get latest data
        if (status !== EXTRACT_JOB_STATUS.running && item_uuid !== undefined) {
            const { item: new_item, validation_summary: new_validation_summary, message: new_message } = await BackendObj.extractions.getItem({ item_uuid });
            setItem(new_item);
            setValidationSummary(new_validation_summary);
            setMessage(new_message);
        }
    };

    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (item_uuid !== undefined && is_remove) {
            setAllowRemove(false);
            // delete from backend
            await Backend.deleteItem({ item_uuid });
            // redirect to items list
            navigate("/items");
        }
    };

    const startCreateExample = () => {
        setIsNewExampleModalOpen(true);
    }

    const createExample = async (comment: string) => {
        setIsNewExampleModalOpen(false);
        if (item === undefined) { return; }
        setIsBeingCloned(true);
        try {
            await BackendObj.extractions.createExampleFromItem({ item_uuid: item?.uuid, comment })
        } catch (err) {
            console.error(err);
        }
        setIsCloned(true);
        setIsBeingCloned(false);
    }

    const downloadEvalBundle = () => {
        if (item === undefined) {
            return;
        }
        // create bundle
        const bundle: IEvalBundle = {
            template: {
                template_name: item.template.name,
            },
            contexts: [],
            texts: [
                {
                    name: item.name,
                    text: flattenScrapeDocuments(item.documents)
                }
            ],
            prompts: [{ prompt_name: "scrape.default", prompts: null }],
            models: [{ model_name: "model.default", params: null }],
        };
        // add contexts
        for (const context of item.template.contexts) {
            bundle.contexts.push({
                context_name: `ORG: ${context.name}`,
                facts: context.facts,
                fields: context.fields,
                extract_params: context.extract_params,
                type: context.type
            });
            bundle.contexts.push({
                context_name: `FIX: ${context.name}`,
                facts: context.facts,
                fields: context.fields,
                extract_params: context.extract_params,
                type: context.type
            });
        }
        // save json
        const dataStr = JSON.stringify(bundle, null, 2);
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataUri);
        downloadAnchorNode.setAttribute("download", `item_eval_bundle_${item.uuid}.json`);
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    if (item === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const scrapes = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = item.template.contexts[i];
        scrapes.push({ scrape, context });
    }

    const is_item_hierarchical = item?.template.contexts.some(context => context.type === CONTEXT_TYPES.hierarchical);

    const is_custom_download_enabled = item?.endpoint?.details.handlebar_template !== undefined && item?.endpoint?.details.handlebar_template !== "";
    const download_buttons: IButtonMenuItem[] = [
        { title: "Eval", onClick: downloadEvalBundle, admin_only: true },
        { title: "Excel", href: `/api/item/excel?item_uuid=${item?.uuid}`, open_in_new_tab: true },
        { title: "PDF", href: `/api/item/pdf?item_uuid=${item?.uuid}`, open_in_new_tab: true },
        { title: "JSON", href: `/api/item/json?item_uuid=${item?.uuid}`, open_in_new_tab: true, hide: is_item_hierarchical },
        { title: "Custom", href: `/api/item/custom?item_uuid=${item?.uuid}`, open_in_new_tab: true, hide: !is_custom_download_enabled },
    ];

    const is_not_pending_or_rejected = item.extract_confirmations_status !== EXTRACT_CONFIRMATION_STATUS.pending &&
        item.extract_confirmations_status !== EXTRACT_CONFIRMATION_STATUS.rejected;

    const item_org = memberships.find(m => m.org.uuid === item.template.org_uuid);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="md:h-20 w-full bg-white border-b border-b-sea_blue-700">
            <div className="pl-4 pr-10 py-4 flex md:flex-row flex-col items-start max-w-5xl gap-y-2">
                <div className="hidden md:block"><Button icon={ArrowLeftIcon} onClick={navigateBack} /></div>
                <div className="pl-4 flex flex-col justify-start gap-1 overflow-hidden text-ellipsis">
                    <h2 className="gap-4 text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {item.name.length > 0 ? item.name : "[no subject]"}
                    </h2>
                    <div className="w-full flex flex-row items-center gap-x-4 text-sm text-gray-400 tracking-tight">
                        <ItemBreadcrumb
                            template_uuid={item.template_uuid}
                            template_name={item.template.name}
                            endpoint_uuid={item.endpoint?.uuid}
                            endpoint_name={item.endpoint?.name}
                        />
                    </div>
                </div>
                <div className="grow"></div>
                <div className="flex flex-col items-end">
                    <div className="flex flex-row items-center">
                        <div className="pl-2 md:hidden"><Button icon={ArrowLeftIcon} onClick={navigateBack} /></div>
                        {item_org && <div className="px-2"><OrgPill name={item_org.org.name} type={item_org.org.type} /></div>}
                        {!is_cloned && <Button icon={is_being_cloned ? undefined : PlusCircleIcon} text="Examples" disabled={is_being_cloned} loading={is_being_cloned} onClick={startCreateExample} />}

                        <ButtonMenu title="Download" items={download_buttons} disabled={!item} />
                        <Button icon={TrashIcon} onClick={() => setShowConfirm(true)} disabled={!allow_remove || !item} />
                        <ExtractButton template_uuid={item.template_uuid} endpoint_uuid={item.endpoint?.uuid} />
                    </div>
                    <div className="p-2 flex flex-row gap-x-2 items-center text-gray-400 text-xs whitespace-nowrap">
                        <Link to={`/job/${item.details.extract_job_uuid}?item_uuid=${item.uuid}`} className="hover:underline cursor-pointer">Job Details</Link>
                        <span>{prettySmartDateTime(item.created_at)}</span>
                        {is_cloned && <div className="pl-1"><Pill small={true} text="Example" type="default" /></div>}
                    </div>
                </div>
                <EditExampleModal
                    type="add"
                    open={is_new_example_modal_open}
                    init_comment=""
                    onUpdateExample={createExample}
                    onClose={() => setIsNewExampleModalOpen(false)} />
                <ConfirmModal open={show_confirm}
                    title="Remove extraction"
                    message={["Are you sure you want to remove this extraction?"]}
                    confirm="Remove"
                    onClose={onRemoveClose} />
            </div>
        </div>
        <div className="pl-2 pr-2">
            <div className="px-4 max-w-5xl">
                {job_status === EXTRACT_JOB_STATUS.running && <div className="mt-4 p-4 border bg-yellow-50 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is still being processed. Please wait for the extraction to finish and refresh.</div>
                        <div className="flex-grow"></div>
                        {statusRefresh && <ArrowPathIcon className="h-5 w-5 cursor-pointer" onClick={statusRefresh} />}
                    </div>
                </div>}
                {job_status === EXTRACT_JOB_STATUS.error && <div className="mt-4 p-4 border bg-red-50 rounded text-sm text-gray-900">
                    <div className="flex flex-col gap-y-2">
                        <p>There was an error processing the item.</p>
                        <p>You can see the error message in the extractions log on the <Link to="/user" className="text-space_blue-500 cursor-pointer hover:underline">profile</Link> page.</p>
                        <p>Please try again or contact <SupportLink />.</p>
                    </div>
                </div>}
                {item.details.retry_new_item_uuids !== undefined && item.details.retry_new_item_uuids.length > 0 && <div className="mt-4 p-4 border bg-red-100 rounded text-sm text-gray-900">
                    {item.details.retry_new_item_uuids.length === 1 && <p>
                        There is a <Link to={`/item/${item.details.retry_new_item_uuids[0]}`} className="text-space_blue-600 underline">new version</Link> of this extraction.
                    </p>}
                    {item.details.retry_new_item_uuids.length > 1 && <p>
                        There are {item.details.retry_new_item_uuids.length} new versions of this extraction: {item.details.retry_new_item_uuids.map((uuid, idx) =>
                            <Fragment key={idx}>{idx > 0 && ", "}<Link to={`/item/${uuid}`} className="text-space_blue-600 underline">version {idx + 1}</Link></Fragment>)}
                    </p>}
                </div>}
                {item.extract_confirmations_status === EXTRACT_CONFIRMATION_STATUS.pending && <div className="mt-4 p-4 border bg-yellow-100 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is pending confirmation. Please validate and confirm the extractions.</div>
                        <div className="flex-grow"></div>
                        <Button text="Go to Confirmation" highlight={true} href={`/confirm/${item.uuid}`} />
                    </div>
                </div>}
                {item.extract_confirmations_status === EXTRACT_CONFIRMATION_STATUS.rejected && <div className="mt-4 p-4 border bg-red-50 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item was rejected during confirmation and was not sent forward for processing.</div>
                    </div>
                </div>}
                {is_not_pending_or_rejected && item.digest !== undefined && !item.digest.sent && <div className="mt-4 p-4 border bg-yellow-50 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is waiting to be sent in a daily digest.</div>
                    </div>
                </div>}
                {is_not_pending_or_rejected && item.digest !== undefined && item.digest.sent && <div className="mt-4 p-4 border bg-mint-50 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item was already sent in a daily digest.</div>
                    </div>
                </div>}
                {item.details.is_input_text_truncated && <div className="mt-4 p-4 border bg-gray-100 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2 text-sm">
                        <div><ExclamationTriangleIcon className="h-5 w-5 mr-2 text-red-400" /></div>
                        <div className="flex flex-col gap-y-2">
                            <p>Input text was truncated due to its length.</p>
                            {user.role === "free" && <p>Please <a href="https://meetings-eu1.hubspot.com/tomaz/book-a-demo" className="text-space_blue-600" target="_blank" rel="noreferrer">contact our team</a> for more information on how to process longer documents.</p>}
                            {user.role !== "free" && <p>You can process longer document by creating an <Link to="/endpoint/new" className="text-space_blue-600">Integration</Link> for this template</p>}
                        </div>
                    </div>
                </div>}
                {validation_summary && <div className="mt-4">
                    <ValidationSummary summary={validation_summary} hide_empty={true} />
                </div>}
            </div>

            <div className="py-4">
                <ViewItemTables item={item} />
            </div>
            <div className="px-4 py-6">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
            </div>
            <div className="px-4 pb-6">
                {selected_tab === "email" && <EmailContent item={item} />}
                {selected_tab === "doc" && <DocumentContent item={item} />}
                {item.attachments.map(a => a.uuid).includes(selected_tab) && <Attachment attachment={item.attachments.find(a => a.uuid === selected_tab)!} />}
                {selected_tab === "text" && <TextContent documents={item.documents} />}
                {selected_tab === "input_text" && <InputTextContent item={item} />}
            </div>
        </div>
        <ErrorMessageBar message={message} clearMessage={() => setMessage(undefined)} />
    </div>;
}