import {
    IOrganization
} from "./types";

export function parseOrganization(o: any): IOrganization {
    return {
        uuid: o.uuid,
        name: o.name,
        tags: o.tags,
        type: o.type,
        models_overrides: o.models_overrides,
        created_at: o.created_at
    }
}
