import { classNames } from "../lib/utils"

type DropdownProps = {
    values: string[];
    ids?: string[];
    selected: string;
    disabled?: boolean;
    placeholder?: string;
    id?: string;
    onChange?: (id: string) => void;
};

export function Dropdown(props: DropdownProps) {
    const { values, ids, selected, disabled, placeholder, id, onChange } = props;

    // if we do not have ids, we resort to values
    const ids_ = ids ?? values;

    return <select
        id={id}
        name="industry" autoComplete="industry"
        className={classNames(
            "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6",
            (values.length === 0 || placeholder === selected || disabled) ? "text-gray-500" : "font-normal text-gray-700",
            disabled ? "cursor-not-allowed bg-gray-100" : "cursor-pointer"
        )}
        disabled={disabled}
        value={selected}
        onChange={(e) => { if (onChange) { onChange(e.target.value); } }}
    >
        {values.map((v, idx) => {
            return <option key={idx} value={ids_[idx]}>{v}</option>;
        })}
    </select>;
}

export function InvisibleDropdown(props: DropdownProps) {
    const { values, ids, selected, disabled, placeholder, id, onChange } = props;

    // if we do not have ids, we resort to values
    const ids_ = ids ?? values;

    return <select
        id={id}
        name="industry" autoComplete="industry"
        className={classNames(
            "block w-full border-0 focus:ring-2 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6",
            (values.length === 0 || placeholder === selected || disabled) ? "text-gray-500" : "text-gray-700",
            disabled ? "cursor-not-allowed bg-gray-100" : "cursor-pointer"
        )}
        disabled={disabled}
        value={selected}
        onChange={(e) => { if (onChange) { onChange(e.target.value); } }}
    >
        {values.map((v, idx) => {
            return <option key={idx} value={ids_[idx]}>{v}</option>;
        })}
    </select>;
}
