import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { classNames } from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";

import { Button } from "../components/Button";
import { Backend, BackgroundJobName } from "../lib/backend";

export function AdminBackgroundJobs() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [is_starting_job, setIsStartingJob] = useState(false);
    const [starting_job_name, setStartingJobName] = useState<string | undefined>(undefined);

    const startJob = (job_name: BackgroundJobName) => {
        setIsStartingJob(true);
        setStartingJobName(job_name);
        Backend.runBackgroundJobs({ job_name });
        setTimeout(() => {
            setIsStartingJob(false);
            setStartingJobName(undefined);
        }, 5000);
    };

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Background Jobs
                </h2>
                <div className="pt-5 border-b-4 border-sea_blue-700" />
            </div>

            <div className="flex flex-col px-10 py-5">
                <div className="w-full">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Job
                                </th>
                                <th className="w-60 px-10 py-3.5 text-center text-sm font-semibold text-gray-900">
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Close old running jobs and set their error message to "Timeout".
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button onClick={() => { startJob("close_timeout_extract_jobs"); }} text="Execute" loading={starting_job_name === "close_timeout_extract_jobs"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Process pending "send email digest" jobs.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button onClick={() => { startJob("forward_email_digests"); }} text="Execute" loading={starting_job_name === "forward_email_digests"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Process pending "user notifications" jobs.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button onClick={() => { startJob("handle_user_notifications"); }} text="Execute" loading={starting_job_name === "handle_user_notifications"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of context fields in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=field" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { startJob("validate_db_context_fields"); }} text="Execute" loading={starting_job_name === "validate_db_context_fields"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of context extract params in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=extract_params" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { startJob("validate_db_context_extract_params"); }} text="Execute" loading={starting_job_name === "validate_db_context_extract_params"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of scrape records in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=record" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { startJob("validate_db_scrape_records"); }} text="Execute" loading={starting_job_name === "validate_db_scrape_records"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of endpoint output sheets in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=output_sheet" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { startJob("validate_db_endpoint_output_sheets"); }} text="Execute" loading={starting_job_name === "validate_db_endpoint_output_sheets"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of endpoint details in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=endpoint_details" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { startJob("validate_db_endpoint_details"); }} text="Execute" loading={starting_job_name === "validate_db_endpoint_details"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of field uuid uniqueness in templates in database.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=template_field_uuids" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { startJob("validate_db_template_field_uuids"); }} text="Execute" loading={starting_job_name === "validate_db_template_field_uuids"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of OCR strategies in templates in database.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=template_context_ocr_strategies" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { startJob("validate_db_template_context_ocr_strategies"); }} text="Execute" loading={starting_job_name === "validate_db_template_context_ocr_strategies"} disabled={is_starting_job} />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Sync data warehouse.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button onClick={() => { startJob("sync_data_warehouse"); }} text="Execute" loading={starting_job_name === "sync_data_warehouse"} disabled={is_starting_job} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </Fragment >;
};
