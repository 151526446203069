import {
    ChevronDownIcon,
    ChevronUpIcon,
    TrashIcon
} from "@heroicons/react/24/outline";

import {
    getExcelColumnName,
    isFlatField
} from "../lib/utils";
import {
    IContextBase,
    IContextNoUUID,
    IEndpointOutputColumn
} from "../lib/types";

import { InvisibleDropdown } from "./Dropdown";
import { InvisibleTextbox } from "./Textbox";
import { Checkbox } from "./Checkbox";
import { CONTEXT_TYPES } from "../lib/consts";

type EndpointOutputColumnsProps = {
    context: IContextBase;
    object_contexts: (IContextNoUUID & { uuid: string; })[];
    columns: IEndpointOutputColumn[];
    show_hash_key: boolean;
    allow_zero_hash_keys: boolean;
    addColumn?: () => void;
    setColumn: (column_idx: number, column: IEndpointOutputColumn) => void;
    moveColumn?: (curr_idx: number, direction: "up" | "down") => void;
    deleteColumn?: (column_idx: number) => void;
    deleteAllButFirstColumn?: () => void;
}

export function EndpointOutputColumns(props: EndpointOutputColumnsProps) {
    const { context, object_contexts, columns, show_hash_key, allow_zero_hash_keys, addColumn, setColumn, moveColumn, deleteColumn, deleteAllButFirstColumn } = props;

    const valid_column_type_values = (context.type === CONTEXT_TYPES.array || context.type === CONTEXT_TYPES.lookup_table) ?
        ["Field", "Constant", "Row #"] : ["Field", "Constant"];
    const valid_column_type_ids = context.type === CONTEXT_TYPES.array || context.type === CONTEXT_TYPES.lookup_table ?
        ["field", "const", "row_number"] : ["field", "const"];

    const field_values: string[] = [];
    const field_ids: string[] = [];
    const fields_map = new Map<string, { context_uuid: string, field_uuid: string, field_name: string }>();
    for (const c of [context, ...object_contexts]) {
        for (const field of c.fields) {
            // for hierarchical contexts we only allow flat fields
            if (c.type === CONTEXT_TYPES.hierarchical && !isFlatField(field)) { continue; }
            // all good, add to list
            const value = (c.uuid === context.uuid) ? field.name : `${c.name}: ${field.name}`;
            const id = `${c.uuid}.${field.uuid}`;
            field_values.push(value);
            field_ids.push(id);
            fields_map.set(id, { context_uuid: c.uuid, field_uuid: field.uuid, field_name: field.name });
        }
    }

    const setColumnType = (column_idx: number, type: "field" | "const" | "row_number") => {
        // check if change
        if (columns[column_idx].type !== type) {
            if (type === "field") {
                setColumn(column_idx, {
                    name: columns[column_idx].name,
                    type: "field",
                    // reset to first field in context
                    field: { context_uuid: context.uuid, field_uuid: context.fields[0].uuid },
                    // rest reset to undefined
                    const_value: undefined,
                    row_number_type: undefined
                });
            } else if (type === "row_number") {
                setColumn(column_idx, {
                    name: "Row Number",
                    type: "row_number",
                    row_number_type: "inc_1",
                    // rest reset to undefined
                    field: undefined,
                    const_value: undefined
                });
            } else {
                setColumn(column_idx, {
                    name: columns[column_idx].name,
                    type: "const",
                    // reset to empty string
                    const_value: "",
                    // rest reset to undefined
                    field: undefined,
                    row_number_type: undefined
                });
            }
        }
        // if no change needed, return
    }

    const setColumnField = (column_idx: number, field_id: string) => {
        const column = columns[column_idx];
        const old_field = fields_map.get(column.field?.context_uuid + "." + column.field?.field_uuid);
        const new_field = fields_map.get(field_id);

        setColumn(column_idx, {
            ...column,
            // if column name is same as old field name, update it to new field name, else keep it as is
            name: old_field?.field_name === column.name ? new_field?.field_name || column.name : column.name,
            field: fields_map.get(field_id),
            const_value: undefined
        });
    };

    const setRowNumberType = (column_idx: number, row_number_type: "inc_1" | "inc_10") => {
        setColumn(column_idx, {
            ...columns[column_idx],
            row_number_type,
            field: undefined,
            const_value: undefined
        });
    }

    const handleHashKeyChange = (idx: number, column: IEndpointOutputColumn, hash_key: boolean) => {
        // If trying to uncheck and we don't allow zero hash keys
        if (!hash_key && !allow_zero_hash_keys && show_hash_key) {
            // Count current hash keys
            const currentHashKeys = columns.filter(col => col.hash_key).length;
            // If this is the last hash key, prevent unchecking
            if (currentHashKeys === 1 && column.hash_key) {
                return;
            }
        }
        // Otherwise proceed with the change
        setColumn(idx, { ...column, hash_key });
    };

    return <div>
        <table>
            <thead>
                <tr>
                    <th className="min-w-[20px] bg-gray-100 border border-gray-300"></th>
                    <th className="py-1 px-2 w-[17rem] bg-gray-100 border border-gray-300 text-left text-sm font-semibold align-top">Header</th>
                    <th className="py-1 px-2 w-[8rem] bg-gray-100 border border-gray-300 text-left text-sm font-semibold align-top">Type</th>
                    <th className="py-1 px-2 w-[17rem] bg-gray-100 border border-gray-300 text-left text-sm font-semibold align-top">Value</th>
                    {show_hash_key && <th className="py-1 px-2 w-[1rem] bg-gray-100 border border-gray-300 text-left text-sm font-semibold align-top">Key</th>}
                    {moveColumn && <th className="p-2 w-4 bg-gray-100 border-t border-b border-gray-300"></th>}
                    {moveColumn && <th className="p-2 w-4 bg-gray-100 border-t border-b border-r border-gray-300"></th>}
                    {deleteAllButFirstColumn && <th
                        className="p-2 w-4 align-middle bg-gray-100 text-gray-600 border border-gray-300 cursor-pointer hover:text-white hover:bg-space_blue-300"
                        onClick={deleteAllButFirstColumn}>
                        <TrashIcon className="w-4 h-4" />
                    </th>}
                </tr>
            </thead>
            <tbody>
                {columns.map((column, idx) => (<tr key={idx}>
                    <td className="py-1 px-2 align-middle bg-gray-100 border border-gray-300 text-left text-sm font-semibold">{getExcelColumnName(idx)}</td>
                    <td className="border border-gray-300 whitespace-normal overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text">
                        <InvisibleTextbox
                            value={column.name}
                            onChange={(name) => setColumn(idx, { ...column, name })} />
                    </td>
                    <td className="border border-gray-300 whitespace-normal overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text">
                        <InvisibleDropdown
                            values={valid_column_type_values}
                            ids={valid_column_type_ids}
                            selected={column.type}
                            onChange={(type) => setColumnType(idx, type as "field" | "const")} />
                    </td>
                    <td className="border border-gray-300 whitespace-normal overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text">
                        {column.type === "field" && <InvisibleDropdown
                            values={field_values}
                            ids={field_ids}
                            selected={column.field ? `${column.field.context_uuid}.${column.field.field_uuid}` : ""}
                            onChange={(field_id) => setColumnField(idx, field_id)} />}
                        {column.type === "const" && <InvisibleTextbox
                            value={column.const_value || ""}
                            onChange={(const_value) => setColumn(idx, { ...column, const_value, field: undefined, row_number_type: undefined })} />}
                        {column.type === "row_number" && <InvisibleDropdown
                            values={["Increment by 1", "Increment by 10"]}
                            ids={["inc_1", "inc_10"]}
                            selected={column.row_number_type ? `${column.row_number_type}` : ""}
                            onChange={(row_number_type) => setRowNumberType(idx, row_number_type as "inc_1" | "inc_10")} />}
                    </td>
                    {show_hash_key && <td
                        className="px-2 text-center align-middle text-gray-600 border border-gray-300">
                        <Checkbox
                            checked={column.hash_key || false}
                            setChecked={(hash_key) => handleHashKeyChange(idx, column, hash_key)} />
                    </td>}
                    {moveColumn && <td
                        className="p-2 w-4 align-middle bg-gray-100 text-gray-600 border-t border-b  border-gray-300 cursor-pointer hover:text-white hover:bg-space_blue-300"
                        onClick={() => moveColumn(idx, "up")}>
                        <ChevronUpIcon className="w-4 h-4" />
                    </td>}
                    {moveColumn && <td
                        className="p-2 w-4 align-middle bg-gray-100 text-gray-600 border-t border-b border-r border-gray-300 cursor-pointer hover:text-white hover:bg-space_blue-300"
                        onClick={() => moveColumn(idx, "down")}>
                        <ChevronDownIcon className="w-4 h-4" />
                    </td>}
                    {deleteColumn && <td
                        className="p-2 w-4 align-middle bg-gray-100 text-gray-600 border border-gray-300 cursor-pointer hover:text-white hover:bg-space_blue-300"
                        onClick={() => deleteColumn(idx)}>
                        <TrashIcon className="w-4 h-4" />
                    </td>}
                </tr>))}
                {addColumn && <tr>
                    <td
                        className="py-1 px-2 bg-gray-100 border border-gray-300 text-center text-sm text-gray-600 align-middle cursor-pointer hover:text-white hover:bg-space_blue-300"
                        onClick={addColumn}>
                        +
                    </td>
                </tr>}
            </tbody>
        </table>
    </div >;
}