import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import * as st from "../lib/types";

import { Backend } from "../lib/backend";
import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import {
    classNames,
    prettyDate
} from "../lib/utils";
import {
    selectIsSidebarLarge,
    selectUser
} from "../lib/scraper.slice";
import {
    ORG_TYPES,
    user_roles
} from "../lib/consts";

import { Button } from "../components/Button";
import { Textbox } from "../components/Textbox";
import { Dropdown } from "../components/Dropdown";
import { Pagination } from "../components/Pagination";
import { OrgPillSimple } from "../components/OrgPill";
import { Pill } from "../components/Pill";

const limit = 10;

export function AdminUsers() {
    const user = useSelector(selectUser);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [users_list, setUsersList] = useState<st.IUserList | undefined>(undefined);
    const [offset, setOffset] = useState<number>(0);
    const [current_search, setCurrentSearch] = useState<string>("");
    const [next_search, setNextSearch] = useState<string>("");
    const [role, setRole] = useState<string>("all");

    useEffect(() => {
        setIsLoading(true);
        Backend.getUserList({
            offset,
            limit,
            search: current_search.length > 0 ? current_search : undefined,
            role: role === "all" ? undefined : role
        }).then((result) => {
            setUsersList(result);
            setIsLoading(false);
        });
    }, [offset, current_search, role]);

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6 max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                        Users
                    </h2>
                    <div className="flex-grow" />
                    <Button href="/admin/email_users" text="Email Users" />
                    <Button href={`/admin/user/${user.uuid}`} text={`Edit ${user.first_name} ${user.last_name}`} />
                    <Button href="/admin/orgs" text="Organizations" />
                </div>
                <div className="pt-5 border-b-4 border-sea_blue-700" />
            </div>
            <div className="px-10 py-5 max-w-6xl flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="sm:px-6 lg:px-8 pb-4 grid grid-cols-3 gap-x-10 text-sm">
                        <div className="flex flex-row items-center gap-x-2 col-span-2">
                            <Textbox placeholder="Search users..." value={next_search || ""} onChange={setNextSearch} />
                            <Button text="Search" onClick={() => setCurrentSearch(next_search)} />
                        </div>
                        <div className="flex flex-row items-center gap-x-2">
                            <span className="text-gray-500 whitespace-nowrap">Filer by role:</span>
                            <Dropdown values={["all", ...user_roles]} selected={role} onChange={setRole} />
                        </div>
                    </div>
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Balance</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">GeoIP</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Onboarding</th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {!is_loading && users_list && users_list.users.map((person) => (
                                        <tr key={person.email}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                <div className="flex items-center">
                                                    <div className="h-11 w-11 flex-shrink-0">
                                                        <img className="h-11 w-11 rounded-full" src={person.picture_url} alt="" />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="flex flex-row items-center gap-x-2">
                                                            <div className="font-medium text-gray-900">{person.first_name + " " + person.last_name}</div>
                                                            {person.org_balances
                                                                .filter(org_balance => org_balance.org.type !== ORG_TYPES.personal)
                                                                .map((org_balance, idx) => (<OrgPillSimple key={idx} org={org_balance.org} />))}
                                                        </div>
                                                        <div className="mt-1 text-gray-500">{person.email}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                {person.role} {person.debug && <Pill text="Debug" type="warning" />}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                {person.org_balances.find(org_balance => org_balance.org.type === ORG_TYPES.personal)?.balance ?? 0}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{prettyDate(person.created_at)}</td>
                                            <td className="px-3 py-5 text-sm text-gray-500">{person.details.geoip_info?.country ?? ""} / {person.details.geoip_info?.city ?? ""} / {person.details.geoip_info?.organization ?? ""} </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{`${person.onboarding_status}`}</td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <Link to={`/admin/user/${person.uuid}`} className="text-space_blue-600 hover:text-space_blue-900">Edit</Link>
                                            </td>
                                        </tr>
                                    ))}
                                    {(!users_list || is_loading) && <tr>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={8}>
                                            <LoadingSpinnerLimit />
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                            <Pagination offset={offset} limit={limit} total={users_list?.total || 0} setOffset={setOffset} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment >;
};
