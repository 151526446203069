import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    FaceSmileIcon,
    PlusIcon
} from "@heroicons/react/24/outline";

import { classNames } from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { IItemSlim } from "../lib/types";
import { BackendObj } from "../lib/backend";

import { Button } from "../components/Button";
import { ItemList } from "./Items";
import { LoadingSpinner, LoadingSpinnerLimit } from "../components/LoadingSpinner";

export function Tasks() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [limit, setLimit] = useState(100);
    const [confirm_items, setConfirmItems] = useState<IItemSlim[] | undefined>(undefined);
    const [is_loading_more, setIsLoadingMore] = useState(false);
    const [no_more_items, setNoMoreItems] = useState(false);

    useEffect(() => {
        BackendObj.extractions.getOpenExtractConfirmations({ limit: `${limit}` }).then(({ confirm_items: new_items }) => {
            setConfirmItems(new_items);
            setNoMoreItems(new_items.length < limit);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        // Check if scrolled to bottom (with a small threshold)
        if (scrollHeight - scrollTop - clientHeight < 50 && !no_more_items) {
            const inc_limit = limit + 100;
            setIsLoadingMore(true);
            const { confirm_items: new_items } = await BackendObj.extractions.getOpenExtractConfirmations({ limit: `${inc_limit}` });
            setConfirmItems(new_items);
            setLimit(inc_limit);
            setNoMoreItems(new_items.length < inc_limit);
            setIsLoadingMore(false);
        }
    };

    const onItemSelected = (item: IItemSlim) => {
        navigate(`/confirm/${item.uuid}`);
    }

    if (confirm_items === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}
        onScroll={handleScroll}>
        <div className="h-16 w-full bg-white border-b border-b-sea_blue-700">
            <div className="px-10 py-4 flex flex-row items-center max-w-5xl">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-2xl sm:tracking-tight">
                    TODOs
                </h2>
                <div className="flex-grow" />
                <Button highlight={true} icon={PlusIcon} text="New Job" href="/extraction/new" />
            </div>
        </div>
        <div className="max-w-5xl">
            <div className="p-10">
                {confirm_items.length > 0 && <ItemList items={confirm_items} show_template={true} onItemSelected={onItemSelected} />}
                {confirm_items.length === 0 && <div className="text-center py-20">
                    <FaceSmileIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">Yay, all nice and tidy!</h3>
                    <p className="mt-1 text-sm text-gray-500">No workflows that require confirmation.</p>
                </div>}
            </div>
            {is_loading_more && <div className="flex justify-center items-center h-screen w-full">
                <LoadingSpinnerLimit />
            </div>}
        </div>
    </div>;
}
