import { Fragment, useState } from "react";
import { BackendObj } from "../lib/backend";
import { Button } from "./Button";
import { useSelector } from "react-redux";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import * as t from "../lib/types";
import { classNames } from "../lib/utils";
import { useEffect } from "react";
import { PlayIcon } from "@heroicons/react/24/outline";

interface PromptEditorProps {
    input: {
        messages?: Array<{ role: "system" | "user" | "assistant"; content: string }>;
        model_params?: Record<string, any>;
        task_type?: t.DebugPromptLogTaskType;
    };
    onClose: () => void;
}

export function PromptEditor({ input, onClose }: PromptEditorProps) {
    const [messages, setMessages] = useState(input.messages || []);
    const [model_params, setModelParams] = useState(
        JSON.stringify(input.model_params || {}, null, 2)
    );
    const [result, setResult] = useState<string>("");
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    // Add escape key handler
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        window.addEventListener("keydown", handleEsc);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [onClose]);

    const handleRunPrompt = async () => {
        try {
            const parsed_params = JSON.parse(model_params);
            const response = await BackendObj.extractions.executeChatCompletion({
                messages,
                model_params: parsed_params,
                task_type: input.task_type ?? "unknown"
            });
            if ("choices" in response.debug_info.output) {
                setResult(response.debug_info.output.choices?.[0]?.message?.content ?? "");
            } else {
                setResult(JSON.stringify(response.debug_info.output, null, 2));
            }
        } catch (error) {
            console.error("Error running prompt:", error);
            setResult(JSON.stringify({ error: "Failed to execute prompt" }, null, 2));
        }
    };

    return (
        <Fragment>
            <div className="fixed inset-0 bg-white" />

            <div className={classNames("fixed inset-0 z-50", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
                <div className="flex-1 p-4 flex flex-col h-full">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">Edit Prompt</h2>
                        <Button text="Close" onClick={onClose} />
                    </div>

                    <div className="flex gap-4 flex-1 min-h-0">
                        <div className="flex-1 flex flex-col gap-4 min-h-0">
                            {messages.map((message, idx) => (
                                <div key={idx} className={classNames(
                                    "flex flex-col gap-2",
                                    idx === 1 ? "flex-1 min-h-0" : ""
                                )}>
                                    <div className="text-sm text-gray-600">{message.role}</div>
                                    <textarea
                                        className={classNames(
                                            "w-full p-2 border rounded",
                                            idx === 1 ? "h-full min-h-0" : ""
                                        )}
                                        value={message.content}
                                        onChange={(e) => {
                                            const new_messages = [...messages];
                                            new_messages[idx] = {
                                                ...message,
                                                content: e.target.value
                                            };
                                            setMessages(new_messages);
                                        }}
                                        rows={idx === 1 ? undefined : 4}
                                    />
                                </div>
                            ))}

                            <div className="flex flex-col gap-2">
                                <div className="text-sm text-gray-600">Model Parameters</div>
                                <textarea
                                    className="w-full p-2 border rounded font-mono"
                                    value={model_params}
                                    onChange={(e) => setModelParams(e.target.value)}
                                    rows={4}
                                />
                            </div>
                            <div className="flex gap-2 justify-start">
                                <Button text="Run" icon={PlayIcon} onClick={handleRunPrompt} />
                            </div>
                        </div>

                        <div className="flex-1">
                            <div className="text-sm text-gray-600 mb-2">Result</div>
                            <textarea
                                className="w-full h-[calc(100%-2rem)] p-2 border rounded font-mono"
                                value={result}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}